import React, { useState } from "react";
import "./CancelledJobTable.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import EyeIcon from "../../../../asset/jobs/EyeIcon.svg";
import CrossIcon from "../../../../asset/jobs/close-circle.svg";
import { CircularProgress, Modal } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  setJobModal,
  setJobDetaialsModal,
} from "../../../../redux/userReducer";
import JobOptionModel from "../../../Common/JobModal/JobModal";
import JobDetailsModal from "../../../Common/JobDetailsModal/JobDetailsModal";
import JobCancelledPostDetails from "../../../PopUp/JobCancelledPostDetails/JobCancelledPostDetails";
import graphqlRequest from "../../../../requestMethod";
import eventHelpers from "../../../../Helpers/Event";

const columns = [
  { id: "jobId", label: "Job ID", minWidth: 100 },
  // {
  //   id: "department",
  //   label: "Department ",
  //   minWidth: 100,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "specialization",
    label: "Specialization",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "professionName",
    label: "Professional Name",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Start Date",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },

  {
    id: "endDate",
    label: "End Date",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  { id: "status", label: "Status", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 100, align: "center" },
];

function createData(
  jobId,
  // department ,
  specialization,
  professionName,
  date,
  endDate,
  status,
  id,
  allData
) {
  return {
    jobId,
    // department,
    specialization,
    professionName,
    date,
    endDate,
    status,
    id,
    allData,
  };
}

const rows = [
  createData(
    "JO1234567",
    "Cardiology",
    "Safari Sharma",
    "19/12/2022",
    "13/02/2023",
    "NA"
  ),
];

function CancelledJobTable({ loading, filterTable, removeOpenJob, totalData, currentPage,limit, setCurrentPage, setLimit }) {
  let newRow = filterTable?.map((data) =>
    createData(
      data?.job?.code,
      // data?.job?.department,
      data?.job?.specialization,
      data?.professional?.name?.full,
      new Date(Number(data?.job?.start))
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      new Date(Number(data?.job?.end))
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      data?.status,
      data.id,
      data
    )
  );

  let { jobModal, detailsJobModal } = useSelector((state) => state.user);
  let dispatch = useDispatch();
  const handleClose = () => dispatch(setJobModal());
  const handleCloseDetail = () => dispatch(setJobDetaialsModal());
  //table checking start...
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setCurrentPage(0);
  };

  let [openDate, setOpenDate] = useState(false);
  let [selectedData, setSelectedData] = useState({});
  let [jobPostRemoveId, setJobPostRemoveId] = useState({});
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let handleView = (data) => {
    setSelectedData(data);
    dispatch(setJobDetaialsModal());
  };

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackActCanceView = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.ACTION_CANCLD_LJB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <>
      <div className="openjob-table">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2D5FA1",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress sx={{ position: "absolute", left: "50%" }} />
              ) : (
                <TableBody>
                  {newRow?.length > 0 ? (
                    newRow
                      ?.slice(
                        // currentPage,limit,
                        // page * rowsPerPage,
                        // page * rowsPerPage + rowsPerPage
                        // currentPage * limit,
                        // currentPage * limit + limit
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "action" ? (
                                <TableCell key={column.id} align={column.align}>
                                  <img
                                    id="Action_Cancld_LJb"
                                    src={EyeIcon}
                                    alt=""
                                    onClick={() => {
                                      handleView(row);
                                      trackActCanceView();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <p style={{ position: "absolute", left: "50%" }}>
                      {" "}
                      No Data Found
                    </p>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
           paginationMode="server"
           pagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalData}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
            // rowsPerPageOptions={[10, 25, 100]}
            // component="div"
            // count={newRow?.length}
            // rowsPerPage={rowsPerPage}
            // page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Modal
        open={jobModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobOptionModel
          removeOpenJob={removeOpenJob}
          jobPostRemoveId={jobPostRemoveId}
        />
      </Modal>
      <Modal
        open={detailsJobModal}
        onClose={handleCloseDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobCancelledPostDetails
          handleCloseDetail={handleCloseDetail}
          selectedData={selectedData}
        />
      </Modal>
    </>
  );
}

export default CancelledJobTable;
