import React from 'react'
import BreadCrumb from '../../../../components/Common/BreadCrumb/BreadCrumb';
import JobsHeader from '../../../../components/Common/JobsHeader/JobsHeader';
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
import "./ShiftLogs.scss";
const ShiftJobs = () => {
  return (
    <div className='shiftjobs-wrapper'>
    <JobsHeader title={"Shift Jobs"}/>
    <BreadCrumb title={"Shift Jobs"}/> 
    <FilterComponent title={"SHIFTJOBS"} professional={"TYPE OF SHIFTJOBS"}/>
    <AcceptedTable/>
  </div>  )
}

export default ShiftJobs