import React, { useState } from "react";
import "./CompletedJobTable.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import EyeIcon from "../../../../asset/jobs/EyeIcon.svg";
import CrossIcon from "../../../../asset/jobs/close-circle.svg";
import { CircularProgress, Modal } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Snackbar, SnackbarContent } from '@mui/material';

import {
  setJobModal,
  setJobDetaialsModal,
} from "../../../../redux/userReducer";
import JobOptionModel from "../../../Common/JobModal/JobModal";
import JobDetailsModal from "../../../Common/JobDetailsModal/JobDetailsModal";
import graphqlRequest from "../../../../requestMethod";


import blitz from "blitzllama-js";


//initialize blitzllama SDK. Place this as higher in your app as possible
// blitz.init("key_BHqcqnvQnpHWaGg");

const columns = [
  { id: "jobId", label: "Job ID", minWidth: 100 },
  {
    id: "specialization",
    label: "Specialization",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "professionName",
    label: "Professional Name",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Start Date",
    minWidth: 150,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 150,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  { id: "status", label: "Status", minWidth: 100, align: "center" },
  { id: "review", label: "Review", minWidth: 100, align: "center" },
];

function createData(
  jobId,
  specialization,
  professionName,
  date,
  endDate,
  id,
  allData
) {
  return {
    jobId,
    specialization,
    professionName,
    date,
    endDate,
    id,
    allData,
  };
}

const rows = [
  createData(
    "JO1234567",
    "Cardiology",
    "Safari Sharma",
    "19/12/2022",
    "13/02/2023",
    "NA"
  ),
];

function CompletedJobTable({ loading, filterTable, removeOpenJob, totalData, currentPage,limit, setCurrentPage, setLimit  }) {
  let newRow = filterTable?.map((data) =>
    createData(
      data?.job?.code,
      // data?.job?.department,
      data?.job?.specialization,
      data?.professional?.name?.full || "NA",
      new Date(Number(data?.job?.start))
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),
      new Date(Number(data?.job?.end))
        .toDateString()
        .split(" ")
        .slice(1)
        .join(" "),

      data?.status,
      data.id,
      data
    )
  );
  let { jobModal, detailsJobModal } = useSelector((state) => state.user);
  let dispatch = useDispatch();

  const handleClose = () => dispatch(setJobModal());
  const handleCloseDetail = () => dispatch(setJobDetaialsModal());
  //table checking start...
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setCurrentPage(0);
  };

  let [hospitalName, setHospitalName] = useState("");
  let [professionsName, setProfessionsName] = useState("");
  let [openDate, setOpenDate] = useState(false);
  let [selectedData, setSelectedData] = useState({});
  let [jobPostRemoveId, setJobPostRemoveId] = useState({});
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let handleView = (data) => {
    setSelectedData(data);
    dispatch(setJobDetaialsModal());
  };

  let handleJobPostDelete = (data) => {
    setJobPostRemoveId(data);
    dispatch(setJobModal());
  };
  const fetchEmployeeId = async () => {
    let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  try{
    const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id
      }
      }
    }
      `;
      const response = await graphqlRequest(query)
      // console.log("175-->",response)
      return response
  }catch(error){
    console.error('Error sending GraphQL request:', error);
  }
  
  }
  // console.log("158-->", filterTable)
  const hasJobBeenReviewed = (jobId) => {
    const reviewedJobs = localStorage.getItem("reviewedJobs");
    if (reviewedJobs) {
        const reviewedJobIds = JSON.parse(reviewedJobs);
        return reviewedJobIds.includes(jobId);
    }
    return false;
};

// Function to mark a job as reviewed
const markJobAsReviewed = (jobId) => {
    let reviewedJobIds = localStorage.getItem("reviewedJobs");
    if (reviewedJobIds) {
        reviewedJobIds = JSON.parse(reviewedJobIds);
    } else {
        reviewedJobIds = [];
    }
    reviewedJobIds.push(jobId);
    localStorage.setItem("reviewedJobs", JSON.stringify(reviewedJobIds));
};
const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleClick = async (id, jobId,date,endDate) => {
    
    try {
      // console.log("160-->",jobId)
      // console.log("160-->",date)
      // console.log("160-->",endDate)
      const employee = await fetchEmployeeId()
      const employeeId = employee.data.withAuth.userDetails.id
      // console.log("abc", employeeId)
      const JobId = jobId || null;
      const userId = id || null;
      const combined = JobId+"_"+userId
      if (!hasJobBeenReviewed(jobId)) {
        blitz.init(process.env.REACT_APP_BLITZLLAMA_API_KEY);
        blitz.logout();
        blitz.createUser(combined);
        const eventName = "csat";
        const eventData = { event_property: "JOBS" };
        blitz.triggerEvent(eventName, eventData);
        blitz.setUserProperties({ ROLE: "HOSPITAL", EMPLOYEEID: employeeId, STARTDATE: date, ENDDATE: endDate });

        markJobAsReviewed(jobId);
    } else {
      setOpenSnackbar(true);

      setTimeout(() => {
          setOpenSnackbar(false);
      }, 3000);
    }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  

  return (
    <>
      <div className="openjob-table">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2D5FA1",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress sx={{ position: "absolute", left: "50%" }} />
              ) : (
                <TableBody>
                  {newRow?.length > 0 ? (
                    newRow
                      ?.slice(
                        // page * rowsPerPage,
                        // page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "status" ? (
                                <TableCell key={column.id} align={column.align}>
                                  <button className="btn-Completed">
                                    Completed
                                  </button>
                                </TableCell>
                              ) : column.id === "review" ? (
                                <TableCell key={column.id} align={column.align}>
                                  {/* {console.log("241-->",row.data)}
                                  {console.log("241-->",row.endDate)} */}
                                  <button
                                    className="btn-Completed"
                                    onClick={() =>
                                      handleClick(row.allData, row.jobId,row.date,row.endDate)
                                    }
                                  >
                                    Review
                                  </button>
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <p style={{ position: "absolute", left: "50%" }}>
                      {" "}
                      No Data Found
                    </p>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
          paginationMode="server"
          pagination
         rowsPerPageOptions={[10, 25, 100]}
         component="div"
         count={totalData}
         rowsPerPage={limit}
         page={currentPage}
         onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}

            // rowsPerPageOptions={[10, 25, 100]}
            // component="div"
            // count={newRow?.length}
            // rowsPerPage={rowsPerPage}
            // page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => {}}
            >
                <SnackbarContent
                    style={{ backgroundColor: '#00468a', color: 'white' }} 
                    message={<span style={{ fontWeight: 300 }}>This job has already been reviewed</span>}

                />
            </Snackbar>
        </div>
      </div>
      <Modal
        open={jobModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobOptionModel
          removeOpenJob={removeOpenJob}
          jobPostRemoveId={jobPostRemoveId}
        />
      </Modal>
      <Modal
        open={detailsJobModal}
        onClose={handleCloseDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobDetailsModal
          handleCloseDetail={handleCloseDetail}
          selectedData={selectedData}
        />
      </Modal>
    </>
  );
}

export default CompletedJobTable;
