import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
// import "./LOngoingJobs.scss";
import graphqlRequest from "../../../../requestMethod";
import ShiftJobTable from "../../../../components/Table/Locum/ShiftJobTable/ShiftJobTable";
const OngoingJobs = () => {
  let [tableData, setTableData] = useState([]);

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  let [filterTable, setFilterTable] = useState([]);

  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");

  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");

  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [totalData, setTotalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  let [filterByProfession, setFilterByProfession] = useState("");
  let [filterByDepartment, setFilterByDepartment] = useState("");
  let [filterByStartDate, setFilterByStartDate] = useState("");
  let [filterByEndDate, setFilterByEndDate] = useState("");
  let [filterByBranch, setFilterByBranch] = useState("");
  let [hospitalBranchList, setHospitalBranchList] = useState([]);

  let [hospitalBranchData, setHospitalBranchData] = useState([]);
  const [hospitalBranch, setHospitalBranch] = useState("");
  const [hospitalBranchId, setHospitalBranchId] = useState("");

  function check(start, end, current) {
    if (current >= start && current < end) {
      return true;
    } else {
      return false;
    }
  }
  function filterDepartment(data) {
    let onlyDep = Array.from(new Set(data.map((data) => data?.department)));
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(new Set(data.map((data) => data?.profession)));
    setProfessionalList(onlyPro);
  }
  function filterShiftJobTable(query) {
    function search(user) {
      return Object.keys(this).every((key) => {
        if (
          (key == "start" || key == "end") &&
          check(this["start"], this["end"], new Date(Number(user["start"])))
        ) {
          return true;
        } else if (key != "end") {
          return user[key] === this[key];
        }
      });
    }
    let filterData = tableData.filter(search, query);

    setFilterTable(filterData);
  }

  function filterHospitalBranch(data) {
    // console.log("data", data)
    let onlyPro = Array.from(
      new Set(data.map((data) => data.branch))
    );
    setHospitalBranchList(onlyPro);
  }

  function fetchHospitalDropdownData() {
    const query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobBranches{
          id
          jobLocation {
            hospitalName
            branchName
          }
       }
       }
     }
  `;
    graphqlRequest(query).then((response) => {
      // console.log("response>>>>>", response);
      setHospitalBranchData(response.data.withAuth.listMiniJobBranches);
    });
  }
  // console.log("hospitalBranchData", hospitalBranchData);
  const hospitalBranchOptions = [];
  hospitalBranchData.map((ele) => {
    // console.log("ele", ele);
    let obj = {
      key: ele.id,
      value: ele.jobLocation.branchName,
    };

    hospitalBranchOptions.push(obj);
  });

  // console.log("hospitalBranchOptions", hospitalBranchOptions);

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    hospitalBranchOptions.forEach((branch) => {
      // console.log("branch", branch);
      if (branch.value === selectedBranch) {
        // selectedBranchObjectID = branch.key;
        selectedBranchObjectID = branch.value;
      }
    });

    setHospitalBranch(selectedBranch);
    setHospitalBranchId(selectedBranchObjectID)

    // dispatch(filterRole(selectedRoleObjectID));
  };

  let fetchShiftLogs = () => {
    setLoading(true);
    const query = `
    query {
      
        withAuth(token: "${token}") {
          listMiniJobShiftLogs(limit: ${limit}, offset: ${currentPage}, search: "${keyword}", filterByProfession: "${filterByProfession}", department: "${filterByDepartment}", startDate: "${filterByStartDate}", endDate:"${filterByEndDate}", filterByHospitalBranch: "${hospitalBranchId}")
           {
            id
            profession
            department
            code
            specialization
            shift
            user
            userId
            date
            in
            out
            log
            cost
            branch
          }
        
      }
      
  }
    `;

    graphqlRequest(query).then((response) => {
      setTotalData(response?.data?.shiftsCount);
      setTableData(response.data.withAuth.listMiniJobShiftLogs);
      setLoading(false);
      setFilterTable(response.data.withAuth.listMiniJobShiftLogs);
      filterDepartment(response.data.withAuth.listMiniJobShiftLogs);
      filterProfessional(response.data.withAuth.listMiniJobShiftLogs);
      filterHospitalBranch(response.data.withAuth.listMiniJobShiftLogs);
    });
  };
  let removeOpenJob = (id) => {
    const query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            revokeMiniJobOffers(job:"${id}")
          }
        }
      }
    `;

    graphqlRequest(query).then((response) => {
      fetchShiftLogs();
    });
  };
  function filterGlobalSearch() {
    let data = tableData?.filter((data) => {
      if (
        data?.code?.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data?.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }
  useEffect(() => {
    fetchShiftLogs();
  }, [
    currentPage,
    limit,
    keyword,
    filterByProfession,
    filterByDepartment,
    filterByStartDate,
    filterByEndDate,
    filterByBranch,
    hospitalBranch,
    hospitalBranchId
  ]);

  useEffect(() => {
    fetchHospitalDropdownData();
  }, []);
  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);

  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["profession"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterShiftJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

  return (
    <div className="ongoingJob-wrapper">
      <JobsHeader title={"Shift Jobs"} />
      <BreadCrumb action={"Locum"} title={"Shift Jobs"} />
      <FilterComponent
        fetchAllData={fetchShiftLogs}
        title={"ONGOINGJOBS"}
        professional={"TYPE OF ONGOING JOBS"}
        departmentList={departmentList}
        professionalList={professionalList}
        SetGlobalInputSearch={SetGlobalInputSearch}
        setProfessionValue={setProfessionValue}
        setDepartmentValue={setDepartmentValue}
        globalInputSearch={globalInputSearch}
        setFilterTable={setFilterTable}
        tableData={tableData}
        setFilterDate={setFilterDate}
        filterDate={filterDate}
        departmentValue={departmentValue}
        professionsValue={professionsValue}
        keyword={keyword}
        setKeyword={setKeyword}
        filterByProfession={filterByProfession}
        setFilterByProfession={setFilterByProfession}
        filterByDepartment={filterByDepartment}
        setFilterByDepartment={setFilterByDepartment}
        filterByStartDate={filterByStartDate}
        setFilterByStartDate={setFilterByStartDate}
        filterByEndDate={filterByEndDate}
        setFilterByEndDate={setFilterByEndDate}
        filterByBranch={filterByBranch}
        setFilterByBranch={setFilterByBranch}
        hospitalBranchList={hospitalBranchList}
        hospitalBranch={hospitalBranch}
          handleBranchFilterChange={handleBranchFilterChange}
          hospitalBranchOptions={hospitalBranchOptions}
          setHospitalBranch={setHospitalBranch}
          setHospitalBranchId={setHospitalBranchId}
      />
      <ShiftJobTable
        loading={loading}
        filterTable={filterTable}
        fetchShiftLogs={fetchShiftLogs}
        totalData={totalData}
        currentPage={currentPage}
        limit={limit}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
      />
    </div>
  );
};

export default OngoingJobs;
