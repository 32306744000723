import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    //Posting A JOB

    isFatching: false,
    currentUser: null,
    isError: false,
    token:
      "8516cbbb721d7fd37e5830724e29742863deed3d5b63c5c70c31bb805b7ee7605506a3c56a6a02a309e2251954fa5cf5f534b87759f81603bc51c7cc8bf27a57e4112113bce63e2eadae08e2c5772128",
    myModel: false,
    jobModal: false,
    detailsJobModal: false,
    isJobMatch: false,
    activeTab: 1,

    //SIGNUP USER DETAILS
    signUpUserGmail: null,

    reduxLocumToggle: false,
    reduxLocumJobActiveTab: 0,
    reduxLocumReportToggle: false,
    reduxLocumReportActiveTab: 0,

    reduxPermanentToggle: false,
    reduxPermanentJobActiveTab: 0,
    reduxPermanentReportToggle: false,
    reduxPermanentReportActiveTab: 0,

    //posting a job
    postingJob: false,

    recommendedUsers: [],
    recommendedUserAbsolute: [],
    userSelectedProfile: {},
    AppliedUserApplication: [],
    userJobPostDetails: {},
  },
  reducers: {
    loginStart: (state) => {
      state.isFatching = true;
    },
    loginSuccess: (state, action) => {
      state.isFatching = false;
      state.currentUser = action.payload;
      state.isError = false;
      console.log("LOGIN SUCCESS", action.payload);
    },
    loginFailed: (state) => {
      state.isFatching = false;
      state.isError = true;
    },
    //SIGNUP REDUCERS
    signUpStart: (state, action) => {
      console.log("Sign UP Reducer", action?.payload);
      state.signUpUserGmail = action?.payload?.data?.loginWithGmail;
      console.log("USER SIGN UP", state.signUpUserGmail);
    },
    signUpSuccess: (state, action) => {
      state.signUpUserGmail = { name: "", email: "" };
    },

    logoutSuccess: (state) => {
      state.currentUser = null;
    },
    setModel: (state) => {
      state.myModel = !state.myModel;
    },
    setJobModal: (state) => {
      state.jobModal = !state.jobModal;
    },
    setJobDetaialsModal: (state) => {
      state.detailsJobModal = !state.detailsJobModal;
    },
    setOpenJobMatches: (state) => {
      state.isJobMatch = !state.isJobMatch;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload.index;
      state.reduxLocumToggle = action.payload.LocumJobToggle
        ? action.payload.LocumJobToggle
        : false;
      state.reduxLocumJobActiveTab = action.payload.locumActive
        ? action.payload.locumActive
        : 0;
      state.reduxLocumReportToggle = action.payload.LocumReportToggle
        ? action.payload.LocumReportToggle
        : false;
      state.reduxLocumReportActiveTab = action.payload.locumReportActive
        ? action.payload.locumReportActive
        : 0;
    },
    setRecommendedUsers: (state, action) => {
      state.recommendedUsers = action.payload;
    },
    setRecommendedUserAbsolute : (state, action) => {
      state.recommendedUserAbsolute = action.payload;
    },
    setProfile: (state, action) => {
      state.userSelectedProfile = action.payload;
    },
    removeProfile: (state, action) => {
      state.userSelectedProfile = {};
    },
    setUserApplication: (state, action) => {
      state.AppliedUserApplication = action.payload;
    },
    setUserJobPost: (state, action) => {
      state.userJobPostDetails = action.payload;
    },
    //JOB POSTING
    setPostingJobReducer: (state, action) => {
      state.postingJob = true;
    },
    setJobPostedReducer: (state, action) => {
      state.postingJob = false;
    },
  },
});

export const {
  setUserJobPost,
  setUserApplication,
  removeProfile,
  userSelectedProfile,
  setProfile,
  recommendedUsers,
  setRecommendedUsers,
  recommendedUserAbsolute,
  setRecommendedUserAbsolute,
  reduxLocumReportActiveTab,
  reduxLocumReportToggle,
  reduxLocumJobActiveTab,
  reduxlocumToggle,
  activeTab,
  setActiveTab,
  setOpenJobMatches,
  jobMatches,
  setModel,
  setJobModal,
  loginStart,
  signUpSuccess,
  loginSuccess,
  loginFailed,

  logoutSuccess,
  setJobDetaialsModal,
  signUpStart,
  setPostingJobReducer,
  setJobPostedReducer,
} = userSlice.actions;
export default userSlice.reducer;
