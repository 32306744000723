import React from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
import "./CancelledJobs.scss";
const CancelledJobs = () => {
  return (
    <div className="cancelled-wrapper">
      <JobsHeader title={"Cancelled Jobs"} />
      <BreadCrumb action={"Locum"}  title={"Cancelled Jobs"} />
      <FilterComponent
        title={"CANCELLEDJOBS"}
        professional={"TYPE OF CANCELLED JOBS"}
      />
      <AcceptedTable />
    </div>
  );
};

export default CancelledJobs;
