import React from "react";

const AvailableDates = ({ availableDates }) => {

  // console.log("1-->", availableDates)
  if (!availableDates || availableDates.length === 0) {
    //style={{ backgroundColor: '#ffffff',color: 'red',}}
    return <div>
      <h3>Available Interview Dates:</h3>
      <p >The Candidate has not marked availability,You can ask slots as per your choice</p>
      </div>;
  }
    
  return (
    <div>
      <h3>Available Interview Dates:</h3>
      <ul>
        {availableDates.slice(0, 7).map((item, index) => (
          <li key={index}>
            <h5>Available Date: {index + 1}</h5>
            <p>Date: {new Date(item.date).toLocaleDateString("en-GB")}</p>
            {/* <p>Shift: {item.shift}</p> */}
            <p>Start Time: {new Date(item.startTime).toLocaleTimeString("en-GB", { hour: "2-digit" })}:00</p>
            <p>End Time: {new Date(item.endTime).toLocaleTimeString("en-GB", { hour: "2-digit" })}:00</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AvailableDates;
