import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import graphqlRequest from "../../requestMethod";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { useNavigate } from "react-router-dom";
import Logo from "../../asset/navbar/logo.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem', 
    fontFamily: 'cursive',
    fontWeight: 'bold',
    // color: theme.palette.primary.main,
    color: "blue",
    textShadow: '1.5px 1.5px 3px rgba(0, 0, 0, 0.3)',
    marginBottom: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "100px",
    marginBottom: theme.spacing(2),
  },
}));

function SetNewPassword() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const fetchSetPassword = async () => {
    const verifyToken = localStorage.getItem("SetVerifyToken");
    // console.log("1-->", verifyToken);
    try {
      const query = `
        mutation {
          setNewPassword(
            verifyToken: "${verifyToken}",
            password: "${password}"
          )
        }
      `;
      const response = await graphqlRequest(query);
      //   console.log("55-->", response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const isValidPassword = (password) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      if (isValidPassword(password)) {
        await fetchSetPassword();
        // navigate("/login");
        navigate("/home");
        message.success("Password Change Successfully");
      } else {
        message.error(
          "Password should be 8-15 characters long and must contain one of 0-9,A-Z,a-z and special characters."
        );
      }
    } else {
      message.error("Passwords do not match");
    }
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Paper className={classes.paper} elevation={3}>
        <img src={Logo} alt="Your Logo" className={classes.logo} />
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className={classes.title}
        >
          Set New Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            required
            margin="normal"
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default SetNewPassword;
