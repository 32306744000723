import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import "./LOngoingJobs.scss";
import OngoingJobTable from "../../../../components/Table/Locum/OngoingJobTable/OngoingJobTable";
import graphqlRequest from "../../../../requestMethod";
const OngoingJobs = () => {
  let [tableData, setTableData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);

  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");

  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");

  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [totalData, setTotalData] = useState([]) 
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  let [filterByProfession, setFilterByProfession] = useState("");
  let [filterByDepartment, setFilterByDepartment] = useState("");
  let [filterByStartDate, setFilterByStartDate] = useState("");
  let [filterByEndDate, setFilterByEndDate] = useState("");
  let [filterByBranch, setFilterByBranch] = useState("");
  let [hospitalBranchList, setHospitalBranchList] = useState([]);

  let [hospitalBranchData, setHospitalBranchData] = useState([]);
  const [hospitalBranch, setHospitalBranch] = useState("");
  const [hospitalBranchId, setHospitalBranchId] = useState("");

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  function check(start, end, current) {
    if (current >= start && current <= end) {
      return true;
    } else {
      return false;
    }
  }
  function filterDepartment(data) {
    let onlyDep = Array.from(new Set(data.map((data) => data.job.department)));
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(
      new Set(data.map((data) => data.professional?.professionalType?.name))
    );
    setProfessionalList(onlyPro);
  }

  function filterHospitalBranch(data) {
    // console.log("data", data)
    let onlyPro = Array.from(
      new Set(data.map((data) => data.job.hospitalName))
    );
    setHospitalBranchList(onlyPro);
  }

  function fetchHospitalDropdownData() {
    const query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobBranches{
          id
          jobLocation {
            hospitalName
            branchName
          }
       }
       }
     }
  `;
    graphqlRequest(query).then((response) => {
      // console.log("response>>>>>", response);
      setHospitalBranchData(response.data.withAuth.listMiniJobBranches);
    });
  }
  // console.log("hospitalBranchData", hospitalBranchData);
  const hospitalBranchOptions = [];
  hospitalBranchData.map((ele) => {
    // console.log("ele", ele);
    let obj = {
      key: ele.id,
      value: ele.jobLocation.branchName,
    };

    hospitalBranchOptions.push(obj);
  });

  // console.log("hospitalBranchOptions", hospitalBranchOptions);

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    hospitalBranchOptions.forEach((branch) => {
      // console.log("branch", branch);
      if (branch.value === selectedBranch) {
        // selectedBranchObjectID = branch.key;
        selectedBranchObjectID = branch.value;
      }
    });

    setHospitalBranch(selectedBranch);
    setHospitalBranchId(selectedBranchObjectID)

    // dispatch(filterRole(selectedRoleObjectID));
  };


  let fetchOngoingJob = () => {
    setLoading(true);
    const query = `
    query {
      withAuth(token: "${token}") {
        listMiniJobApplications(type:ONGOING,limit: ${limit}, offset: ${currentPage},search: "${keyword}", filterByProfession: "${filterByProfession}", department: "${filterByDepartment}",startDate: "${filterByStartDate}", endDate:"${filterByEndDate}", filterByHospitalBranch: "${hospitalBranchId}"){
        id
        status
        job {jobDescription
          applications
          hospitalName}
        professional {
          id
          emails {
            isPrimary
            address
          }
          phoneNumbers {
            isPrimary
            number
          }
          name {
            full
          }
          address {
            state
            address
            country
            city
            country
            pin
          }
          specialization {
            name
            id
          }
          numExperience {
            id
            name
          }
          workCity {
            id
            name
          }
          qualification {
            id
            name
          }
          professionalType {
            id
            name
          }
          profile {
            dob
            gender
            pan
            aadhar
            social {
              facebook
              linkedin
            }
            covid
            jobHistory {
              hospital
              department
              specialisation
              start
              end
            }
            education {
              course
              specialisation
              name
              year
            }
            references {
              name
              hospital
              designation
              phone
              email
            }
          }
          languageKnownNew
          additionalTrainingNew
          workedOn {
            id
            softwarename
          }
          professionalRegistration {
            number
            registrationType {
              id
              name
            }
          }
          avatar {
            location
            fileName
            uri
            bucket
            region
            mimeType
            secureUrl
            url
            isSecured
            cdnUrl
          }
          correspondenceAddress {
            address
            city
            state
            pin
            country
          }
          departments {
            id
            name
          }
        }
        job {
            id
            code
            department
            specialization
            start
            end
            applications
            filled
          }
        }
      }
  }
    `;
    graphqlRequest(query).then((response) => {
      // console.log("ongoing response>>", response);
      setTotalData(response?.data?.totalApplicationCount);
      setTableData(response.data.withAuth.listMiniJobApplications);
      setLoading(false);
      setFilterTable(response.data.withAuth.listMiniJobApplications);
      filterDepartment(response.data.withAuth.listMiniJobApplications);
      filterProfessional(response.data.withAuth.listMiniJobApplications);
      filterHospitalBranch(response.data.withAuth.listMiniJobApplications);
    });
  };
  // console.log("totalData", totalData)
  function filterGlobalSearch() {
    let data = tableData.filter((data) => {
      if (
        data.job.code.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data.job.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase()) ||
        data.professional?.name?.full
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }

  let removeOpenJob = (id) => {
    const query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            cancelMiniJobApplication(application:"${id}")
          }
        }
      }
    `;

    graphqlRequest(query).then((response) => {
      fetchOngoingJob();
    });
  };
  function filterOngoingJobTable(query) {
    let { department, professionalType, start, end } = query;
    function search(elem) {
      let flag = {};
      if (!!department) {
        flag.department = elem?.job?.department === department;
      }
      if (!!professionalType) {
        flag.professional =
          elem?.professional?.professionalType.name === professionalType;
      }
      if (!!start && !!end) {
        flag.time = check(start, end, new Date(Number(elem?.job?.start)));
      }
      return Object.values(flag).every((test) => test == true);
    }
    let filterData = tableData.filter(search);

    setFilterTable(filterData);
  }
  useEffect(() => {
    fetchOngoingJob();
  }, [currentPage,limit,keyword,filterByProfession,filterByDepartment,filterByStartDate,filterByEndDate,filterByBranch,hospitalBranch,hospitalBranchId]);

  useEffect(() => {
    fetchHospitalDropdownData();
  }, []);

  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["professionalType"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterOngoingJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);

  return (
    <div className="ongoingJob-wrapper">
      <JobsHeader title={"Ongoing Jobs"} />
      <BreadCrumb action={"Locum"} title={"Ongoing Jobs"} />
      <FilterComponent
        fetchAllData={fetchOngoingJob}
        title={"ONGOINGJOBS"}
        professional={"TYPE OF ONGOING JOBS"}
        departmentList={departmentList}
        professionalList={professionalList}
        SetGlobalInputSearch={SetGlobalInputSearch}
        setProfessionValue={setProfessionValue}
        setDepartmentValue={setDepartmentValue}
        globalInputSearch={globalInputSearch}
        setFilterTable={setFilterTable}
        tableData={tableData}
        setFilterDate={setFilterDate}
        filterDate={filterDate}
        departmentValue={departmentValue}
        professionsValue={professionsValue}
        keyword={keyword}
        setKeyword={setKeyword}
        filterByProfession={filterByProfession}
        setFilterByProfession={setFilterByProfession}
        filterByDepartment={filterByDepartment}
        setFilterByDepartment={setFilterByDepartment}
        filterByStartDate={filterByStartDate} 
        setFilterByStartDate={setFilterByStartDate}
        filterByEndDate={filterByEndDate} 
        setFilterByEndDate={setFilterByEndDate}
        filterByBranch={filterByBranch}
        setFilterByBranch={setFilterByBranch}
        hospitalBranch={hospitalBranch}
          handleBranchFilterChange={handleBranchFilterChange}
          hospitalBranchOptions={hospitalBranchOptions}
          setHospitalBranch={setHospitalBranch}
          setHospitalBranchId={setHospitalBranchId}
      />
      <OngoingJobTable
        loading={loading}
        filterTable={filterTable}
        totalData={totalData}
        currentPage={currentPage}
        limit={limit}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
        removeOpenJob={removeOpenJob}
      />
    </div>
  );
};

export default OngoingJobs;
