import React from "react";
import "./UpcomingEvent.scss";
import bgimg from "../../../asset/popup/bg-img.svg";
function UpcomingEvent({ setIsUpcomingOpen }) {
  return (
    <div className="upcoming-event-container">
      <div className="upcoming-event-wrapper">
        <div className="image-container">
          <img src={bgimg} />
        </div>
        <div className="description-upcoming">
          <h3>Launching Soon!</h3>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur. Diam gravida a to get update
            when it’ll be live.
          </p> */}
          <button onClick={() => setIsUpcomingOpen(false)}>Okay</button>
        </div>
      </div>
    </div>
  );
}

export default UpcomingEvent;
