import { MenuItem, Select, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";
import graphqlRequest from "../../../requestMethod";
import { message } from "../../Common/SnakeAlert/SnakeAlert";
import eventHelpers from "../../../Helpers/Event";

let dropdown = [
  { id: 1, value: "Time Mismatch" },
  { id: 2, value: "Different Professional completed the job" },
  { id: 3, value: "Professional Did not showed up" },
  { id: 4, value: "Other" },
];

const JobbShiftPostDetails = ({
  handleCloseDetail,
  selectedData,
  ShiftJobTable,
  fetchShiftLogs,
  setShowQueryModal,
  showQueryModal
}) => {
  let [raiseQuery, setRaiseQuery] = useState({
    reason: "",
    comment: "",
  });

  let [raiseQueryError, setRaiseQueryError] = useState({});
  // const [showQueryModal, setShowQueryModal] = useState(false);

  console.log("raiseQUery", raiseQuery);
  console.log("ShiftJobTable", ShiftJobTable);

  function validate() {
    let error = {};
    if (!raiseQuery.reason) {
      error.reason = "please select one reason";
    }
    if (!raiseQuery.comment) {
      error.comment = "please provide reason to remove";
    }
    if (raiseQuery.comment.length < 8) {
      error.comment = "please provide valid comment";
    }
    return error;
  }

  useEffect(() => {
    setRaiseQueryError(validate());
  }, [raiseQuery]);

  let handleConfirm = () => {
    postRaiseQuery();
    fetchShiftLogs();
    handleCloseDetail(false);
    setShowQueryModal(false);
  };

  console.log("selectedData", selectedData);
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  let postRaiseQuery = () => {
    const query = `
      mutation {
        mutationWithAuth(token: "${token}") {
          update {
            raiseAQuery(reason: "${raiseQuery.reason}", description: "${raiseQuery.comment}", jobId: "${selectedData.jobId}", jobDate: "1659008700000", department: "${selectedData.specialization}", professionalName: "${selectedData.professionalName}", professionalId: "${selectedData.id}")
            }
          }
        }
      `;

    graphqlRequest(query).then((response) => {
      if (
        response?.data?.mutationWithAuth?.update?.raiseAQuery === "query raised"
      ) {
        message.success("Query has been raised Succesfully");
        setShowQueryModal(false);
      }
    });
  };

  let handleQuery = (data, label) => {
    setRaiseQuery({ ...raiseQuery, [label]: data });
  };

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackRaisedQueryYes = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.SHIFT_LOGS_QUERRY_RAISED
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackRaisedQueryNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.SHIFT_LOGS_QUERRY_DISCARD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div
      style={{
        borderRadius: "8px",
        backgroundColor: "#fff",
        width: "488px",
        height: "426px",
        left: "31%",
        top: "20%",
        position: "absolute",
        padding: "20px",
      }}
    >
      <h3
        style={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: 500,
          margin: "0 0 10px 0",
        }}
      >
        Do you want to raise a query to admin ?
      </h3>
      <div style={{ display: "flex", gap: "20px" }}>
        <p style={{ fontSize: "14px", fontWeight: 400 }}>Reason For query:</p>
        <Select
          sx={{ width: "60%", borderRadius: "8px" }}
          displayEmpty
          onChange={(e) => handleQuery(e.target.value, "reason")}
          renderValue={(selected) => {
            if (!selected) return "Reason";
            else return selected;
          }}
        >
          {dropdown.map((data) => {
            return <MenuItem value={data.value}>{data.value}</MenuItem>;
          })}
        </Select>
      </div>
      <div>
        <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
          Comments<span style={{ color: "#d74f58" }}>*</span>
        </h3>
        <textarea
          aria-label="minimum height"
          rows="8"
          cols="80"
          placeholder="Type Here"
          onChange={(e) => handleQuery(e.target.value.trim(), "comment")}
          style={{
            width: "96%",
            border: "none",
            outline: "none",
            padding: "10px",
            background: "#F7F7F7",
            borderRadius: "8px",
            resize: "none",
            overFlowX: "auto",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "right",
          paddingTop: "40px",
        }}
      >
        <button
          onClick={() => {
            handleCloseDetail(false);
            setShowQueryModal(false);
            trackRaisedQueryNo();
          }}
          className="cancelButton"
          id="Shift_logs_Querry_Discard"
        >
          No
        </button>
        <button
          onClick={() => {
            handleConfirm();
            trackRaisedQueryYes();
          }}
          className="confirmButton"
          style={{
            opacity: Object.keys(raiseQueryError).length !== 0 ? "0.3" : "1",
            cursor:
              Object.keys(raiseQueryError).length !== 0 ? "default" : "pointer",
          }}
          disabled={Object.keys(raiseQueryError).length !== 0}
          id="Shift_logs_Querry_Raised"
        >
          Yes
        </button>
      </div>{" "}
    </div>
  );
};

export default JobbShiftPostDetails;
