import React, { useEffect, useState } from "react";
import graphqlRequest from "../../requestMethod";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { login } from "../../redux/apiCall";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Typography,
  TextField,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import blitz from "blitzllama-js";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loginInfo, setLoginInfo] = useState({ value: "", type: "" });
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userInput, setUserInput] = useState(null);
  const [token, setToken] = useState("");
  const [isOtpModelOpen, setOtpModelOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };
  const openBlitzllamaSurvey = async () => {
    const user = await fetchUserId();
    // console.log("1--->", user.data.withAuth.userDetails.emails[0].address)
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    //const combined = "USER_ID"; // Replace with the actual user identifier
    blitz.init(process.env.REACT_APP_BLITZLLAMA_API_KEY);
    blitz.logout();
    blitz.createUser(userId);
    const eventName = "nps";
    const eventData = { event_property: "LOGIN" };
    blitz.triggerEvent(eventName, eventData);
    blitz.setUserProperties({ name: userName, email: userEmail });
  };

  const handleSendOTP = async () => {
    // console.log("loginInfo", loginInfo);
    if (!loginInfo.value) {
      setError("Please enter your email or phone number.");
      return;
    }
    const adminToken = localStorage.getItem("adminToken");
    // console.log("adminToken", adminToken);
    const query = `
    query{
      withAuth(token:"${adminToken}"){
      loginAdmin(emailOrPhone:"${loginInfo.value}",isWeb:true)
    }
  } 
  `;

    try {
      const response = await graphqlRequest(query);
      //  console.log("67-->",response )
      if (response?.errors?.[0]?.message === "Check your credentials!") {
        message.error("Email/Phone number is not registered with us!");
      } else if (
        response?.errors?.[0]?.message ===
        "You can't login into admin's account!"
      ) {
        message.error("You can't login into admin's account!");
      } else if (
        response?.errors?.[0]?.message ===
        "Professional, please login using mobile app!"
      ) {
        message.error("Professional, please login using mobile app!");
      } else {
        //console.log("70-->",response);
        localStorage.setItem("verifyToken", response.data.withAuth.loginAdmin);
        //setToken(response?.data?.withAuth?.loginAdmin);
        setOtpModelOpen(true);
        message.success("OTP has been sent.");
      }
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      setError("Please enter the OTP.");
      return;
    }

    if (!loginInfo.value) {
      setError("Please enter your email or phone number.");
      return;
    }
    const verifyToken = localStorage.getItem("verifyToken");
    // console.log("verifyToken", verifyToken);
    try {
      const query = `
      mutation {
        verifyLogin(verifyToken: "${verifyToken}", otp: "${otp}") {
          name
          pic
          email
          email_verified
          accessToken
          refreshToken
          verifyToken
          isAdmin
        }
      }
    `;

      const response = await graphqlRequest(query);
      //console.log("111-->", response.data.verifyLogin.accessToken);
      // localStorage.setItem('userLogin', response.data.verifyLogin.accessToken);

      return response;

      // if (response.data.verifyLogin) {
      //   const { accessToken, refreshToken } = response.data.verifyLogin;
      //   console.log("115-->", accessToken)
      //   // setToken(accessToken);
      //   // setRefreshToken(refreshToken);
      //   // navigate("/");
      //   message.success('OTP has been verified successfully!');
      //   return response;
      // } else {
      //   setError('Invalid OTP. Please try again.');
      // }
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
      setError("Failed to verify OTP. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!loginInfo.value) {
      setError("Please enter your email or phone number.");
      return;
    }

    if (loginInfo.type === "email" && otp) {
      try {
        const response = await handleVerifyOTP();
        // console.log("147-->",response.data.verifyLogin.accessToken);
        if (response?.data?.verifyLogin) {
          //console.log("150-->",response.data.verifyLogin);
          localStorage.setItem(
            "auth",
            JSON.stringify(response.data.verifyLogin)
          );
          navigate("/");
          //setSuccessMessage('Login successful!');
          message.success("Login Successful!");
          openBlitzllamaSurvey();
          setError("");
        } else {
          setError("Invalid OTP. Please try again.");
          setSuccessMessage("");
        }
      } catch (error) {
        setError("An error occurred while authenticating the user.");
        setSuccessMessage("");
      }
    }

    if (loginInfo.type === "phoneNumber" && otp) {
      try {
        const response = await handleVerifyOTP();
        if (response?.data?.verifyLogin) {
          localStorage.setItem(
            "auth",
            JSON.stringify(response.data.verifyLogin)
          );
          navigate("/");
          //setSuccessMessage('Login successful!');
          message.success("Login Successful!");
          openBlitzllamaSurvey();
          setError("");
        } else {
          setError("Invalid OTP. Please try again.");
          setSuccessMessage("");
        }
      } catch (error) {
        setError("An error occurred while authenticating the user.");
        setSuccessMessage("");
      }
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const type = value.includes("@") ? "email" : "phoneNumber";
    setLoginInfo({ value, type });
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Typography variant="h4" component="h1" align="center">
        Admin Login
      </Typography>
      {successMessage && (
        <Typography variant="body2" color="primary" align="center">
          {successMessage}
        </Typography>
      )}
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="loginInfo"
          label="Email Address or Phone Number"
          name="loginInfo"
          autoComplete="off"
          autoFocus
          value={loginInfo.value}
          onChange={handleInputChange}
        />
        {loginInfo.value && (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="otp"
              label="One-Time Password (OTP)"
              type="text"
              id="otp"
              autoComplete="off"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {!otp ? (
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={handleSendOTP}
              >
                Send OTP
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
              >
                Log In
              </Button>
            )}
          </>
        )}
        {error && (
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        )}
      </form>
    </Container>
  );
};

export default LoginPage;
