const eventHelpers = {
  INT_JOB_PST: "Int_Job_Pst",  //done
  OPN_JOB: "Opn_Job",     //done
  OPN_JOB_ACTION: "opn_Job_Action",
  ACTION_INTERVIEW: "Action_Interview",
  ACPT_JOB: "Acpt_Job",  //done
  ONG_JOB: "Ong_Job",   //done
  CMPT_JOB: "Cmpt_Job",   //done
  CAN_JOB: "Can_Job",    //done
  SHFT_LOG: "Shft_Log",   //done
  RPT_LOG: "Rpt_Log",     //done
  UP_EVNTS: "Upcmg_Evnts",  //done
  DASH_BRD: "Dash_brd",     //done
  FINAL_JOBS_PST: "Final_Jobs_Pst",  //done
  DISCRD_JOB_Y: "Discrd_Job_Y",       //done
  DISCRD_JOB_N: "Discrd_Job_N",       //done
  SHIFT_LOGS_QUERRY_RAISED: "Shift_logs_Querry_Raised",       //done
  SHIFT_LOGS_QUERRY_DISCARD: "Shift_logs_Querry_Discard",       //done

  APLICNT_RCV: "Aplicnt_Rcv",  //doubts
  LOCUM_RJCT: "Locum_Rjct",       //done
  LOCUM_ACPT: "Locum_Acpt",       //done
  LOCUM_SHRTLST: "Locum_Shrtlst",       //done
  LOCUM_INTRV: "Locum_Intrv",       //done
  CANCEL_OPN_L_JB_Y: "Cancel_Opn_L_Jb_Y",        //done
  CANCEL_OPN_L_JB_N: "Cancel_Opn_L_Jb_N",       //done

  DASH_BOARD_HVR: "Dash_board_Hvr",       //done
  BILLING: "Billing",       //done
  BILLING_DOWLD: "Billing_Dowld",       //done
  BILLING_EXPORT: "Billing_Export",  //left
  RE_OPN_JB: "Re_Opn_Jb",       //done
  RE_ACPT_JB: "Re_Acpt_Jb",       //done
  RE_ONG_JB: "Re_Ong_Jb",       //done
  RE_CMPT_JB: "Re_Cmpt_Jb",       //done
  RE_CAN_JB: "Re_Can_Jb",       //done
  PERCENT_SCROLLED: "Percent_scrolled",       //done
  CREATIVE: "Creative",       //done

  DID_U_KNW: "Did_U_Knw",       //done
  DASH_BD_LOCUM: "Dash_Bd_Locum",       //done
  DASH_BD_PRMNT: "Dash_Bd_Prmnt",       //done
  PERMNT_JOBS: "Permnt_Jobs",       //done
  DASH_BRD_LCM: "Dash_Brd_Lcm",       //done
  DASH_BRD_ACTV: "Dash_Brd_Actv",       //done
  DASH_BRD_INTERV_SCH: "Dash_Brd_Interv_Sch",  //no need

  OPN_JB_DWNLD: "Opn_JB_Dwnld",  //start doubts
  ACCPT_JB_DWNLD: "Accpt_JB_Dwnld", 
  ONG_JB_DWNLD: "Ong_JB_Dwnld",   
  CMPT_JB_DWNLD: "Cmpt_JB_Dwnld",
  CANC_JB_DWNLD: "Canc_JB_Dwnld",
  CMPT_JB_AUDIT: "Cmpt_JB_Audit", // end doubt
  PRFL_VIEW: "Prfl_View",      //done

INVT_2_APPLY: "Invt_2_Apply",      //done
DOWNLD_RESUME: "Downld_Resume",      //done
LOGIN_INT_OTP: "Login_Int_OTP",
LOGIN_INT_PASWRD: "Login_Int_Paswrd",      //done

Sign_in_Google: "Sign_in_Google",  //left

FRGT_PASWRD: "Frgt_Paswrd",      //done
LOGIN_FINAL_PASWRD: "Login_Final_Paswrd",      //done
LOGIN_FINAL_OTP: "Login_Final_OTP",      //done
RSND_OTP: "Rsnd_OTP",      //done
OTP_VERFIED: "OTP_Verfied",      //done

USER_LIST: "User_List",  //doubt
USER_PERMSN: "User_Permsn",

VIEW_ACT_ONG_JB: "View_Act_Ong_Jb",      //done
CANCL_ONG_LJB_Y: "Cancl_Ong_LJb_Y",      //done
CANCL_ONG_LJB_N: "Cancl_Ong_LJb_N",      //done
ACTION_CANCLD_LJB: "Action_Cancld_LJb",      //done
VIEW_ACT_ACCPT_LJB: "View_Act_Accpt_LJb",
CANCL_ACCPT_LJB_Y: "Cancl_Accpt_LJb_Y",
CANCL_ACCPT_LJB_N: "Cancl_Accpt_LJb_N",
LOGOUT_Y: "Logout_Y",      //done
LOGOUT_N: "Logout_N",      //done
BFR_SCHEDULE_INTRV: "Bfr_Schedule_Intrv",  
ONTIME_SCHEDULE_INTERVIEW: "Ontime_Schedule_Interview",
JOBS_PAST_7_DAYS: "Jobs_Past_7_Days",
JOBS_FORTNIGHT: "Jobs_Fortnight",
JOBS_MONTH: "Jobs_Month",
JOBS_YEAR: "Jobs_Year",
APPLICNT_PREV_EXP: "Applicnt_Prev_Exp",
APPLICNT_EDU: "Applicnt_Edu",
PST_L_JOBS: "Pst_L_Jobs",
PST_P_JOBS: "Pst_P_Jobs"


};

export default eventHelpers;
