import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import completed from "../../asset/postjob/completed.svg";
import pending from "../../asset/postjob/pending.svg";
import calendar from "../../asset/postjob/calendar.svg";
import clock from "../../asset/postjob/clock.svg";
import check from "../../asset/postjob/check.svg";
import uncheck from "../../asset/postjob/uncheck.svg";
import * as locales from "react-date-range/dist/locale";
import { Calendar } from "react-date-range";
import { format } from "date-fns";

import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./PermanentJobForm.scss";
import { useDispatch } from "react-redux";
import { setOpenJobMatches, setActiveTab } from "../../redux/userReducer";
import { Link } from "react-router-dom";
import graphqlRequest from "../../requestMethod";

function PermanentJobForm(props) {
  let dispatch = useDispatch();
  let [openStartDate, setOpenStartDate] = useState(false);
  let [openEndDate, setOpenEndDate] = useState(false);
  let [hospitals, setHospitals] = useState([]);
  let [profession, setProfession] = useState([]);
  let [qualification, setQualification] = useState([]);
  let [department, setDepartment] = useState([]);
  let [specialization, setSpecialization] = useState([]);
  let [experience, setExperience] = useState([]);
  let [professionalDetails, setProfessionalDetails] = useState({
    hospitals: "",
    profession: "",
    qualification: "",
    department: "",
    specialization: "",
    experience: "",
  });
  let [jobDetails, setJobDetails] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  let [additionalFacilities, setAdditionalFacilities] = useState({
    isFood: false,
    isAccommodation: false,
    isTransport: false,
  });
  let [jobDescription, setJobDescription] = useState("");
  let [FetchContactDetails, setFetchContactDetails] = useState([]);
  let [contactDetails, setContactDetails] = useState({});

  let handleDropdown = (value, name) => {
    setProfessionalDetails({ ...professionalDetails, [name]: value });
  };
  let handleContactDetails = (e) => {
    let selectedData = FetchContactDetails.filter(
      (data) => data.name === e.target.value
    );

    console.log(selectedData);
    setContactDetails({
      name: e.target.value,
      email: selectedData[0].email,
      contact: selectedData[0].contact === null ? "" : selectedData[0].contact,
    });
  };
  // fetch hospita dropdown and Contact Details
  let data = localStorage.getItem("auth");
let token = JSON.parse(data)?.accessToken;
  useEffect(() => {
    function fetchHospitalDropdownData() {
      const query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobBranches{
            id
            jobLocation {
              hospitalName
              branchName
            }
         }
         }
       }
    `;

      graphqlRequest(query).then((response) => {
        setHospitals(response.data.withAuth.listMiniJobBranches);
      });
    }
    function fetchContactDetailsUser() {
      const query = `
      query{
        withAuth(token:"${token}"){
          listOrganizationUsers {
            id
            email
            contact
            name
          }
         }
       }
    `;
      graphqlRequest(query).then((response) => {
        setFetchContactDetails(response.data.withAuth.listOrganizationUsers);
      });
    }
    fetchHospitalDropdownData();
    fetchContactDetailsUser();
  }, []);

  // fetch department dropdown
  useEffect(() => {
    function fetchDepartmentDropDown() {
      let id = hospitals.filter(
        (data) => data.jobLocation.hospitalName === professionalDetails.hospital
      )[0].id;
      let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobDepartments(branch:"${id}") {
            id
            departmentType{
              name
            }
          }
         }
       }
      `;
      graphqlRequest(query).then((response) => {
        console.log(response.data.withAuth.listMiniJobDepartments);
        setDepartment(response.data.withAuth.listMiniJobDepartments);
      });
    }
    professionalDetails?.hospital?.length > 2 && fetchDepartmentDropDown();
  }, [professionalDetails.hospital]);

  // fetch profession dropdown
  useEffect(() => {
    function fetchProfessionsDropDown() {
      let hospitalId = hospitals.filter(
        (data) => data.jobLocation.hospitalName === professionalDetails.hospital
      )[0].id;

      let departmentId = department.filter(
        (data) => data.departmentType.name === professionalDetails.department
      )[0].id;

      let query = `
      query{
        withAuth(token:"${token}"){
          listMiniJobProfessions (branch:"${hospitalId}" department:"${departmentId}") {
            id
            name
            postCost
          }
         }
       }
      `;
      graphqlRequest(query).then((response) => {
        response.data.withAuth.listMiniJobProfessions.length === 1 &&
          setProfessionalDetails({
            ...professionalDetails,
            profession: response.data.withAuth.listMiniJobProfessions[0].name,
          });
        setProfession(response.data.withAuth.listMiniJobProfessions);
      });
    }
    professionalDetails?.department?.length > 2 && fetchProfessionsDropDown();
  }, [professionalDetails.department]);

  // fetch qualification dropdown
  useEffect(() => {
    console.log("useEffect called for qualifiacation");
    function fetchQualificationDropDown() {
      console.log("useEffect called for qualifiacation api");
      let hospitalId = hospitals.filter(
        (data) => data.jobLocation.hospitalName === professionalDetails.hospital
      )[0]?.id;

      let departmentId = department.filter(
        (data) => data.departmentType.name === professionalDetails.department
      )[0]?.id;

      let professionId = profession.filter(
        (data) => data.name === professionalDetails.profession
      )[0]?.id;
      let query = `
        query{
          withAuth(token:"${token}"){
            listMiniJobQualifications (branch:"${hospitalId}" 
                                      department:"${departmentId}"
                                      profession:"${professionId}"
            ) {
              id
              name
            }
           }
         }
        `;
      graphqlRequest(query).then((response) => {
        response.data.withAuth.listMiniJobQualifications.length === 1 &&
          setProfessionalDetails({
            ...professionalDetails,
            qualification:
              response.data.withAuth.listMiniJobQualifications[0].name,
          });
        setQualification(response.data.withAuth.listMiniJobQualifications);
      });
    }
    professionalDetails?.profession?.length > 2 && fetchQualificationDropDown();
  }, [professionalDetails.profession]);
  // fetch specialization dropdown
  useEffect(() => {
    function fetchSpecializationDropDown() {
      let hospitalId = hospitals.filter(
        (data) => data.jobLocation.hospitalName === professionalDetails.hospital
      )[0]?.id;

      let departmentId = department.filter(
        (data) => data.departmentType.name === professionalDetails.department
      )[0]?.id;

      let professionId = profession.filter(
        (data) => data.name === professionalDetails.profession
      )[0]?.id;

      let qualificationId = qualification.filter(
        (data) => data.name === professionalDetails.qualification
      )[0]?.id;

      let query = `
        query{
          withAuth(token:"${token}"){
            listMiniJobSpecializations (branch:"${hospitalId}" 
                                      department:"${departmentId}"
                                      profession:"${professionId}"
                                      qualification:"${qualificationId}"
            ) {
              id
              name
            }
           }
         }
        `;
      graphqlRequest(query).then((response) => {
        response.data.withAuth.listMiniJobSpecializations.length === 1 &&
          setProfessionalDetails({
            ...professionalDetails,
            specialization:
              response.data.withAuth.listMiniJobSpecializations[0].name,
          });
        setSpecialization(response.data.withAuth.listMiniJobSpecializations);
      });
    }
    professionalDetails.qualification?.length > 2 &&
      fetchSpecializationDropDown();
  }, [professionalDetails.qualification]);

  // fetch experience dropdown
  useEffect(() => {
    function fetchSpecializationDropDown() {
      let hospitalId = hospitals.filter(
        (data) => data.jobLocation.hospitalName === professionalDetails.hospital
      )[0]?.id;

      let departmentId = department.filter(
        (data) => data.departmentType.name === professionalDetails.department
      )[0]?.id;

      let professionId = profession.filter(
        (data) => data.name === professionalDetails.profession
      )[0]?.id;

      let qualificationId = qualification.filter(
        (data) => data.name === professionalDetails.qualification
      )[0]?.id;

      let specializationId = specialization.filter(
        (data) => data.name === professionalDetails.specialization
      )[0]?.id;

      let query = `
        query{
          withAuth(token:"${token}"){
            listMiniJobExperiences (branch:"${hospitalId}" 
                                      department:"${departmentId}"
                                      profession:"${professionId}"
                                      qualification:"${qualificationId}"
                                      specialization: "${specializationId}"
            ) {
              id
              name
            }
           }
         }
        `;
      graphqlRequest(query).then((response) => {
        response.data.withAuth.listMiniJobExperiences.length === 1 &&
          setProfessionalDetails({
            ...professionalDetails,
            experience: response.data.withAuth.listMiniJobExperiences[0].name,
          });
        setExperience(response.data.withAuth.listMiniJobExperiences);
      });
    }
    professionalDetails.qualification?.length > 2 &&
      fetchSpecializationDropDown();
  }, [professionalDetails.specialization]);

  return (
    <div className="permanent-form-container">
      <div className="permanent-form-wrapper">
        <JobsHeader title={"Post A job"} />
        <BreadCrumb action={"Post a job"} title={"Permanent"} />
        <div className="professional-details-form">
          <div className="pdf-wrapper">
            <div className="pdf-top">
              <img src={pending} alt="form-input-completed" />
              <h3 className="lucum-job-form-title">Professional Details</h3>
            </div>
            <div className="pdf-bottom">
              <div className="hospital-input-container">
                {/* hospita dropdown */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  value={professionalDetails.hospital}
                  onChange={(e) => handleDropdown(e.target.value, "hospital")}
                  displayEmpty
                   renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Hospital Name";
                    else return selected;
                }}   
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    {/* hospital */}
                  </MenuItem>
                  {hospitals.map((data) => (
                    <MenuItem value={data.jobLocation.hospitalName}>
                      {data.jobLocation.hospitalName}
                    </MenuItem>
                  ))}
                </Select>
                {/* profession */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  defaultValue={professionalDetails?.profession}
                  value={professionalDetails?.profession}
                  onChange={(e) => handleDropdown(e.target.value, "profession")}
                  displayEmpty
                   renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Profession";
                    else return selected;
                }} 
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="ok">
                    {/* {data.label} */}
                  </MenuItem>
                  {profession.map((data) => (
                    <MenuItem value={data.name}>{data.name}</MenuItem>
                  ))}
                </Select>
                {/* qualification */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  // defaultValue={professionalDetails?.qualification}
                  defaultValue={professionalDetails.qualification}
                  value={professionalDetails?.qualification}
                  onChange={(e) =>
                    handleDropdown(e.target.value, "qualification")
                  }
                  displayEmpty
                   renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Qualification";
                    else return selected;
                }} 
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="ok">
                    {/* {data.label} */}
                  </MenuItem>
                  {qualification.map((data) => (
                    <MenuItem value={data.name}>{data.name}</MenuItem>
                  ))}
                </Select>
                {/* department */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  value={professionalDetails?.department}
                  onChange={(e) => handleDropdown(e.target.value, "department")}
                  displayEmpty
                   renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Department";
                    else return selected;
                }} 
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="ok">
                    {/* {data.label} */}
                  </MenuItem>
                  {department.map((data) => (
                    <MenuItem value={data.departmentType.name}>
                      {data.departmentType.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* specialization */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  defaultValue={professionalDetails?.specialization}
                  value={professionalDetails?.specialization}
                  onChange={(e) =>
                    handleDropdown(e.target.value, "specialization")
                  }
                  displayEmpty
                  renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Specialization";
                    else return selected;
                }} 
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="ok">
                    {/* {data.label} */}
                  </MenuItem>
                  {specialization.map((data) => (
                    <MenuItem value={data.name}>{data.name}</MenuItem>
                  ))}
                </Select>
                {/* experience */}
                <Select
                  className="dropdown-hospital-permanent-job"
                  sx={{
                    width: "300px",
                  }}
                  defaultValue={professionalDetails.experience}
                  value={professionalDetails?.experience}
                  onChange={(e) => handleDropdown(e.target.value, "experience")}
                  displayEmpty
                   renderValue={selected => {
                    console.log(selected);
                    if (!selected) return "Experience";
                    else return selected;
                }} 
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="ok">
                    {/* {data.label} */}
                  </MenuItem>
                  {experience.map((data) => (
                    <MenuItem value={data.name}>{data.name}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="job-details-form">
          <div className="jdf-wrapper">
            <div className="jdf-top">
              <img src={pending} alt="pending" />
              <h3 className="lucum-job-form-title">Job Details</h3>
            </div>
            <div className="jdf-bottom">
              <div className="jdf-left">
                <label className="input-container">
                  <input
                    type="text"
                    placeholder="Start Date"
                    value={format(jobDetails.startDate, "dd/MM/yy")}
                  />
                  <img
                    src={calendar}
                    onClick={() => setOpenStartDate(!openStartDate)}
                    alt="calender-start"
                  />
                </label>
                {openStartDate && (
                  <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                    <Calendar
                      className="calendar-date-input"
                      onChange={(item) => {
                        setJobDetails({ ...jobDetails, startDate: item });
                        setOpenStartDate(false);
                      }}
                      locale={locales["enUS"]}
                      date={jobDetails.startDate}
                    />
                  </div>
                )}
                <label className="input-container">
                  <input
                    type="text"
                    placeholder="Shift Timing- Start"
                    value={jobDetails.startTime}
                    onChange={(e) =>
                      setJobDetails({
                        ...jobDetails,
                        startTime: e.target.value,
                      })
                    }
                  />
                  <img src={clock} />
                </label>
              </div>

              <div className="jdf-right">
                <label className="input-container">
                  <input
                    type="text"
                    placeholder="End Date"
                    value={format(jobDetails.endDate, "dd/MM/yy")}
                  />
                  <img
                    src={calendar}
                    onClick={() => setOpenEndDate(!openEndDate)}
                    alt="end-date"
                  />
                </label>
                {openEndDate && (
                  <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                    <Calendar
                      className="calendar-date-input"
                      onChange={(item) => {
                        setJobDetails({ ...jobDetails, endDate: item });
                        setOpenEndDate(false);
                      }}
                      locale={locales["enUS"]}
                      date={jobDetails.endDate}
                    />
                  </div>
                )}
                <label className="input-container">
                  <input
                    type="text"
                    placeholder="Shift Timing- End"
                    value={jobDetails.endTime}
                    onChange={(e) =>
                      jobDetails({ ...jobDetails, endTime: e.target.value })
                    }
                  />
                  <img src={clock} />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="job-description-form">
          <div className="jdf-wrapper">
            <div className="jdf-top">
              <img src={pending} alt="pending" />
              <h3 className="lucum-job-form-title">Job Description</h3>
            </div>
            <div className="jdf-bottom">
              <label>Type here</label>
              <textarea
                id="story"
                name="story"
                rows="8"
                cols="85"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="additional-facilities-form">
          <div className="aff-wrapper">
            <div className="aff-top">
              <img src={pending} alt="pending-input" />
              <h3 className="lucum-job-form-title">Additional Facilities</h3>
            </div>
            <div className="aff-bottom">
              <div className="first-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() =>
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isFood: !additionalFacilities.isFood,
                        })
                      }
                    />
                  }
                  label="Food"
                  className="lucam-job-checkbox"
                />
              </div>
              <div className="second-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() =>
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isTransport: !additionalFacilities.isTransport,
                        })
                      }
                    />
                  }
                  label="Transport"
                  className="lucam-job-checkbox"
                />
              </div>
              <div className="third-input">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src={uncheck} />}
                      checkedIcon={<img src={check} />}
                      onClick={() =>
                        setAdditionalFacilities({
                          ...additionalFacilities,
                          isAccommodation:
                            !additionalFacilities.isAccommodation,
                        })
                      }
                    />
                  }
                  label="Accommodation"
                  className="lucam-job-checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-details-form">
          <div className="cdf-wrapper">
            <div className="cdf-top">
              <img src={pending} alt="pending" />
              <h3 className="lucum-job-form-title">Contact Details</h3>
            </div>
            <div className="cdf-bottom">
              <Select
                className="dropdown-hospital-permanent-job"
                sx={{
                  width: "300px", height:"47px",
                }}
                value={contactDetails.name}
                onChange={(e) => handleContactDetails(e)}
                displayEmpty
                renderValue={selected => {
                  console.log(selected);
                  if (!selected) return "Name";
                  else return selected;
              }} 
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  {/* hospital */}
                </MenuItem>
                {FetchContactDetails?.map((data) => (
                  <MenuItem value={data.name}>{data.name}</MenuItem>
                ))}
              </Select>
              <TextField
                disabled
                id="outlined-basic"
                // label="Email id"
                  placeholder="email"
                name="emailId"
                variant="outlined"
                sx={{ width: "328px" }}
                value={contactDetails?.email}
              />
              <TextField
                disabled
                id="outlined-basic"
                // label="Contact no."
                placeholder="Contact No"
                name="contactNo"
                variant="outlined"
                sx={{ width: "328px" }}
                type="number"
                value={contactDetails?.contact}
              />
            </div>
          </div>
        </div>
        <div className="basic-cost-form">
          <div className="bcf-wrapper">
            <div className="bcf-top">
              <div className="bcf-top-one">
                <h3 className="lucum-job-form-title">Basic Cost</h3>
              </div>
              <div className="bcf-top-two">
                <div className="bcf-details">
                  <p>Total for emplpoyer</p>
                  <label>Rs. XXX</label>
                </div>
                <div className="bcf-details">
                  <p>Total for emplpoyer</p>
                  <label>Rs. XXX</label>
                </div>
                <div className="bcf-details">
                  <p>Total for emplpoyer</p>
                  <label>Rs. XXX</label>
                </div>
                <div className="bcf-details">
                  <p>Total for emplpoyer</p>
                  <span>*Inclusive of taxes</span>
                  <label>Rs. XXX</label>
                </div>
              </div>
            </div>
            <div className="bcf-bottom">
              <button
                className="post-button"
                onClick={() => dispatch(setOpenJobMatches())}
              >
                Post a Job!
              </button>
              <Link
                to="/"
                onClick={() =>
                  dispatch(setActiveTab({ index: 1, LocumJobToggle: false }))
                }
              >
                <button className="cancel-button">Cancel</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermanentJobForm;
