import React, { useState } from "react";
import "./FilterReportJobs.scss";
import { MenuItem, TextField, Modal, FormControl } from "@mui/material";
import { CSVLink } from "react-csv";

import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import EventNoteIcon from "@mui/icons-material/EventNote";

import CalendarRange from "../../PopUp/CalendarRange/CalendarRange";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

//

const FilterReportJobs = ({
  fetchAllData,
  csvData,
  filterDate,
  setFilterDate,
  tableData,
  setFilterTable,
  globalInputSearch,
  SetGlobalInputSearch,
  departmentList,
  departmentValue,
  setDepartmentValue,
  professionsValue,
  setProfessionValue,
  professionalList,
  exportPDF,
  keyword,
  setKeyword,
  filterByProfession,
  setFilterByProfession,
  filterByDepartment,
  setFilterByDepartment,
  filterByStartDate,
  setFilterByStartDate,
  filterByEndDate,
  setFilterByEndDate,
  totalData,
}) => {
  let [downloadOption, setDownloadOption] = useState("");
  let [openRangeCalendar, setOpenRangeCalendar] = useState(false);

  const handleDownloadChange = (e) => {
    // console.log("48-->", totalData)
    setDownloadOption(e?.target?.value);
    // if (e.target.value === "PDF") {
    //   exportPDF(totalData); 
    // } else if (e.target.value === "CSV") {
    //   // const csvData = totalData;
    //   csvData(totalData)
    // }
  };

  const handleDownload = () => {
    if (downloadOption === "PDF") {
      exportPDF(totalData); 
    } else if (downloadOption === "CSV") {
      const csvData = totalData; 
      
    }
  };

  function resetAll() {
    setDepartmentValue("");
    setProfessionValue("");
    setFilterByProfession("");
   setFilterByDepartment("");
   setKeyword("");
   setFilterByStartDate("");
    setFilterByEndDate("");
    SetGlobalInputSearch("");
    setFilterTable(tableData);
    setDownloadOption("");
    setOpenRangeCalendar("");
    fetchAllData();
    setFilterDate([{ key: "selection" }]);
  }
  function formatDate(date) {
    // return date instanceof Date ? date.toLocaleDateString("en-GB"): date;
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  // const fetchUserId = async () => {
  //   let data = localStorage.getItem("auth");
  //   let token = JSON.parse(data)?.accessToken;
  //   try {
  //     const query = `
  //   query {
  //     withAuth(token: "${token}"){
  //      userDetails{
  //       id,
  //       name {
  //         salutation
  //         first
  //         middle
  //         last
  //         full
  //       },
  //       emails {
  //         isPrimary
  //         isVerified
  //         address
  //       }
  //     }
  //     }
  //   }
  //     `;
  //     const response = await graphqlRequest(query);
  //     // console.log("175-->",response)
  //     return response;
  //   } catch (error) {
  //     console.error("Error sending GraphQL request:", error);
  //   }
  // };

  // const trackReOpenJobDwnld = async () => {
  //   const user = await fetchUserId();
  //   // console.log("user---->", user);
  //   const userId = user.data.withAuth.userDetails.id;
  //   const userName = user.data.withAuth.userDetails.name.full;
  //   const userEmail = user.data.withAuth.userDetails.emails[0].address;
  //   if (window.Moengage) {
  //     //set unique id
  //     window.Moengage.add_unique_user_id(userId);
  //     //set user attributes
  //     window.Moengage.add_user_name(userName);
  //     window.Moengage.add_email(userEmail);
  //     //set event data
  //     window.Moengage.track_event(
  //       eventHelpers.OPN_JB_DWNLD
  //       // {
  //       // "ID": userId,
  //       // "name": userName,
  //       // "Email": userEmail,
  //       // "date": Date.now(),
  //       //}
  //     );
  //     // window.Moengage.destroy_session();
  //   }
  // };

  console.log("deparmentList",departmentList )
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div className="sl-filter">
        <label className="global-report-search-container">
          <input
            type="text"
            placeholder="Search"
            value={keyword}
            onChange={(e) => setKeyword(e?.target?.value)}
          />
          <div>
            <SearchIcon />
          </div>
        </label>
        <FormControl sx={{}}>
          <TextField
            select
            sx={{
              width: "150px",
            }}
            size="small"
            value={filterByDepartment}
            label="Department"
            onChange={(e) => setFilterByDepartment(e?.target?.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {departmentList?.map((data) => (
              <MenuItem value={data}>{data}</MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl sx={{}}>
          <TextField
            select
            size="small"
            label="Profession"
            sx={{ width: "150px" }}
            value={filterByProfession}
            onChange={(e) => setFilterByProfession(e?.target?.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {professionalList?.map((data) => (
              <MenuItem value={data}>{data}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <div className="filter-button-a" onClick={resetAll}>
          <RotateLeftIcon />
        </div>
        <label className="date-range-container">
          <input
            type="text"
            placeholder="Search"
            // value={
            //   filterDate[0]?.startDate
            //     ? `${filterDate[0]?.startDate?.toLocaleDateString()} - ${filterDate[0]?.endDate?.toLocaleDateString()} `
            //     : "Date Range"
            // }
            // value={
            //   filterByStartDate instanceof Date && filterByEndDate instanceof Date
            //     ? `${filterByStartDate.toLocaleDateString()} - ${filterByEndDate.toLocaleDateString()}`
            //     : "Date Range"
            // }
            value={
              filterByStartDate  && filterByEndDate 
                ? `${formatDate(filterByStartDate)} - ${formatDate(filterByEndDate)}`
                : "Date Range"
            }
            style={{width: "100px"}}
          />
          <div onClick={() => setOpenRangeCalendar(!openRangeCalendar)}>
            <EventNoteIcon />
          </div>
        </label>
      </div>

      <TextField
        select
        size="small"
        sx={{ width: "120px", marginRight: "1rem" }}
        value={downloadOption}
        onChange={() => {
          handleDownloadChange();
          // trackReOpenJobDwnld()
        }}
        label="Download"
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        placeholder="Select"
      >
        <MenuItem disabled value="">
          Download
        </MenuItem>

        <MenuItem value="PDF">
          <span
            onClick={() => {
              exportPDF();
              // trackReOpenJobDwnld()
            }}
          >
            Download as Pdf
          </span>
        </MenuItem>
        <MenuItem value="CSV">
          {" "}
          <CSVLink data={csvData}>Download as CSV</CSVLink>
        </MenuItem>
      </TextField>
      <Modal
        open={openRangeCalendar}
        onClose={() => setOpenRangeCalendar(!openRangeCalendar)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CalendarRange
          setOpenRangeCalendar={setOpenRangeCalendar}
          // setFilterDate={setFilterDate}
          // filterDate={filterDate}
          filterByStartDate={filterByStartDate}
          setFilterByStartDate={setFilterByStartDate}
          filterByEndDate={filterByEndDate}
          setFilterByEndDate={setFilterByEndDate}
        />
      </Modal>
    </div>
  );
};

export default FilterReportJobs;
