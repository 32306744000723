import { Dialog } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./InterviewConfirmation.scss";
const InterviewConfirmation = ({ open, handleCloseModal, isdata }) => {
  console.log({ isdata });
  const navigate = useNavigate();
  return (
    <>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <div
          style={{
            width: "500px",
            padding: "20px 10px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            position: "absolute",
            left: "30%",
            top: "30%",
          }}
        >
          <p style={{ textAlign: "center" }}>
            You are joining too early for a Interview Call, Candidate will only
            be able to Join the call 10 minutes before the scheduled time
          </p>
          <div style={{ textAlign: "right" }}>
            <button
              id="Bfr_Schedule_Intrv"
              className="button-yes-interview"
              onClick={
                () => handleCloseModal(false)
                // navigate("/interviewPage",{
                //   state: isdata,
                // })
              }
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewConfirmation;
