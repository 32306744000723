import graphqlRequest from "../../requestMethod"

// let data = localStorage.getItem("auth");
// let accessToken = JSON.parse(data)?.accessToken;
  // let accessToken = data?.accessToken;

export default class JobService {

static async recordSession (interviewId) {

 let data = localStorage.getItem("auth");
let accessToken = JSON.parse(data).accessToken;
// const accessToken = "8516cbbb721d7fd37e5830724e29742863deed3d5b63c5c70c31bb805b7ee7605506a3c56a6a02a309e2251954fa5cf5084819387e77a2cffaee3b4b5ba8aecbaaaea23f41056afcad0e9802cea6d53f"
  if (!accessToken) return false
  const response = await graphqlRequest(
    `mutation {
      mutationWithAuth(token: "${accessToken}") {
      update {
      recordInterview(interviewId: "${interviewId}")
      }
      }
      }
    `
  )
  console.log("recordSession",response)
  if (response.error !== null) return false
  else return response.data.withAuth.listInterview
}
static async stopRecordSession (interviewId) {
  // const accessToken = "8516cbbb721d7fd37e5830724e29742863deed3d5b63c5c70c31bb805b7ee7605506a3c56a6a02a309e2251954fa5cf5084819387e77a2cffaee3b4b5ba8aecbaaaea23f41056afcad0e9802cea6d53f"
  let data = localStorage.getItem("auth");
  let accessToken = JSON.parse(data).accessToken;
  // const accessToken = localStorage.getItem('access-token')
  if (!accessToken) return false
  const response = await graphqlRequest(
    `mutation {
      mutationWithAuth(token: "${accessToken}") {
      update {
        stopInterviewRecord(interviewId: "${interviewId}")
      }
      }
      }
    `
  )
  console.log("stopSession",response)
  if (response.error !== null) return false
  else return response.data.withAuth.listInterview

}
}
