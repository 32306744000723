import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
const PrivateRoute = ({ privateComponent }) => {

const navigate = useNavigate();  
const {currentUser} = useSelector((state)=> state?.user)
console.log("userName", currentUser)

useEffect(()=>{
            // !localStorage.getItem("auth") && navigate("/login") //login
            !localStorage.getItem("auth") && navigate("/home")  //Home
},[navigate])

return(privateComponent)

};

export default PrivateRoute;
