import React from "react";
import CrossIcon from "../../../asset/jobs/close-circle.svg";
import "./CompletedJobModal.css";
const CompletedJobModal = ({ handleCloseDetail }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: "60%",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            cursor: "pointer",
          }}
        >
          <img src={CrossIcon} onClick={() => handleCloseDetail()} />
        </div>
        <div>
          <h2>Confirmation</h2>
        </div>
        <div>
          <h4>
            Self download functionality coming soon. Meanwhile, we have sent
            your request to jobizo team and they will send you the report on
            your registered email id within 2-3 working days<span style={{ color: "#d74f58" }}>*</span>
          </h4>
        </div>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <button className="OkButton" onClick={() => handleCloseDetail()}>
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedJobModal;
