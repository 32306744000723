import { createSlice } from "@reduxjs/toolkit";
import initialState from "./state";

export const permanentJobsSlice = createSlice({
  name: "permanentJobs",
  initialState,
  extraReducers: (builder) => {
    //Reducers For Permanent Jobs Actions
  },
});

export default permanentJobsSlice.reducer;
