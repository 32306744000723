import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from "@mui/material";
import React from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import check from "../../asset/postjob/check.svg";
import uncheck from "../../asset/postjob/uncheck.svg";
import "./Permission.scss";


function Permission(props) {
  return (
    <div className="permission-wrapper">
      <div className="permission-container">
        <BreadCrumb action={"User"} title={"Permission"} />
        <JobsHeader title={"Permission"} />
        <div className="permission-action">
     
            <h2>Access Required</h2>
            <h3>Company</h3>
          <div className="permission-dropdown">
          <FormControl sx={{}}>
        {/* <InputLabel>Department</InputLabel> */}
        <TextField
          select
          sx={{
            width: "300px",
          }}
          size="small"
          // value={departmentValue}
          label="Departments"
          // onChange={(e) => setDepartmentValue(e.target.value)}
          displayEmpty
          labelId="demo-simple-select-autowidth-label"
          inputProps={{ "aria-label": "Without label" }}
        >
          {/* <MenuItem disabled value="">
          Hospital
        </MenuItem> */}
          {/* {departmentList?.map((data) => ( */}
            <MenuItem value={"Company"}>Company</MenuItem>
            <MenuItem value={"Hospital"}>Hospital</MenuItem>

          {/* ))} */}
        </TextField>
      </FormControl>
          </div>
          <div>
            <h2>Additional Facilities</h2>
          </div>
          <div className="permission-checkbox">
            <div className="checkbox-input">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<img src={uncheck} />}
                    checkedIcon={<img src={check} />}
                  />
                }
                label="Post"
                className="lucam-job-checkbox"
              />
            </div>
            <div className="checkbox-input">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<img src={uncheck} />}
                    checkedIcon={<img src={check} />}
                  />
                }
                label="Approve"
                className="lucam-job-checkbox"
              />
            </div>
            <div className="checkbox-input">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<img src={uncheck} />}
                    checkedIcon={<img src={check} />}
                  />
                }
                label="Job Approve Type"
                className="lucam-job-checkbox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Permission;
