import React, { useState } from "react";
import "./UserProfile.scss";
import dp from "../../asset/popup/dp.svg";
import downloadIcon from "../../asset/profile/download.svg";
import { useEffect } from "react";
import graphqlRequest from "../../requestMethod";
import { CircularProgress } from "@mui/material";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import UploadIcon from "../../asset/uploadIcon.png";
import Avtar from "../../asset/navbar/user.svg";
import EditIcon from "../../asset/profile/Edit.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UserProfile = () => {
  let [isEditable, setIsEditable] = useState(false);
  let [userData, setUserData] = useState({});
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isloading, setIsloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  const navigate = useNavigate();
  function fetchUserInfo() {
    let query = `
  query{
    withAuth(token:"${token}"){
      userDetails{
        id
        profile {
          organization {
            name
            role
            website
          }
				}
        avatar{
          url
        }
        emails{address}
        phoneNumbers{number}
        name{full},
        role{
          name
        },
        
        organizations{
          role{
            name
          }
          organization{
            name,
            website,
            companyType{
              name
            }
          }
        },
        
        
      }
    }
  
  }
  `;
    graphqlRequest(query).then((response) => {
      let data = response.data.withAuth.userDetails;
      // console.log("data>>", data)
      setUserData({
        userId: data?.id,
        avatar: data.avatar,
        organization: data?.organizations[0].organization.name,
        email: data?.emails[0].address,
        phoneNumber: data?.phoneNumbers[0]?.number,
        // role: data?.role?.name,
        role: data?.profile?.organization?.role,
        website: data?.organizations[0]?.organization?.website,
        name: data?.name?.full,
        companyType: data?.organizations[0]?.organization?.companyType?.name,
        // profileRole: data?.profile?.organization?.role
      });
    });
  }
  // console.log("userData", userData);
  function processImage(event) {
    const imageFile = event.target.files[0];
    // console.log("image>>", imageFile)
    setSelectedFile(imageFile);
    setFileName(imageFile.name);
    const imageUrl = URL.createObjectURL(imageFile);
    setFileUrl(imageUrl);
  }

 
  function updateUser() {
    setIsloading(!isloading);
    getPreSignUrl();
  }
  
  const getPreSignUrl = async () => {
   
    let query = `query{
      getSignedUrl(filename: "${fileName}") {
        location
        fileName
        uri
        bucket
        region
        mimeType
        secureUrl
        url
        isSecured
        cdnUrl
      }
    }`;
    
    graphqlRequest(query).then((response) => {
      // console.log("resp>>>",response.data.getSignedUrl.secureUrl)

      if (response.data.getSignedUrl.secureUrl) {
        uploadFile(response.data.getSignedUrl.secureUrl, response.data.getSignedUrl);
        uploadUserPhoto(response.data.getSignedUrl);
        // console.log("details", response.data.getSignedUrl)
      }
    });
  }

  // console.log("selectedFile>", selectedFile)
  function uploadFile(secureUrl) {
    // console.log("secureUrl=", secureUrl)
    fetch(secureUrl, {
      method: "PUT",
      body: selectedFile,
      processData: false,
      contentType: false,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    // console.log("fileupload,inside")
  }

 

  async function uploadUserPhoto(data) {
    let query = `
    mutation {
      mutationWithAuth(token: "${token}") {
        update{
          updateUser
          (
            id: "${userData.userId}",
            avatar: {
              location: ${data.location},
              fileName: "${data.fileName}",
              uri: "${data.uri}",
              bucket: "${data.bucket}",
              region: "${data.region}",
              mimeType: "${data.mimeType}",
              secureUrl: "${data.secureUrl}",
              url: "${data.url}",
              isSecured: true,
              cdnUrl: ""
            }
          )
          {
            id
          }
        }
      }
    }
    `;
    graphqlRequest(query).then((response) => {
      setIsloading(false);
      message.success("Profile photo uploaded successfully.");
      setIsEditable(!isEditable);
    });
  }
  useEffect(() => {
    fetchUserInfo();
  }, []);

  // console.log("userData", userData);
  // console.log("check-->",fileUrl, userData?.avatar?.url,  Avtar );
  // <img className="download-icon" src={downloadIcon} />
  const phonwwithplus = "+" + userData?.phoneNumber
  return (
    <div className="user-profile-container">
      <div className="user-profiile-wrapper">
        <div className="user-profile-top">
          {/* {isEditable && ( */}
          <label
            htmlFor="filePicker"
            style={{
              opacity: isEditable ? "1" : "0",
              cursor: "pointer",
              color: "#2D5FA1",
              borderRadius: "8px",
              height: "34px",
              width: "100px",
              border: "1px solid #DFDFDF",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              padding: "0 5px",
              marginRight: "5px",
            }}
          >
            <img src={UploadIcon} />
            Upload
            <input
              id="filePicker"
              type="file"
              className="file-upload-input"
              accept="image/png"
              onChange={processImage}
              style={{ visibility: "hidden" }}
            />
          </label>
          {/* )} */}
          <div className="up-top-left-container">
            <div style={{ position: "relative" }}>
              <img
                className="pp-dp"
                src={fileUrl || userData?.avatar?.url || Avtar}
                // alt="profile-photo"
              />
              <img
                src={EditIcon}
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  cursor: "pointer",
                }}
                onClick={() => setIsEditable(!isEditable)}
              />
            </div>
          </div>

          <div className="user-name">
            <h3 style={{ marginLeft: "2rem" }}>{userData?.name}</h3>
          </div>
          {/* <div className="up-top-right-container">
            <button
              className="btn-edit"
              onClick={() => setIsEditable(!isEditable)}
            >
              Edit
            </button>
          </div> */}
          <div>{/* <UploadFile/> */}</div>
        </div>
        <div className="user-profile-bottom">
          <div className="user-profile-details">
            {/* <p>Registered Organization</p> */}
            <p>Company Name</p>
            <label>
              <input
                type="text"
                placeholder="Registered Organization"
                value={userData?.organization}
                disabled={true}
                onChange={(e) =>
                  setUserData({ ...userData, organization: e.target.value })
                }
              />
            </label>
          </div>
          <div className="user-profile-details">
            <p>Official email-id</p>
            <label>
              <input
                type="text"
                placeholder="Official email-id"
                value={userData?.email}
                disabled={true}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </label>
          </div>
          <div className="user-profile-details">
            <p>Phone </p>
            <label>
              <input
                type="text"
                placeholder="Phone"
                value={phonwwithplus}
                disabled={true}
                onChange={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
              />
            </label>
          </div>
          <div className="user-profile-details">
            <p>Role</p>
            <label>
              <input
                type="text" 
                placeholder="Your Role"
                value={userData?.role}
                disabled={!isEditable}
                onChange={(e) =>
                  setUserData({ ...userData, role: e.target.value })
                }
              />
            </label>
          </div>
          <div className="user-profile-details">
            <p>Type of Company</p>
            <label>
              <input
                type="text"
                placeholder="Type of Company"
                value={userData?.companyType}
                disabled={true}
              />
            </label>
          </div>
          <div className="user-profile-details">
            <p>Website</p>
            <label>
              <input
                type="text"
                placeholder="Website"
                value={userData?.website}
                disabled={!isEditable}
                onChange={(e) =>
                  setUserData({ ...userData, website: e.target.value })
                }
              />
            </label>
          </div>
        </div>
        <div className="button-container">
          {isEditable && (
            <>
              <button
                className="reset-btn"
                onClick={() => setFileUrl(userData?.avatar?.url || Avtar)}
              >
                Reset
              </button>
              <button
                className="save-btn"
                onClick={() => {
                  updateUser();
                  setTimeout(() => {
                    navigate(0);
                  }, 2000);
                }}
              >
                {isloading ? <CircularProgress /> : "Save"}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
