import React from "react";
import OpenTok from "./testVideo";
import { useLocation } from "react-router-dom";
import Header from "../../../src/pages/Layout/Layout"
const InterviewPage = () => {
  const location = useLocation();
  console.log("InterviewId-->", location)
  return (
    <div style={{ padding: "0px" }}>
      {/* <Header/> */}
      {/* <div>
        <h3>Schedule Interview List</h3>
      </div> */}
      <div style={{ display: "flex", gap: "20px", flexDirection:"column-reverse" }}>
        <OpenTok location={location} />
      </div>
    </div>
  );
};

export default InterviewPage;
