import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../../components/Common/BreadCrumb/ReportBreadCrumb/ReportBreadCrumb";
import JobsHeader from "../../../../../components/Common/JobsHeader/JobsHeader";
import DownloadIcon from "../../../../../asset/jobs/download.svg";
import "./LReportOpenJobs.scss";
import graphqlRequest from "../../../../../requestMethod";
import ReportOpenJobTable from "../../../../../components/Table/Locum/ReportOpenJobTable/ReportOpenJobTable";
import FilterReportJobs from "../../../../../components/Common/FilterReportJobs/FilterReportJobs";
import Pdf from "react-to-pdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import eventHelpers from "../../../../../Helpers/Event";

const OpenJobs = () => {
  let [tableData, setTableData] = useState([]);
  let [csvData, setCsvData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");
  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");
  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  let [downloadTable, setDownloadTable] = useState([]);

  const [loading, setLoading] = useState(false);

  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [totalData, setTotalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  let [filterByProfession, setFilterByProfession] = useState("");
  let [filterByDepartment, setFilterByDepartment] = useState("");
  let [filterByStartDate, setFilterByStartDate] = useState("");
  let [filterByEndDate, setFilterByEndDate] = useState("");

  function check(start, end, current) {
    if (current >= start && current <= end) {
      return true;
    } else {
      return false;
    }
  }

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  function filterDepartment(data) {
    let onlyDep = Array?.from(new Set(data?.map((data) => data?.department)));
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(
      new Set(data?.map((data) => data?.professionalType))
    );
    setProfessionalList(onlyPro);
  }
  let fetchReportOpenJob = () => {
    setLoading(true);
    const query = `
    query {
      withAuth(token: "${token}") {
        listMiniJobs(limit: ${limit}, offset: ${currentPage},search: "${keyword}", filterByProfession: "${filterByProfession}", department: "${filterByDepartment}",  dateRange:{startDate: "${filterByStartDate}", endDate: "${filterByEndDate}"}){
          id
          code
          department
          specialization
          shift
          start
          end
          applications
          filled
          professionalType
        }
      }
  }
    `;

    graphqlRequest(query).then((response) => {
      setTotalData(response?.data?.totalJobsCount);
      setLoading(false);
      let data = response.data.withAuth.listMiniJobs;
      let arr = [
        [
          "Job ID",
          "Specialization",
          "Start Date",
          "End Date",
          "Positions Available",
          "Application Received",
          "Positions Filled",
        ],
      ];
      setTableData(data);
      filterProfessional(data);
      setFilterTable(data);

      filterDepartment(data);
      if (data) {
        data.map((obj) => {
          let rowArray = [];
          rowArray.push(obj?.code);
          rowArray.push(obj?.specialization);

          rowArray.push(
            new Date(Number(obj?.start)).toLocaleString().split(",")[0]
          );
          rowArray.push(
            new Date(Number(obj?.end)).toLocaleString().split(",")[0]
          );
          rowArray.push(obj?.filled.split("/")[1]);
          rowArray.push(obj?.applications.split("/")[0]);
          rowArray.push(obj?.filled.split("/")[0]);

          arr.push(rowArray);
        });

        setCsvData(arr);
      }
    });
  };

  let removeOpenJob = (id) => {
    const query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            revokeMiniJobOffers(job:"${id}")
          }
        }
      }
    `;

    graphqlRequest(query).then((response) => {
      fetchReportOpenJob();
    });
  };

  function filterCanceledJobTable(query) {
    function search(user) {
      return Object.keys(this).every((key) => {
        if (
          (key == "start" || key == "end") &&
          check(this["start"], this["end"], new Date(Number(user["start"])))
        ) {
          return true;
        } else if (key != "end") {
          return user[key] === this[key];
        }
      });
    }
    let filterData = tableData.filter(search, query);

    setFilterTable(filterData);
  }
  function filterGlobalSearch() {
    let data = tableData.filter((data) => {
      if (
        data?.code.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data?.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase()) ||
        data?.professionalType
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }

  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["professionalType"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterCanceledJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

  // useEffect(() => {
  //   departmentValue !== "" && filterCanceledJobTable("department");
  // }, [departmentValue]);

  // useEffect(() => {
  //   professionsValue !== "" && filterCanceledJobTable("profession");
  // }, [professionsValue]);
  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);
  // useEffect(() => {
  //   filterCanceledJobTable("date");
  // }, [filterDate]);

  useEffect(() => {
    fetchReportOpenJob();
    // fetchHospitalBranch();
    // fetchProfessionalList();
  }, [currentPage,limit,keyword,filterByProfession,filterByDepartment,filterByStartDate,filterByEndDate]);
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [11, 10],
  };

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackReOpenJobDwnld = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.OPN_JB_DWNLD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const exportPDF = () => {
    trackReOpenJobDwnld();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Open Job Report";
    const headers = [
      [
        "Job ID",
        "Specialization",
        "Start Date",
        "End Date",
        "Position Available",
        "Application Received",
        "POsition Filled",
      ],
    ];

    const data = downloadTable?.map((elt) => [
      elt?.jobId,
      elt?.specialization,
      elt?.date,
      elt?.endDate,
      elt?.positionsAvailable,
      elt?.applicationReceived,
      elt?.positionFilled,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  return (
    <div className="report-openJob-wrapper">
      <JobsHeader title={"Open Jobs"} />
      <BreadCrumb action={"Locum"} title={"Report"} title2={"Open Jobs"} />

      <Pdf targetRef={ref} filename="Open-Report.pdf" options={options}>
        {({ toPdf }) => (
          <FilterReportJobs
            fetchAllData={fetchReportOpenJob}
            title={"Open Jobs"}
            professional={"Open Jobs"}
            dowload={"Download Pdf"}
            downloadIcon={DownloadIcon}
            csvData={csvData}
            toPdf={toPdf}
            departmentList={departmentList}
            professionalList={professionalList}
            SetGlobalInputSearch={SetGlobalInputSearch}
            setProfessionValue={setProfessionValue}
            setDepartmentValue={setDepartmentValue}
            globalInputSearch={globalInputSearch}
            setFilterTable={setFilterTable}
            filterTable={filterTable}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
            tableData={tableData}
            professionsValue={professionsValue}
            departmentValue={departmentValue}
            exportPDF={exportPDF}
            keyword={keyword}
            setKeyword={setKeyword}
            filterByProfession={filterByProfession}
            setFilterByProfession={setFilterByProfession}
            filterByDepartment={filterByDepartment}
            setFilterByDepartment={setFilterByDepartment}
            filterByStartDate={filterByStartDate}
            setFilterByStartDate={setFilterByStartDate}
            filterByEndDate={filterByEndDate}
            setFilterByEndDate={setFilterByEndDate}
            totalData={totalData}
          />
        )}
      </Pdf>

      <div ref={ref} style={{ width: "100%" }}>
        <ReportOpenJobTable
          tableData={tableData}
          filterTable={filterTable}
          removeOpenJob={removeOpenJob}
          ref={ref}
          loading={loading}
          setDownloadTable={setDownloadTable}
          totalData={totalData}
          currentPage={currentPage}
          limit={limit}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default OpenJobs;
