import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../components/Common/JobsTable/JobsTable";
import "./Rating.scss";
import graphqlRequest from "../../requestMethod";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import BillingGrid from "../../components/Common/BillingGrid/BillingGrid";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Rating } from "@mui/material";
import RatingGrid from "../../components/Common/Rating Grid/RatingGrid";

const Billing = () => {
  let [responseData, setResponseData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);
  let [hospitalBranchValue, setHospitalBranchValue] = useState("");

  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState([]);

  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");

  let [globalInputSearch, SetGlobalInputSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [isPaid, setIsPaid] = useState(false);
  const [isRaised, setIsRaised] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  console.log("Response Data", responseData);

  // let fetchBillingData = () => {
  //   setLoading(true);
  //   const query = `
  //   query{
  //       withAuth(token:"${token}"){
  //      getBills(limit:10, ${
  //        isPaid ? "isPaid:true" : isRaised ? "isPaid:false" : ""
  //      }) {
  //        url,
  //        id,
  //        isPaid,
  //        billNumber,
  //        billingDate,
  //        reimbursement,
  //        amount,
  //        branch{
  //         jobLocation{
  //           branchName
  //         }
  //       },
  //        numberOfDays,
  //        userInvoices{
  //          jobId,
  //          departmentName,
  //          numberOfDays
  //          consequtiveTime{
  //            start{
  //              ts
  //            }
  //          }
  //          nonConsequtiveTime{
  //            start{
  //              ts
  //            }
  //          }
  //        }
  //      }
   
  //      }
  //    } 
  //   `;
  //   graphqlRequest(query).then((response) => {
  //     setLoading(false);
  //     setResponseData(response?.data?.withAuth?.getBills);
  //     //   setFilterTable(response.data.withAuth.listMiniJobs);
  //     //   filterDepartment(response.data.withAuth.listMiniJobs);
  //     //   filterProfessional(response.data.withAuth.listMiniJobs);
  //   });
  // };
  // let removeOpenJob = (id, data) => {
  //   console.log(data);
  //   const query = `
  //     mutation{
  //       mutationWithAuth(token:"${token}"){
  //         update{
  //           revokeMiniJobOffers(job:"${id}")
  //         }
  //       }
  //     }
  //   `;

  //   graphqlRequest(query).then((response) => {
  //     if (response?.data?.mutationWithAuth?.update?.revokeMiniJobOffers) {
  //       message.success("Job Removed Successfully!");
  //     }
  //     fetchBillingData();
  //   });
  // };

  // function filterOpenJobTable(filterType) {
  //   if (filterType === "department") {
  //     let afterFilter = responseData.filter(
  //       (data) => data.department === departmentValue
  //     );
  //     setFilterTable(afterFilter);
  //   } else if (filterType === "profession") {
  //     let afterFilter = responseData.filter(
  //       (data) => data.professionalType === professionsValue
  //     );
  //     setFilterTable(afterFilter);
  //   }
  // }

  // useEffect(() => {
  //   fetchBillingData();
  // }, [isRaised, isPaid]);

  return (
    <div className="open-job-container">
      <div className="open-job-wrapper">
        <JobsHeader title={"Share Rating"} />
        <Box sx={{ width: "100%", typography: "body1", marginTop: "1rem" }}>
          <RatingGrid data={responseData} />
        </Box>
      </div>
    </div>
  );
};

export default Billing;
