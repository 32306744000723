import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../../../redux/userReducer";
import graphqlRequest from "../../../../requestMethod";
import eventHelpers from "../../../../Helpers/Event";
import useWindowDimensions from "../../../../components/Common/WindowDimention/windowDimention";

function PostingJobModal({
  setDiscardPostingJob,
  setPostingJob,
  dispatch,
  setModel,
}) {
  const navigate = useNavigate();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackDiscardJobYes = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DISCRD_JOB_Y
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackDiscardJobNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DISCRD_JOB_N
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
   width > 480 ? 
   <div className="logout-confirmation-container">
   <div className="logout-confirmation-wrapper">
     <div>{/* <img/> */}</div>
     <div>
       <h2>Confirmation</h2>
       <p> Currently You are Posting a Job</p>
       <p>Are you sure you want to discard this Job ?</p>
     </div>
     <div className="lc-bottom">
       <button
         className="button-no"
         onClick={() => {
           setDiscardPostingJob(false);
           trackDiscardJobNo();
         }}
       >
         No 
       </button>
       <button
         className="button-yes"
         id="job-discarded"
         onClick={() => {
           setDiscardPostingJob(false);
           setPostingJob(false);
           navigate("/");
           dispatch(
             setActiveTab({
               index: 1,
             })
           );
           trackDiscardJobYes();
         }}
       >
         Yes
       </button>
     </div>
   </div>
 </div>
 :
 <div className="logout-confirmation-container" style={{ maxWidth: '350px', marginLeft: "-120px", padding: '20px' }}>
 <div className="logout-confirmation-wrapper">
   <div>{/* <img/> */}</div>
   <div>
     <h2>Confirmation</h2>
     <p> Currently You are Posting a Job</p>
     <p>Are you sure you want to discard this Job ?</p>
   </div>
   <div className="lc-bottom">
     <button
       className="button-no"
       onClick={() => {
         setDiscardPostingJob(false);
         trackDiscardJobNo();
       }}
     >
       No 
     </button>
     <button
       className="button-yes"
       id="job-discarded"
       onClick={() => {
         setDiscardPostingJob(false);
         setPostingJob(false);
         navigate("/");
         dispatch(
           setActiveTab({
             index: 1,
           })
         );
         trackDiscardJobYes();
       }}
     >
       Yes
     </button>
   </div>
 </div>
</div>
  );
}

export default PostingJobModal;
