import React, { useState } from "react";
import "../../../pages/ShiftLogs/ShiftLogs.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import EyeIcon from "../../../asset/jobs/EyeIcon.svg";
import CrossIcon from "../../../asset/jobs/close-circle.svg";
import { CircularProgress, Modal } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { color } from "@mui/system";
import JobsHeader from "../JobsHeader/JobsHeader";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

import { setJobModal, setJobDetaialsModal } from "../../../redux/userReducer";
import JobOptionModel from "../../Common/JobModal/JobModal";
import JobDetailsModal from "../JobDetailsModal/JobDetailsModal";
import JobPostDetailsModal from "../../PopUp/JobPostDetailsModal/JobPostDetailsModal";
import RevokeOpenJob from "../../PopUp/RevokeOpenJob/RevokeOpenJob";
import { useNavigate } from "react-router-dom";
// import RevokeWithReason from "../../PopUp/RevokeWithReason/RevokeWithReason";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

const columns = [
  { id: "jobId", label: "Job ID", minWidth: 100 },
  {
    id: "specialization",
    label: "Specialization",
    minWidth: 90,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "startEndDate",
    label: "Start-End Date",
    minWidth: 130,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "startEndTime",
    label: "Start-End Time",
    minWidth: 130,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "positionAvailable",
    label: "Position Available",
    minWidth: 80,
    align: "center",
  },
  {
    id: "applicationsReceived",
    label: "Applications Received",
    minWidth: 80,
    align: "center",
  },
  {
    id: "positionsFilled",
    label: "Positions Filled",
    minWidth: 80,
    align: "center",
  },
  { id: "action", label: "Action", minWidth: 60, align: "center" },
];
// function formatTime(timestamp) {
//   const localTime = new Date(timestamp);
//   let hours = localTime.getHours();
//   const minutes = localTime.getMinutes();
//   const amPm = hours >= 12 ? "PM" : "AM";
//   const timeString = `${
//     hours > 12 ? hours - 12 : hours === 0 ? 12 : hours
//   }${amPm}`;
//   return timeString;
// }
function formatTime(timestamp) {
  const localTime = new Date(timestamp);
  let hours = localTime.getHours();
  let minutes = localTime.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Add leading zero to minutes if needed
  minutes = minutes < 10 ? '0' + minutes : minutes;

  const timeString = `${hours}:${minutes} ${amPm}`;
  return timeString;
}
function formatDate(datestamp) {
  let data = datestamp.split(" ");
  return `${data[2]} ${data[1]}`;
}

function createData(
  jobId,
  specialization,
  startEndDate,
  startEndTime,
  positionAvailable,
  applicationsReceived,
  positionsFilled,
  id
) {
  return {
    jobId,
    specialization,
    startEndDate,
    startEndTime,
    positionAvailable,
    applicationsReceived,
    positionsFilled,
    id,
  };
}

function JobsTable({
  loading,
  filterTable,
  revokeMiniJobOffers,
  keepMiniJobOffers,
  hospitalBranchValue,
  setHospitalBranchValue,
  professionsValue,
  setProfessionValue,
  fetchOpenJob,
  revokeId,
  setRevokeId,
  reasonToRemove,
  setReasonToRemove,
  totalData, 
  currentPage, 
  limit, 
  setCurrentPage, 
  setLimit
}) {
  let newRow = filterTable?.map((data) =>
    createData(
      data?.code,
      data?.specialization,
      `${formatDate(
        new Date(Number(data?.start)).toDateString()
      )} - ${formatDate(new Date(Number(data?.end)).toDateString())}`,
      `${formatTime(new Date(Number(data?.start)))} - ${formatTime(
        new Date(Number(data?.end))
      )} `,
      data.filled.split("/")[1],
      data?.applications.split("/")[0],
      data?.filled.split("/")[0],
      data?.id
    )
  );
   console.log("filterTable", filterTable)
  let { jobModal, detailsJobModal } = useSelector((state) => state.user);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const handleClose = () => dispatch(setJobModal());
  // const handleCloseDetail = () => dispatch(setJobDetaialsModal());
  const handleCloseDetail = () => {
    setOpenModal(false);
    // fetchOpenJob();
    navigate(0);
    // window.location.reload();
  };

  //
  //table checking start...
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setCurrentPage(0);
  };

  let [hospitalName, setHospitalName] = useState("");
  let [professionsName, setProfessionsName] = useState("");
  let [openDate, setOpenDate] = useState(false);
  let [selectedData, setSelectedData] = useState({});
  let [jobPostRemoveId, setJobPostRemoveId] = useState({});
  let [revokeConfirmation, setRevokeConfirmation] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [openModal, setOpenModal] = useState(false);

  let handleView = (data) => {
    setSelectedData(data);
    setOpenModal(!openModal);
    // dispatch(setJobDetaialsModal());
  };

  let handleJobPostDelete = (data) => {
    setJobPostRemoveId(data);
    setRevokeConfirmation(!revokeConfirmation);
    // dispatch(setJobModal());
  };

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackOpenJobAction = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);
      

      //set event data
      // JobID, Accepted , Rejected ,Schedule Interview, Shortlisted,Invite to apply
      window.Moengage.track_event(
        eventHelpers.OPN_JOB_ACTION
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };
   console.log("newRow", newRow)
  return (
    <>
      <div className="openjob-table">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return column.id === "date" || column.id === "endDate" ? (
                      <TableCell
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#2D5FA1",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                        <div
                          className="date-input"
                          style={{
                            display: "flex",
                            flexFlow: "row-reverse",
                            marginTop: "-22px",
                          }}
                        >
                          <CalendarMonthIcon
                            onClick={() => setOpenDate(!openDate)}
                            className="calendar-logo"
                          />
                        </div>

                        {/* {openDate && (
                                <DateRange
                                  editableDateInputs={true}
                                  onChange={(item) => setDate([item.selection])}
                                  moveRangeOnFirstSelection={false}
                                  ranges={date}
                                  className="jobs-date"
                                  style={{position:"absolute", backgroundColor:"#fff  "}}
                                />
                              )} */}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2D5FA1",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "18px",
                            color: "#FFFFFF",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>

              {loading ? (
                <CircularProgress sx={{ position: "absolute", left: "50%" }} />
              ) : (
                <TableBody>
                  {newRow?.length > 0 ? (
                    newRow
                      ?.slice(
                        // page * rowsPerPage,
                        // page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "action" ? (
                                <TableCell key={column.id} align={column.align}>
                                  <img
                                    src={CrossIcon}
                                    onClick={() => handleJobPostDelete(row)}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <img
                                    src={EyeIcon}
                                    alt=""
                                    onClick={() => {
                                      handleView(row);
                                      trackOpenJobAction();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <p style={{ position: "absolute", left: "50%" }}>
                      {" "}
                      No Data Found
                    </p>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
          paginationMode="server"
          pagination
         rowsPerPageOptions={[10, 25, 100]}
         component="div"
         count={totalData}
         rowsPerPage={limit}
         page={currentPage}
         onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}
            // rowsPerPageOptions={[10, 25, 100]}
            // component="div"
            // count={newRow?.length}
            // rowsPerPage={rowsPerPage}
            // page={page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {/* <Modal
        open={jobModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobOptionModel />
      </Modal> */}

      <Modal
        open={revokeConfirmation}
        onClose={() => {
          setRevokeConfirmation(!revokeConfirmation);
          setReasonToRemove({ reason: "", comment: "" });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RevokeOpenJob
          revokeMiniJobOffers={revokeMiniJobOffers}
          revokeId={revokeId}
          setRevokeId={setRevokeId}
          reasonToRemove={reasonToRemove}
          setReasonToRemove={setReasonToRemove}
          keepMiniJobOffers={keepMiniJobOffers}
          jobPostRemoveId={jobPostRemoveId}
          setRevokeConfirmation={setRevokeConfirmation}
        />
        {/* <RevokeWithReason/> */}
      </Modal>

      <Modal
        // open={detailsJobModal}
        open={openModal}
        onClose={handleCloseDetail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobDetailsModal
          handleCloseDetail={handleCloseDetail}
          selectedData={selectedData}
          fetchOpenJob={fetchOpenJob}
        />
      </Modal>
    </>
  );
}

export default JobsTable;
