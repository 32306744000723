import React, { useEffect, useState } from "react";
import OT from "@opentok/client";
import "./style.scss";
import JobService from "./services";
import { useNavigate } from "react-router-dom";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { Modal } from "@mui/material";
import InterviewLeaveModal from "../PopUp/InterviewLeaveModal/InterviewLeaveModal";

// import 'opentok-solutions-css';

function handleError(error) {
  if (error) {
    console.error("hello1", error);
  }
}
// console.log("localStorage", localStorage);
const bakeErrorData = (error) => {
  if (!error) {
    return { message: "NO_ERROR_OBJECT" };
  }

  const { code, name, message } = error;

  console.log("code:-", code);
  console.log("name:-", name);
  console.log("message:-,", message);
  console.log("error:-", error);
};

function OpenTokNew({ location }) {
  console.log("check--->",location?.state, "asas", location?.state?.id)
  const [isOpen, setIsOpen] = useState(false)
  var API_KEY = "47647061";
  var SESSION_ID = location?.state?.sessionId;
  var SESSION_TOKEN = location?.state?.pubToken;
 const [video, setVideo] = React.useState(true);

 const session = OT.initSession(API_KEY, SESSION_ID);
 const navigate = useNavigate();

 const handleModal = ()=> {
  setIsOpen(true);
 }

 window.onpopstate = function() {
  disconnectSession()
  window.location.reload()
  return
 }

  const disconnectSession = () => {
   
    session.disconnect();
    console.log("first");
  };
  const recordSession = () => {
    const interviewId = location?.state?.id;
    message.success("Recording has been Started")
    const response = JobService.recordSession(interviewId);
    console.log("recordSession", response?.data?.mutationWithAuth);
  };
  const stopRecordSession = () => {
    const interviewId = location?.state?.id;
    message.success("Recording has been Stop")
    const response = JobService.stopRecordSession(interviewId);
    console.log("stopSession",response?.data?.mutationWithAuth);
  };

  useEffect(() => {
    const token = SESSION_TOKEN;
    function initializeSession() {
      let subscribers = { a: 1 };
      let subscriber;
      let publisher;
      // Subscribe to a newly created stream
      OT.setLogLevel(OT.WARN);
      OT.log(`my session ID: ${session.sessionId}`);

      session.on({
        streamCreated: ({ stream }) => {
          const { streamId } = stream;
          const subscriberOptions = {
            insertMode: "append",
            // width: "360px",
            // height: "360px",
          };

          subscriber = session.subscribe(
            stream,
            "subscriber",
            subscriberOptions,
            handleError
          );
          // subscriber.subscribeToVideo(true);
          subscribers[streamId] = subscriber;
          subscriber.on({
            videoDisabled(event) {
              console.log("videoDisabled", event);
            },
            audioLevelUpdated(event) {
              // publisherVolume(event);
            },
          });
        },
        streamPropertyChanged: function (event) {
          // logNetworkStats();
        },
      });

      session.on("sessionDisconnected", function sessionDisconnected(event) {
        console.log("You were disconnected from the session.", event.reason);
      });

      // initialize the publisher
      const publisherOptions = {
        insertMode: "append",
        // width: "360px",
        // height: "360px",
      };
      publisher = OT.initPublisher("publisher", publisherOptions, (error) => {
        if (error) {
          const { name, code } = error;

          if (code === "1004") {
            bakeErrorData(error);

            return;
          }
          if (name === "OT_USER_MEDIA_ACCESS_DENIED") {
            bakeErrorData(error);
            return;
          }
        }
      });

      publisher.on({
        accessAllowed(event) {
          // console.log("accessAllowed", event);
        },
        audioLevelUpdated(event) {
          // debounceFn(event);
        },
      });

      session.connect(token, function callback(error) {
        if (error) {
          handleError(error);
        } else {
          session.publish(publisher, handleError);
        }
      });
    }

    if (API_KEY && SESSION_TOKEN && SESSION_ID) {
      initializeSession();
    }
  }, []);

  return (
    <>
      <div id="videos">
        <div id="publisher"></div>
        <div id="subscriber"></div>
      </div>
      <div style={{display:"flex", gap:"20px",position:"absolute",bottom:"2%",left:"35%"}}>
        <button
          style={{
            backgroundColor: "#dc3545",
            fontSize: "16px",
            color: "#FFF",
            fontWeight: 400,
            borderRadius: "25px",
            border: "none",
            padding: "8px",
          }}
          onClick={()=>handleModal()
            }>
          Leave the call
        </button>
        <button
          style={{
            backgroundColor: "#dc3545",
            fontSize: "16px",
            color: "#FFF",
            fontWeight: 400,
            borderRadius: "25px",
            border: "none",
            padding: "8px",
          }}
          onClick={() => recordSession()}
          
        >
          Record Session
        </button>
        <button
          style={{
            backgroundColor: "#dc3545",
            fontSize: "16px",
            color: "#FFF",
            fontWeight: 400,
            borderRadius: "25px",
            border: "none",
            padding: "8px",
          }}
          onClick={() => stopRecordSession()}
        >
          Stop Session
        </button>
        {/* <Checkbox
        label="Publish Video"
        initialChecked={video}
        onChange={setVideo}
      /> */}
      </div>
      <Modal
        // open={detailsJobModal}
        open={isOpen}
        onClose={setIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InterviewLeaveModal handleCloseModal={setIsOpen} disconnectSession={disconnectSession}/>
      </Modal>
    </>
  );
}

export default OpenTokNew;
