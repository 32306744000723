import React from 'react';
import "./howJobizoWorksCard.scss";
const index = ({image,heading,desc,alt}) => {
  return (
    <div className="tech_lisiting_card">
              <div className="tech_lisiting_card_image">
                <img src={image} alt={alt} className="img" />
              </div>
              <div className="tech_listing_first_block_title_wrappper">
                <p className="tech_heading">{heading}</p>
                <p className="tech_desc">{desc}</p>
              </div>
            </div>
  )
}

export default index