import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../../components/Common/BreadCrumb/ReportBreadCrumb/ReportBreadCrumb";
import JobsHeader from "../../../../../components/Common/JobsHeader/JobsHeader";
import DownloadIcon from "../../../../../asset/jobs/download.svg";
import "./LCompletedReportJobs.scss";
import ReportCompletedJobTable from "../../../../../components/Table/Locum/ReportCompletedJobTable/ReportCompletedJobTable";
import graphqlRequest from "../../../../../requestMethod";
import FilterReportJobs from "../../../../../components/Common/FilterReportJobs/FilterReportJobs";
import Pdf from "react-to-pdf";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import jsPDF from "jspdf";
import "jspdf-autotable";
const CompletedJobs = () => {
  let [loading, setLoading] = useState(false);
  let [tableData, setTableData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);
  let [downloadTable, setDownloadTable] = useState([]);
  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");
  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");
  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  let [csvData, setCsvData] = useState([]);
  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [totalData, setTotalData] = useState([]) 
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  let [filterByProfession, setFilterByProfession] = useState("");
  let [filterByDepartment, setFilterByDepartment] = useState("");
  let [filterByStartDate, setFilterByStartDate] = useState("");
  let [filterByEndDate, setFilterByEndDate] = useState("");

  function check(start, end, current) {
    if (current >= start && current <= end) {
      return true;
    } else {
      return false;
    }
  }
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  function filterDepartment(data) {
    let onlyDep = Array?.from(
      new Set(data?.map((data) => data?.job?.department))
    );
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(
      new Set(data?.map((data) => data?.professional?.professionalType?.name))
    );
    setProfessionalList(onlyPro);
  }
  let fetchAcepetedjobreport = () => {
    setLoading(true);
    const query = `
    query {
      withAuth(token: "${token}") {
        listMiniJobApplications(type:FINISHED,limit: ${limit}, offset: ${currentPage},search: "${keyword}", filterByProfession: "${filterByProfession}", department: "${filterByDepartment}",startDate: "${filterByStartDate}", endDate:"${filterByEndDate}"){
        id
        status
        professional {
          id
          emails {
            isPrimary
            address
          }
          phoneNumbers {
            isPrimary
            number
          }
          name {
            full
          }
          address {
            state
            address
            country
            city
            country
            pin
          }
          specialization {
            name
            id
          }
          numExperience {
            id
            name
          }
          workCity {
            id
            name
          }
          qualification {
            id
            name
          }
          professionalType {
            id
            name
          }
          profile {
            dob
            gender
            pan
            aadhar
            social {
              facebook
              linkedin
            }
            covid
            jobHistory {
              hospital
              department
              specialisation
              start
              end
            }
            education {
              course
              specialisation
              name
              year
            }
            references {
              name
              hospital
              designation
              phone
              email
            }
          }
          languageKnownNew
          additionalTrainingNew
          workedOn {
            id
            softwarename
          }
          professionalRegistration {
            number
            registrationType {
              id
              name
            }
          }
          avatar {
            location
            fileName
            uri
            bucket
            region
            mimeType
            secureUrl
            url
            isSecured
            cdnUrl
          }
          correspondenceAddress {
            address
            city
            state
            pin
            country
          }
          departments {
            id
            name
          }
        }
        job {
            id
            code
            department
            specialization
            start
            end
            applications
            filled
          }
        }
      }
  }
    `;
    graphqlRequest(query).then((response) => {
      setTotalData(response?.data?.totalApplicationCount);
      setTableData(response.data.withAuth.listMiniJobApplications);
      filterDepartment(response.data.withAuth.listMiniJobApplications);
      filterProfessional(response.data.withAuth.listMiniJobApplications);
      setFilterTable(response.data.withAuth.listMiniJobApplications);
      setLoading(false);
      let data = response.data.withAuth.listMiniJobApplications;
      let arr = [
        [
          "Job ID",
          "Specialization",
          "Professional Name",
          "Start Date",
          "End Date",
          "Status",
          "Audit Documents",
        ],
      ];
      if (data) {
        data?.map((obj) => {
          let rowArray = [];
          rowArray?.push(obj?.job?.code);
          rowArray.push(obj?.job?.specialization);
          rowArray.push(obj?.professional?.name?.full);
          rowArray.push(
            new Date(Number(obj?.job?.start)).toLocaleString().split(",")[0]
          );
          rowArray?.push(
            new Date(Number(obj?.job?.end)).toLocaleString().split(",")[0]
          );
          rowArray?.push(obj?.status);
          rowArray?.push(obj?.job?.id);
          arr.push(rowArray);
        });
        setCsvData(arr);
      }
    });
  };

  function filterCanceledJobTable(query) {
  
    let { department, professionalType, start, end } = query;  
    function search(elem) {
      let flag = {};
      if (!!department) {
        flag.department = elem?.job?.department === department;
      }
      if (!!professionalType) {
        flag.professional =
          elem?.professional?.professionalType.name === professionalType;
      }
      if (!!start && !!end) {
        flag.time = check(start, end, new Date(Number(elem?.job?.start)));
      }
      return Object.values(flag).every((test) => test == true);
    }
    let filterData = tableData.filter(search);

    setFilterTable(filterData);
  }
  function filterGlobalSearch() {
    let data = tableData.filter((data) => {
      if (
        data.job.code.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data.job.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase()) ||
        data.professional?.name?.full
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }
  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["professionalType"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterCanceledJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

 
  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);
 

  useEffect(() => {
    fetchAcepetedjobreport();
  }, [currentPage,limit,keyword,filterByProfession,filterByDepartment,filterByStartDate,filterByEndDate]);

  const ref = React.createRef();
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [11,10]
};
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});



const exportPDF = () => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = "Completed Job Report";
  const headers = [["Job ID", "Specialization","Professional Name",	"Start Date",	"End Date","Status",]];

  const data = downloadTable?.map(elt=> [elt?.jobId, elt?.specialization,elt?.professionName,elt?.date,elt?.endDate,!elt?.status ? "Complete" : elt.status]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("report.pdf")
}

  return (
    <div className="report-completed-wrapper">
      <JobsHeader title={"Completed Jobs"} />
      <BreadCrumb action={"Locum"} title={"Completed Report Jobs"} title2={"Completed Jobs"} />
      <Pdf targetRef={ref} filename="CompletedReportJob.pdf" options={options}>
        {({ toPdf }) => (
          <FilterReportJobs
            fetchAllData={fetchAcepetedjobreport}
            title={"Completed Jobs"}
            professional={"Completed Jobs"}
            dowload={"Download Pdf"}
            downloadIcon={DownloadIcon}
            toPdf={toPdf}
            departmentList={departmentList}
            professionalList={professionalList}
            SetGlobalInputSearch={SetGlobalInputSearch}
            setProfessionValue={setProfessionValue}
            setDepartmentValue={setDepartmentValue}
            globalInputSearch={globalInputSearch}
            setFilterTable={setFilterTable}
            tableData={tableData}
            setFilterDate={setFilterDate}
            filterDate={filterDate}
            csvData={csvData}
            professionsValue={professionsValue}
            departmentValue={departmentValue}
            exportPDF={exportPDF}
            keyword={keyword}
            setKeyword={setKeyword}
            filterByProfession={filterByProfession}
            setFilterByProfession={setFilterByProfession}
            filterByDepartment={filterByDepartment}
            setFilterByDepartment={setFilterByDepartment}
            filterByStartDate={filterByStartDate}
            setFilterByStartDate={setFilterByStartDate}
            filterByEndDate={filterByEndDate}
            setFilterByEndDate={setFilterByEndDate}
          />
        )}
      </Pdf>
      <div ref={ref} style={{ width: "100%" }}>
        <ReportCompletedJobTable  
        filterTable={filterTable}
         loading={loading}
         setDownloadTable={setDownloadTable}
         totalData={totalData}
        currentPage={currentPage}
        limit={limit}
        setCurrentPage={setCurrentPage}
        setLimit={setLimit}
         />
      </div>
    </div>
  );
};
export default CompletedJobs;
