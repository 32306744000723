import React from 'react'
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
import "./CompletedJobs.scss";

const CompletedJobs = () => {
  return (
    <div className="CompletedJobs-wrapper">
    <JobsHeader title={"Completed Jobs"}/>
    <BreadCrumb action={"Locum"}  title={"Completed Jobs"}/>
    <FilterComponent
      title={"COMPLETEDJOBS"}
      professional={"TYPE OF COMPLETED JOBS"}/>
    <AcceptedTable />
  </div>  )
}

export default CompletedJobs