import "./FullCalendar.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";
import { message } from "../../Common/SnakeAlert/SnakeAlert";
import { useState } from "react";
import { Modal } from "@mui/material";
import InterviewConfirmation from "../../PopUp/InterviewConfirmation/InterviewConfirmation";
import moment from "moment";

export default function FullCalendarComponent({ upcomingEvent }) {
  console.log("upcomingEventupcomingEvent", upcomingEvent);
  const [isOpen, setIsOpen] = useState(false);
  const [isdata, setIsData] = useState();

  const eventInfo = upcomingEvent?.map((item) => {
    return {
      title: item?.candidate?.name?.full,
      date: item?.date,
      id: item.id,
    };
  });
  let navigate = useNavigate();

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>

        <i
         id="Ontime_Schedule_Interview"
          onClick={() => {
            const data = upcomingEvent?.filter((id) =>
              id?.id?.includes(eventInfo.event.id)
            );

            let diffInterviewToCurrent = moment(
              `${data[0]?.date} ${data[0]?.startTime}`,
              "YYYY-MM-DD hh:mm:ss A"
            ).diff(moment());

            diffInterviewToCurrent = Math.ceil(diffInterviewToCurrent / 60000);
            console.log("diffInterviewToCurrent", diffInterviewToCurrent);
            if (diffInterviewToCurrent > 10) {
              setIsOpen(true);
              setIsData(data[0]);
            } else if (diffInterviewToCurrent > -59) {
              navigate("/interviewPage", {
                state: data[0],
              });
            } else {
              message.error("Interview Expired!");
            }
          }}
        >
          {eventInfo.event.title}
        </i>
      </>
    );
  }
  return (
    <>
      <div className="App">
        <div className="calendar-heading-label">
          {/* <h3>Calendar</h3> */}
        </div>

        <FullCalendar
          height="85vh"
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          // dateClick={(e) => handleDateClick(e)}
          events={eventInfo}
          eventContent={renderEventContent}
        />
      </div>

      <Modal
        // open={detailsJobModal}
        open={isOpen}
        onClose={setIsOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InterviewConfirmation handleCloseModal={setIsOpen} isdata={isdata} />
      </Modal>
    </>
  );
}
