import React, { useEffect, useState } from "react";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import "./Billing.scss";
import graphqlRequest from "../../requestMethod";

import BillingGrid from "../../components/Common/BillingGrid/BillingGrid";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const Billing = () => {
  let   [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [isPaid, setIsPaid] = useState(false);
  const [isRaised, setIsRaised] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;


  let fetchBillingData = () => {
    setLoading(true);
    const query = `
    query{
        withAuth(token:"${token}"){
       getBills(limit:10, ${
         isPaid ? "isPaid:true" : isRaised ? "isPaid:false" : ""
       }) {
         url,
         url2,
         id,
         isPaid,
         billNumber,
         billingDate,
         reimbursement,
         amount,
         branch{
          jobLocation{
            branchName
          }
        },
         numberOfDays,
         userInvoices{
           jobId,
           departmentName,
           numberOfDays
           consequtiveTime{
             start{
               ts
             }
           }
           nonConsequtiveTime{
             start{
               ts
             }
           }
         }
       }
   
       }
     } 
    `;
    graphqlRequest(query).then((response) => {
      // console.log("res",response);
      setLoading(false);
      setResponseData(response?.data?.withAuth?.getBills);
      //   setFilterTable(response.data.withAuth.listMiniJobs);
      //   filterDepartment(response.data.withAuth.listMiniJobs);
      //   filterProfessional(response.data.withAuth.listMiniJobs);
    });
  };



  useEffect(() => {
    fetchBillingData();
  }, [isRaised, isPaid]);

  return (
    <div className="open-job-container">
      <div className="open-job-wrapper">
        <JobsHeader title={"Billing"} />
        <Box sx={{ width: "100%", typography: "body1", marginTop: "1rem" }}>
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "#fff",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="All Invoice"
                  value="1"
                  onClick={() => {
                    setIsPaid(false);
                    setIsRaised(false);
                  }}
                />
                <Tab
                  label="Paid Invoice"
                  value="2"
                  onClick={() => {
                    setIsPaid(true);
                    setIsRaised(false);
                  }}
                />
                <Tab
                  label="Raised Invoice"
                  value="3"
                  onClick={() => {
                    setIsPaid(false);
                    setIsRaised(true);
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
              {" "}
              <BillingGrid data={responseData} />{" "}
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
              {" "}
              <BillingGrid data={responseData} />{" "}
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0, margin: 0 }}>
              {" "}
              <BillingGrid data={responseData} />{" "}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default Billing;
