import React from 'react';
import shiftlogs from "../../../asset/shiftlogs/kanban.svg";
import "./JobsHeader.scss";
const JobsHeader = ({icon, title}) => {
 
  return (
    <div className="sl-heading">
          <img src={shiftlogs} alt="shiftlog-logo" />
          <h3>{title === "Recommendation" ? "Recommendations": title}</h3>
        </div>
  )
}

export default JobsHeader;