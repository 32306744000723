import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
// import "./LOpenJobs.scss";
import graphqlRequest from "../../../../requestMethod";
import { message } from "../../../../components/Common/SnakeAlert/SnakeAlert";

const LOpenjobs = () => {
  let [tableData, setTableData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);
  let [hospitalBranchValue, setHospitalBranchValue] = useState("");

  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");

  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");

  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [loading, setLoading] = useState(false);
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  let fetchOpenJob = () => {
    setLoading(true);
    const query = `
      query {
        withAuth(token: "${token}") {
          listMiniJobs{
            id
            code
            department
            specialization
            shift
            start
            end
            applications
            filled
            professionalType
          }
        }
    }
    `;
    graphqlRequest(query).then((response) => {
      setLoading(false);
      setTableData(response.data.withAuth.listMiniJobs);
      setFilterTable(response.data.withAuth.listMiniJobs);
      filterDepartment(response.data.withAuth.listMiniJobs);
      filterProfessional(response.data.withAuth.listMiniJobs);
    });
  };

  let [reasonToRemove, setReasonToRemove] = useState({
    reason: "",
    comment: "",
  });

  const [revokeId, setRevokeId] = useState("");

  let revokeMiniJobOffers = (id) => {
    const query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            revokeMiniJobOffers(job:"${id}", comment: "${reasonToRemove.comment}", rejectReason: "${reasonToRemove.reason}")
          }
          
        }
      }
    `;

    graphqlRequest(query).then((response) => {
      if (response?.data?.mutationWithAuth?.update?.revokeMiniJobOffers) {
        message.success("Revoke Job Offers Successful!");
      }
      fetchOpenJob();
    });
  };
  let keepMiniJobOffers = (id) => {
    const query = `
    mutation{
      mutationWithAuth(token:"${token}"){
        update{
            keepMiniJobOffers(job:"${id}", comment: "${reasonToRemove.comment}", rejectReason: "${reasonToRemove.reason}")
        }
      }
    }
    `;

    graphqlRequest(query).then((response) => {
      if (response?.data?.mutationWithAuth?.update?.keepMiniJobOffers) {
        message.success("Revoke Job Offers Successful!");
      }
      fetchOpenJob();
    });
  };
  let fetchProfessionalList = () => {
    let query = `
    query {
      withAuth(token: "${token}") {
        listProfessionalType {
          id
          name
        }
      }
  }
    `;

    graphqlRequest(query).then((response) => {
      setProfessionalList(response.data.withAuth.listProfessionalType);
    });
  };
  function filterDepartment(data) {
    let onlyDep = Array.from(new Set(data.map((data) => data.department)));
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(
      new Set(data.map((data) => data.professionalType))
    );
    setProfessionalList(onlyPro);
  }
  function check(start, end, current) {
    if (current >= start && current <= end) {
      return true;
    } else {
      return false;
    }
  }

  function filterOpenJobTable(query) {
    function search(user) {
      return Object.keys(this).every((key) => {
        if (
          (key == "start" || key == "end") &&
          check(this["start"], this["end"], new Date(Number(user["start"])))
        ) {
          return true;
        } else if (key != "end") {
          return user[key] === this[key];
        }
      });
    }
    let filterData = tableData.filter(search, query);

    setFilterTable(filterData);
  }
  function filterGlobalSearch() {
    let data = tableData.filter((data) => {
      if (
        data.code.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }
  useEffect(() => {
    fetchOpenJob();
    // fetchProfessionalList();
  }, []);

  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["professionalType"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterOpenJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);

  return (
    <div className="open-job-container">
      <div className="open-job-wrapper">
        <JobsHeader title={"Open Jobs"} />
        <BreadCrumb action={"Permanent"} title={"Open Jobs"} />
        <FilterComponent
          fetchAllData={fetchOpenJob}
          tableData={tableData}
          setFilterTable={setFilterTable}
          departmentList={departmentList}
          professionalList={professionalList}
          title={"OPENJOBS"}
          professional={"TYPE OF OPENJOB"}
          departmentValue={departmentValue}
          setDepartmentValue={setDepartmentValue}
          professionsValue={professionsValue}
          setProfessionValue={setProfessionValue}
          globalInputSearch={globalInputSearch}
          SetGlobalInputSearch={SetGlobalInputSearch}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
        />
        <AcceptedTable
          // fetchOpenJob={fetchOpenJob}
          loading={loading}
          filterTable={filterTable}
          revokeId={revokeId}
          setRevokeId={setRevokeId}
          reasonToRemove={reasonToRemove}
          setReasonToRemove={setReasonToRemove}
          revokeMiniJobOffers={revokeMiniJobOffers}
          keepMiniJobOffers={keepMiniJobOffers}
          hospitalBranchValue={hospitalBranchValue}
          setHospitalBranchValue={setHospitalBranchValue}
          professionsValue={professionsValue}
          setProfessionValue={setProfessionValue}
        />
      </div>
    </div>
  );
};

export default LOpenjobs;
