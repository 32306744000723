import * as React from 'react';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
// import  from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectLabels({title,setReasonToRemove,reasonToRemove}) {

  return (
    <div>
    
      <FormControl sx={{ m: 1}}>
      <InputLabel >
      Reason
               </InputLabel>
        <Select
        sx={{width: "300px"}}
          value={reasonToRemove?.reason}
          onChange={(e)=>setReasonToRemove({...reasonToRemove,reason:e.target.value})}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          label="Reason"
          renderValue={(selected) => {
            if (!selected) return "";
            else return selected;
          }}
        >
          {/* <MenuItem disabled value="">
          Reason
        </MenuItem> */}
          <MenuItem value={"Own Staff Turned Back on Duty"}>Own Staff Turned Back on Duty</MenuItem>
          <MenuItem value={"Staff came for duty is not trained"}>Staff came for duty is not trained</MenuItem>
          <MenuItem value={"Staff came for duty is not up to the expectation"}>Staff came for duty is not up to the expectation</MenuItem>
          <MenuItem value={"Service postponed for which job was posted"}>Service postponed for which job was posted</MenuItem>
          <MenuItem value={"Job posted by mistake"}>Job posted by mistake</MenuItem>

        </Select>
      </FormControl>
    </div>
  );
}
