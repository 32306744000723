import EventEmitter from 'events';
import createAudioFallbackVideoStateMachine from './audioFallbackVideoStateMachine';
import AudioFallbackVideoStates from './audioFallbackVideoStates';
import setEncodersActiveStateDefault from './setEncodersActiveState';
import { LOW, MEDIUM, CRITICAL } from '../peer_connection/congestionLevel/congestionLevels';

const { ACTIVE_VIDEO, ACTIVE_VIDEO_WITH_WARNING, SUSPENDED_VIDEO } = AudioFallbackVideoStates;

const createAudioFallbackCoordinator = ({
  getAllPeerConnections,
  setEncodersActiveState = setEncodersActiveStateDefault,
}) => {
  const ee = new EventEmitter();
  const audioFallbackStateMachine = createAudioFallbackVideoStateMachine();
  audioFallbackStateMachine.on('stateChange', (...args) => ee.emit('stateChange', ...args));

  const calculateState = async () => {
    const pcs = await getAllPeerConnections();
    const publisherVideoState = pcs.reduce((acc, pc) =>
      Math.max(acc, pc.getAudioFallbackState()), ACTIVE_VIDEO);
    audioFallbackStateMachine.setState(publisherVideoState);
  };

  const onPeerConnectionStateChange = ({
    state: audioFallbackState, peerConnection, publishVideo, stream, peerId,
  }) => {
    if (publishVideo) {
      switch (audioFallbackState) {
        case ACTIVE_VIDEO:
          setEncodersActiveState(peerConnection, true);
          stream.setChannelActiveState('video', true, 'auto', LOW, peerId);
          break;
        case ACTIVE_VIDEO_WITH_WARNING:
          stream.setChannelActiveState('video', true, 'auto', MEDIUM, peerId);
          break;
        case SUSPENDED_VIDEO:
          setEncodersActiveState(peerConnection, false);
          stream.setChannelActiveState('video', false, 'auto', CRITICAL, peerId);
          break;
        default:
          break;
      }
    }
    calculateState();
  };

  return Object.assign(
    ee,
    {
      onPeerConnectionStateChange,
      onPeerConnectionDisconnected: () => { calculateState(); },
      reset: () => audioFallbackStateMachine.reset(),
    });
};

export default createAudioFallbackCoordinator;
