import React, { useState } from "react";
// import "../../../pages/ShiftLogs/ShiftLogs.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import EyeIcon from "../../../../asset/jobs/EyeIcon.svg";
import CrossIcon from "../../../../asset/jobs/close-circle.svg";
import { CircularProgress, Modal } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { color } from "@mui/system";
// import JobsHeader from "../JobsHeader/JobsHeader";
// import BreadCrumb from "../BreadCrumb/BreadCrumb";

import { setJobModal,setJobDetaialsModal } from "../../../../redux/userReducer";
import JobOptionModel from "../../../Common/JobModal/JobModal";
// import JobDetailsModal from "../JobDetailsModal/JobDetailsModal";
import JobPostDetailsModal from "../../../PopUp/JobPostDetailsModal/JobPostDetailsModal";
import { useEffect } from "react";
// import JobPostDetailsModal from "../../PopUp/JobPostDetailsModal/JobPostDetailsModal";
const columns = [
  { id: "jobId", label: "Job ID", minWidth: 100 },
  // {
  //   id: "department",
  //   label: "Department",
  //   minWidth: 100,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "specialization",
    label: "Specialization",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "professionName",
    label: "Professional Name",
    minWidth: 150,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Date",
    minWidth: 150,
    align: "center",
    format: (value) => value.toFixed(2),
  },
 
  // { id: "inTime", label: "In-Time", minWidth: 100 },
  // { id: "outTime", label: "Out-Time", minWidth: 100 },
  // { id: "totalCost", label: "Total Cost", minWidth: 100 },
  // { id: "status", label: "Status", minWidth: 100, align: "center" },
  // { id: "auditdocuments", label: "Audit Documents", minWidth: 100, align: "center" },

  // { id: "action", label: "Action", minWidth: 100, align: "center" },
];

function createData(
  jobId,
  // department,
  specialization,
  professionName,
  date,
  id,
) {
  // const density = population / size;
  return {
    jobId,
    // department,
    specialization,
    professionName,
    date,
    id,
  };
}

const rows = [
  createData(
    "JO1234567",
    "Cardiology",
    "Safari Sharma",
    "19/12/2022",
    "13/02/2023",
    "NA",
  )
];

function ReportOngoingJobTable({loading,tableData ,filterTable, removeOpenJob,setDownloadTable, totalData, currentPage,limit, setCurrentPage, setLimit}) {
  let newRow=filterTable?.map((data)=> createData(
    data?.job?.code,
    // data?.job?.department,
    data?.job?.specialization,
    data?.professional?.name?.full,
    (new Date(Number(data?.job?.start)).toLocaleString()).split(",")[0],
    data?.status,
    "Download"
  ),)
  let { jobModal, detailsJobModal } = useSelector((state) => state.user);
  let dispatch = useDispatch();
  const handleClose = () => dispatch(setJobModal());
  const handleCloseDetail = ()=> dispatch(setJobDetaialsModal());
  //table checking start...
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setCurrentPage(0);
  };

  let [hospitalName, setHospitalName] = useState("");
  let [professionsName, setProfessionsName] = useState("");
  let [openDate, setOpenDate] = useState(false);
  let [selectedData,setSelectedData]=useState({})
  let [jobPostRemoveId,setJobPostRemoveId]=useState({})
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let handleView=(data)=>{
    setSelectedData(data)
   dispatch(setJobDetaialsModal())
  }

  let handleJobPostDelete=(data)=>{
    setJobPostRemoveId(data)
    dispatch(setJobModal())
  }
  
  useEffect(() => {
    if(newRow?.length){
      setDownloadTable(newRow)
    }
  }, [newRow?.length])
  return (
    <>
          <div className="openjob-table">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 520 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#2D5FA1",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                color: "#FFFFFF",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  {loading ? (
                <CircularProgress sx={{position:"absolute",left:"50%"}}/>
                ) : (
                <TableBody>
                  {newRow?.length > 0 ? (
                    newRow
                      ?.slice(
                        // page * rowsPerPage,
                        // page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "action" ? (
                                <TableCell key={column.id} align={column.align}>
                                  <img src={CrossIcon} onClick={()=>handleJobPostDelete(row)}/>
                                  <img src={EyeIcon} alt="" onClick={()=>handleView(row)}/>
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })): (
                        <p style={{position:"absolute", left:"50%"}}> No Data Found</p>
  
                      )}
                  </TableBody>
              )}
                </Table>
              </TableContainer>
              <TablePagination
              paginationMode="server"
              pagination
             rowsPerPageOptions={[10, 25, 100]}
             component="div"
             count={totalData}
             rowsPerPage={limit}
             page={currentPage}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
                // rowsPerPageOptions={[10, 25, 100]}
                // component="div"
                // count={newRow?.length}
                // rowsPerPage={rowsPerPage}
                // page={page}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
           <Modal
           open={jobModal}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
         ><JobOptionModel removeOpenJob={removeOpenJob}  jobPostRemoveId={jobPostRemoveId} />
         </Modal>
         <Modal
           open={detailsJobModal}
           onClose={handleCloseDetail}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
         ><JobPostDetailsModal handleCloseDetail={handleCloseDetail} selectedData={selectedData}/>
         </Modal> 
         </>
  );
}

export default ReportOngoingJobTable;

