import React from "react";
import "./PieChart.scss";
import ReactApexChart from "react-apexcharts";

function PieChart({ title1, title2, value1, value2, color1, color2 }) {
  const chartData = {
    series: [parseInt(value1), parseInt(value2)],
    labels: ["A", "B"],
    options: {
      chart: {
        type: "donut",
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        colors: [color1, color2],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
          title: {
            formatter: function (value) {
              if (value == "series-1") {
                return title1;
              } else return title2;
            },
          },
        },
      },
      // plotOptions: {
      //   pie: {
      //     donut: {
      //       size: 90,
      //       labels: {
      //         show: true,
      //         name: {
      //           show: false,
      //         },
      //         value: {
      //           fontSize: 100,
      //           offsetY: -30,
      //         },
      //         total: {
      //           showAlways: true,
      //           show: true,
      //           fontSize: 100,
      //           formatter: (w) => {
      //             const { seriesTotals = {} } = w.globals;
      //             const sum = seriesTotals.reduce((a, b) => a + b, 0);
      //             const avg = sum / seriesTotals.length || 0;
      //             return `${Math.round(avg)}%`;
      //           },
      //         },
      //       },
      //     },
      //     startAngle: 0,
      //     endAngle: 360,
      //     offsetY: 10,
      //   },
      // },
      grid: {
        padding: {
          bottom: 0,
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
      />
    </div>
  );
}

export default PieChart;
