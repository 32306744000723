import React from "react";
import "./MobileViewPopUp.scss";
const MobileViewPopUp = ({ setOpenOtpHandler }) => {
  return (
    <div
      style={{
        position: "relative",
        top: "20%",
        width: "90%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          padding: "20px",
          position: "absolute",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <p>For Better User experience use this website in Desktop and Laptop</p>
        <button
          onClick={() => setOpenOtpHandler(false)}
          className="b-closePoppup"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default MobileViewPopUp;
