import React from "react";
import "./RecommendationUser.scss";
import Avtar from "../../../asset/avtar.png"
import badge from "../../../asset/badge.svg";
import tickMark from "../../../asset/tickMark.svg";
function RecommendationUser({ data, img, selectedRecommendedUser }) {
  // console.log("1-->",data)
  return (
    <div
      className={
        selectedRecommendedUser.id === data.id
          ? "profile-card-container active"
          : "profile-card-container"
      }
    >
      <div className="profile-card-wrapper">
        <div className="user-photo">

          

        { data?.marksPercentage >= 60 && 
                              <div  className="dp-badge-hover" data-message="Jobizo Assessment Qualified"> 
                              <img src={badge} className="dp-badge"/> 
                             </div>
                              }
                          <img
                            src={
                              data?.avatar?.url ||
                              Avtar
                            }
                            className={
                              data?.marksPercentage >= 60
                                ? "badge-border"
                                : ""
                            }
                          />

          {/* <img src={data.avatar.url || Avtar} /> */}
        </div>
        <div className="user-details">
          <div className="ud-one">
            <h4>{data?.name?.full}</h4>
            <img src={tickMark} className="dp-tickmark" />
          </div>
          <div className="ud-two">
            <p>{data?.specialization?.name}</p>
          </div>
        </div>
        <div className="user-experience">
          <p>Exp. {data?.numExperience?.name}</p>
        </div>
      </div>
    </div>
  );
}

export default RecommendationUser;
