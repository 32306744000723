import React, { useEffect, useState } from "react";
import "./InterviewSlotInput.scss";
import close from "../../../asset/popup/close.svg";
import CrossIcon from "../../../asset/jobs/close-circle.svg";
import calendar from "../../../asset/postjob/calendar.svg";
import { addHours, format } from "date-fns";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack } from "@mui/system";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import graphqlRequest from "../../../requestMethod";
import Button from "@mui/material/Button";
import check from "../../../asset/postjob/check.svg";
import uncheck from "../../../asset/postjob/uncheck.svg";
import { message } from "../../Common/SnakeAlert/SnakeAlert";
import moment from "moment";
import AvailableDates from "./GetAvailabilty";
import eventHelpers from "../../../Helpers/Event";
const now = new Date();
now.setMinutes(0);
now.setSeconds(0);
let SlotDuration = [
  { id: 1, lable: "15", value: 15, label: "15 Min" },
  { id: 2, lable: "30", value: 30, label: "30 Min" },
  { id: 3, lable: "45", value: 45, label: "45 Min" },
];
function convertDateFormat(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const convertedDate = `${year}-${month}-${day}`;
  return convertedDate;
}
function InterviewSlotInput({
  handleInterviewSlotClose,
  fetchApplications,
  selectedProfile,
  jobDetails,
  fetchSelectedJobDetails,
}) {
  let [sendingData, setSendingData] = useState([]);
  let [slots, setSlots] = useState([
    {
      label: "slot1",
      startDate: null,
      startTime: null,
      slotDuration: null,
      isSelected: true,
    },
    {
      label: "slot2",
      startDate: null,
      startTime: null,
      slotDuration: null,
      isSelected: false,
    },
    {
      label: "slot3",
      startDate: null,
      startTime: null,
      slotDuration: null,
      isSelected: false,
    },
  ]);
  let [openStartDates1, setOpenStartDates1] = useState(false);
  let [openStartDates2, setOpenStartDates2] = useState(false);
  let [openStartDates3, setOpenStartDates3] = useState(false);
  const [todatDate, setTodayDate] = useState(new Date().toDateString());
  let [sloteError, setSloteError] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  let handleSlotInput = (input, property, label) => {
    // console.log("input", input);
    setSlots(
      slots.map((data) => {
        if (data.label === label) {
          return { ...data, [property]: input };
        } else return data;
      })
    );
  };
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  let addInterviewSlot = () => {
    let uniqueData = new Set();
    slots.forEach((data) => {
      if (data.isSelected) {
        const slot = {
          date: convertDateFormat(data.startDate),
          start: new Date(data.startTime).toLocaleTimeString(undefined, {
            timeZone: "Asia/Kolkata",
          }),
          interviewLength: `${data.slotDuration} Min`,
        };
        uniqueData.add(JSON.stringify(slot));
      }
    });
    let data = Array.from(uniqueData).map(JSON.parse);
    // console.log("sendingData", data);

    let query = `
    mutation createInterviewSlot($interviewSlots:[Slot]) {
        mutationWithAuth(token: "${token}") {
          fabricate {
            createInterviewSlot(
              job: "${jobDetails?.id}",
              application: "${selectedProfile?.id}", 
              candidate: "${selectedProfile?.professional?.id}",  
              suggestedSlot: $interviewSlots
            )
          }
        }
      }
    `;

    graphqlRequest(query, { interviewSlots: data }).then((response) => {
      fetchApplications();
      handleInterviewSlotClose();
      fetchSelectedJobDetails();
      message.success("Interview has been scheduled!");
    });
  };
  let handleInterview = () => {
    addInterviewSlot();
  };

  function validateSlote() {
    let error = {};
    if (slots[0].isSelected) {
      if (!slots[0].startDate) {
        error.onestartDate = "please select slot 1 start date";
      }
      if (!slots[0].startTime) {
        error.onestartTime = "please select slot 1 start time";
      }
      if (!slots[0].slotDuration) {
        error.oneslotDuration = "please select slot 1 interview duration";
      }
    }
    if (slots[1].isSelected) {
      if (!slots[1].startDate) {
        error.twostartDate = "please select slot 2 start date";
      }
      if (!slots[1].startTime) {
        error.twostartTime = "please select slot 2 start time";
      }
      if (!slots[1].slotDuration) {
        error.twoslotDuration = "please select slot 2 interview duration";
      }
      if (
        slots[0].startDate &&
        slots[1].startDate &&
        slots[0].startTime &&
        slots[1].startTime
      ) {
        if (
          new Date(slots[0].startDate).toDateString() ===
          new Date(slots[1].startDate).toDateString()
        ) {
          if (
            new Date(slots[0].startTime).getHours() ===
            new Date(slots[1].startTime).getHours()
          ) {
            message.error(
              "please change the start date for slot 2 (Matching with other slot Start Time)"
            );
            error.twostartTime =
              "please change the start date for slot 2 (Matching with other slot Start Time)";
          }
        }
      }
    }
    if (slots[2].isSelected) {
      if (!slots[2].startDate) {
        error.threestartDate = "please select slot 3 start date";
      }
      if (!slots[2].startTime) {
        error.threestartTime = "please select slot 3 start time";
      }
      if (!slots[2].slotDuration) {
        error.threeslotDuration = "please select slot 3 interview duration";
      }
      if (
        slots[0].startDate &&
        slots[0].startTime &&
        slots[1].startDate &&
        slots[1].startTime &&
        slots[2].startDate &&
        slots[2].startTime
      ) {
        if (
          new Date(slots[2].startDate).toDateString() ===
            new Date(slots[1].startDate).toDateString() ||
          new Date(slots[2].startDate).toDateString() ===
            new Date(slots[0].startDate).toDateString()
        ) {
          if (
            (slots[1].isSelected &&
              new Date(slots[2].startTime).getHours() ===
                new Date(slots[1].startTime).getHours()) ||
            new Date(slots[2].startTime).getHours() ===
              new Date(slots[0].startTime).getHours()
          ) {
            message.error(
              "please change the start date for slot 3 (Matching with other slot Start Time)"
            );
            error.twostartTime =
              "please change the start date for slot 3 (Matching with other slot Start Time)";
          }
        }
      }
    }
    return error;
  }
  function resetDateTimeToZero(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
  function getHour(timeString) {
    const [hour, minute] = timeString.split(":");
    return parseInt(hour, 10);
  }
  // console.log("231-->",selectedProfile?.job?.code )
  const getProfessionalAvailability = async () => {
    let query = `
    query{
      withAuth(token:"${token}"){
        getProfessionalAvailability(userId:"${selectedProfile?.professional?.id}", jobId:"${selectedProfile?.job?.id}"){
          date,
          shift,
          startTime,
          endTime
        }
        }
    }
    `;
    try {
      const data = await graphqlRequest(query);
      console.log("259", data);
      const availabilityData = data.data.withAuth.getProfessionalAvailability;
      // console.log("260",availabilityData)
      return availabilityData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    setSloteError(validateSlote());
    getProfessionalAvailability()
      .then((availabilityData) => {
        setAvailableDates(availabilityData);
      })
      .catch((error) => {
        console.error("Error fetching availability data:", error);
      });
  }, [slots]);

  // console.log("sloteError", sloteError);
  // console.log("jobDetails", jobDetails);
  // console.log("slote", new Date(slots[0].startDate).toDateString());
  // console.log("jobDetails", jobDetails?.startTime);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  console.log("280-->", slots);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackActionInterviewSlot = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);

    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(eventHelpers.ACTION_INTERVIEW, {
        "Interview Date1": slots[0].startDate,
        "Start Time1": slots[0].startTime,
        "Slot Duration1": slots[0].slotDuration,
        "Interview Date2": slots[1].startDate,
        "Start Time2": slots[1].startTime,
        "Slot Duration2": slots[1].slotDuration,
        "Interview Date3": slots[2].startDate,
        "Start Time3": slots[2].startTime,
        "Slot Duration3": slots[2].slotDuration,
        // "Slots1": {
        //   "Interview Date1": slots[0].startDate,
        //   "Start Time1": slots[0].startTime,
        //   "Slot Duration1": slots[0].slotDuration,
        // },
        // "Slots2": {
        //   "Interview Date": slots[1].startDate,
        //   "Start Time": slots[1].startTime,
        //   "Slot Duration": slots[1].slotDuration,
        // },
        // "Slots3": {
        //   "Interview Date": slots[2].startDate,
        //   "Start Time": slots[2].startTime,
        //   "Slot Duration": slots[2].slotDuration,
        // },
        date: Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="interview-slot-container">
      <div className="is-wrapper">
        <div className="heading-container">
          <h3>Schedule an Interview</h3>
          {/* <button onClick={handleInterview}>Add</button> */}
          <img
            src={CrossIcon}
            onClick={handleInterviewSlotClose}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="input-container">
          {/* slot one */}
          <div>
            <h3>
              Slot 1{" "}
              {/* <input
               icon={<img src={uncheck} />}
               checkedIcon={<img src={check} />}
                checked={true}
                type="checkbox"
                onChange={(e) => handleSlotInput(true, "isSelected", "slot1")}
              /> */}
              <Checkbox
                icon={<img src={uncheck} />}
                checkedIcon={<img src={check} />}
                checked={true}
                onChange={(e) => handleSlotInput(true, "isSelected", "slot1")}
              />
            </h3>

            <label className="input-container">
              <input
                className="interview-date-style"
                type="text"
                required
                placeholder="Start Date"
                // value={format(slots[0].startDate, "yyyy-MM-dd")}
                value={
                  slots[0].startDate
                    ? format(slots[0].startDate, "yyyy-MM-dd")
                    : "Interview Date"
                }
              />
              <img
                src={calendar}
                onClick={() => setOpenStartDates1(!openStartDates1)}
                alt="calender-start"
              />
            </label>
            {openStartDates1 && (
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  zIndex: 50,
                }}
              >
                <Calendar
                  minDate={new Date(new Date().setDate(new Date().getDate()))}
                  maxDate={
                    new Date(
                      Number(
                        jobDetails?.timingsType === "CONSEQUTIVE"
                          ? jobDetails?.consequtiveTime?.start?.ts
                          : jobDetails?.nonConsequtiveTime?.start?.ts
                      )
                    )
                  }
                  className="calendar-date-input"
                  onChange={(item) => {
                    handleSlotInput(item, "startDate", "slot1");
                    setOpenStartDates1(false);
                  }}
                  locale={locales["enUS"]}
                  date={slots[0].startDate}
                />
              </div>
            )}
            <label className="input-container">
              {/* <MobileTimePicker label={'"hours"'} views={['hours']} /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} style={{ width: "100%" }}>
                  <TimePicker
                    label="Start Time"
                    ampm={false}
                    minTime={
                      todatDate === new Date(slots[0]?.startDate).toDateString()
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : new Date(slots[0]?.startDate).toDateString() ===
                            new Date(
                              Number(
                                jobDetails?.timingsType === "CONSEQUTIVE"
                                  ? jobDetails?.consequtiveTime?.start?.ts
                                  : jobDetails?.nonConsequtiveTime?.start?.ts
                              )
                            ).toDateString() && !todatDate
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour("00")
                              // getHour("04:00")
                            )
                          )
                    }
                    maxTime={
                      new Date(slots[0].startDate).toDateString() ===
                        new Date(
                          Number(
                            jobDetails?.timingsType === "CONSEQUTIVE"
                              ? jobDetails?.consequtiveTime?.start?.ts
                              : jobDetails?.nonConsequtiveTime?.start?.ts
                          )
                        ).toDateString() &&
                      moment(
                        addHours(
                          resetDateTimeToZero(new Date()),
                          getHour(jobDetails?.startTime) - 1
                          // getHour("02:00")
                        )
                      )
                    }
                    required
                    views={["hours"]}
                    value={slots[0].startTime}
                    onChange={(e) => {
                      handleSlotInput(e, "startTime", "slot1");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </label>
            <Select
              // className="dropdown-hospital-locum-job"
              required
              sx={{
                width: "90%",
                height: "50px !important",
              }}
              value={slots[0].slotDuration}
              onChange={(e) =>
                handleSlotInput(e.target.value, "slotDuration", "slot1")
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <span
                      style={{
                        display: "block",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Slot Duration
                    </span>
                  );
                else return selected;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                {/* hospital */}
              </MenuItem>
              {SlotDuration.map((data) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
            </Select>
          </div>
          {/* slot two */}
          <div>
            <h3>
              Slot 2
              {/* <input
                type="checkbox"
                onChange={(e) => {
                  handleSlotInput(!slots[1].isSelected, "isSelected", "slot2");
                }}
              /> */}
              <Checkbox
                icon={<img src={uncheck} />}
                checkedIcon={<img src={check} />}
                checked={slots[1].isSelected}
                onChange={(e) => {
                  handleSlotInput(!slots[1].isSelected, "isSelected", "slot2");
                }}
              />
            </h3>
            <label className="input-container">
              <input
                type="text"
                placeholder="Start Date"
                className="interview-date-style"
                style={{
                  opacity: slots[1].isSelected ? 1 : 0.5,
                }}
                value={
                  slots[1].startDate
                    ? format(slots[1].startDate, "yyyy-MM-dd")
                    : "Interview Date"
                }
              />
              <img
                src={calendar}
                onClick={() =>
                  slots[1].isSelected && setOpenStartDates2(!openStartDates2)
                }
                alt="calender-start"
              />
            </label>
            {openStartDates2 && (
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  zIndex: 50,
                }}
              >
                <Calendar
                  minDate={new Date(new Date().setDate(new Date().getDate()))}
                  maxDate={
                    new Date(
                      Number(
                        jobDetails?.timingsType === "CONSEQUTIVE"
                          ? jobDetails?.consequtiveTime?.start?.ts
                          : jobDetails?.nonConsequtiveTime?.start?.ts
                      )
                    )
                  }
                  className="calendar-date-input"
                  onChange={(item) => {
                    handleSlotInput(item, "startDate", "slot2");
                    setOpenStartDates2(false);
                  }}
                  locale={locales["enUS"]}
                  date={slots[1].startDate}
                />
              </div>
            )}
            <label className="input-container">
              {/* <MobileTimePicker label={'"hours"'} views={['hours']} /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} style={{ width: "100%" }}>
                  <TimePicker
                    // label="Start Time"
                    ampm={false}
                    minTime={
                      todatDate === new Date(slots[1]?.startDate).toDateString()
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : new Date(slots[1]?.startDate).toDateString() ===
                            new Date(
                              Number(
                                jobDetails?.timingsType === "CONSEQUTIVE"
                                  ? jobDetails?.consequtiveTime?.start?.ts
                                  : jobDetails?.nonConsequtiveTime?.start?.ts
                              )
                            ).toDateString() && !todatDate
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour("00")
                              // getHour("04:00")
                            )
                          )
                    }
                    maxTime={
                      new Date(slots[1].startDate).toDateString() ===
                        new Date(
                          Number(
                            jobDetails?.timingsType === "CONSEQUTIVE"
                              ? jobDetails?.consequtiveTime?.start?.ts
                              : jobDetails?.nonConsequtiveTime?.start?.ts
                          )
                        ).toDateString() &&
                      moment(
                        addHours(
                          resetDateTimeToZero(new Date()),
                          getHour(jobDetails?.startTime) - 1
                          // getHour("02:00")
                        )
                      )
                    }
                    label="Start Time"
                    views={["hours"]}
                    disabled={!slots[1].isSelected}
                    value={slots[1].startTime}
                    onChange={(e) => {
                      handleSlotInput(e, "startTime", "slot2");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </label>
            <Select
              // className="dropdown-hospital-locum-job"
              sx={{
                width: "90%",
                height: "50px !important",
              }}
              value={slots[1].slotDuration}
              disabled={!slots[1].isSelected}
              onChange={(e) =>
                handleSlotInput(e.target.value, "slotDuration", "slot2")
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <span
                      style={{
                        display: "block",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Slot Duration
                    </span>
                  );
                else return selected;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                {/* hospital */}
              </MenuItem>
              {SlotDuration.map((data) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
            </Select>
          </div>
          {/* slot three */}
          <div>
            <h3>
              Slot 3
              {/* <input
                type="checkbox"
                onChange={(e) =>
                  handleSlotInput(!slots[2].isSelected, "isSelected", "slot3")
                }
              /> */}
              <Checkbox
                icon={<img src={uncheck} />}
                checkedIcon={<img src={check} />}
                checked={slots[2].isSelected}
                onChange={(e) =>
                  handleSlotInput(!slots[2].isSelected, "isSelected", "slot3")
                }
              />
            </h3>
            <label className="input-container">
              <input
                type="text"
                placeholder="Start Date"
                style={{
                  opacity: slots[2].isSelected ? 1 : 0.5,
                }}
                className="interview-date-style"
                value={
                  slots[2].startDate
                    ? format(slots[2].startDate, "yyyy-MM-dd")
                    : "Interview Date"
                }
              />
              <img
                src={calendar}
                onClick={() =>
                  slots[1].isSelected && setOpenStartDates3(!openStartDates3)
                }
                alt="calender-start"
              />
            </label>
            {openStartDates3 && (
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  zIndex: 50,
                }}
              >
                <Calendar
                  minDate={new Date(new Date().setDate(new Date().getDate()))}
                  maxDate={
                    new Date(
                      Number(
                        jobDetails?.timingsType === "CONSEQUTIVE"
                          ? jobDetails?.consequtiveTime?.start?.ts
                          : jobDetails?.nonConsequtiveTime?.start?.ts
                      )
                    )
                  }
                  className="calendar-date-input"
                  onChange={(item) => {
                    handleSlotInput(item, "startDate", "slot3");
                    setOpenStartDates3(false);
                  }}
                  locale={locales["enUS"]}
                  date={slots[2].startDate}
                  style={{
                    marginRight: "20px",
                  }}
                />
              </div>
            )}
            <label className="input-container">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} style={{ width: "100%" }}>
                  <TimePicker
                    ampm={false}
                    minTime={
                      todatDate === new Date(slots[2]?.startDate).toDateString()
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : new Date(slots[2]?.startDate).toDateString() ===
                            new Date(
                              Number(
                                jobDetails?.timingsType === "CONSEQUTIVE"
                                  ? jobDetails?.consequtiveTime?.start?.ts
                                  : jobDetails?.nonConsequtiveTime?.start?.ts
                              )
                            ).toDateString() && !todatDate
                        ? moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour(`${new Date().getHours() + 1}:00`)
                              // getHour("04:00")
                            )
                          )
                        : moment(
                            addHours(
                              resetDateTimeToZero(new Date()),
                              getHour("00")
                              // getHour("04:00")
                            )
                          )
                    }
                    maxTime={
                      new Date(slots[2].startDate).toDateString() ===
                        new Date(
                          Number(
                            jobDetails?.timingsType === "CONSEQUTIVE"
                              ? jobDetails?.consequtiveTime?.start?.ts
                              : jobDetails?.nonConsequtiveTime?.start?.ts
                          )
                        ).toDateString() &&
                      moment(
                        addHours(
                          resetDateTimeToZero(new Date()),
                          getHour(jobDetails?.startTime) - 1
                          // getHour("02:00")
                        )
                      )
                    }
                    disabled={!slots[2].isSelected}
                    label="Start Time"
                    views={["hours"]}
                    value={slots[2].startTime}
                    onChange={(e) => {
                      handleSlotInput(e, "startTime", "slot3");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </label>
            <Select
              // className="dropdown-hospital-locum-job"
              disabled={!slots[2].isSelected}
              sx={{
                width: "90%",
                height: "50px !important",
              }}
              value={slots[2].slotDuration}
              onChange={(e) =>
                handleSlotInput(e.target.value, "slotDuration", "slot3")
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <span
                      style={{
                        display: "block",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Slot Duration
                    </span>
                  );
                else return selected;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                {/* hospital */}
              </MenuItem>
              {SlotDuration.map((data) => (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className="slot-button">
          <Button
            variant="contained"
            sx={{ padding: "10px 50px" }}
            onClick={() => {
              handleInterview();
              trackActionInterviewSlot();
            }}
            disabled={Object.keys(sloteError).length !== 0}
          >
            Add
          </Button>
        </div>
        {/* Available Dates */}
        <div className="available-dates-container">
          <AvailableDates availableDates={availableDates} />
        </div>
      </div>
    </div>
  );
}

export default InterviewSlotInput;
