import React from "react";
import { useNavigate } from "react-router-dom";
import "./InterviewLeaveModal.scss"
const InterviewLeaveModal = ({ handleCloseModal, disconnectSession }) => {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <div
        style={{
          width: "500px",
          padding: "20px 10px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          position: "absolute",
          left: "30%",
          top: "30%",
        }}
      >
        <p style={{ textAlign: "center" }}>
        Are you sure you want leave the call ?
        </p>
        <div className="lc-bottom1">
          <button className="button-no1" style={{cursor: "pointer"}} onClick={()=>handleCloseModal(false)}>No</button>
          <button
          className="button-yes1"
            style={{cursor: "pointer" }}
            onClick={() => {
              handleCloseModal(false);
              disconnectSession();
              navigate("/upcoming-events");
              window.location.reload()
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterviewLeaveModal;
