import React from 'react';
import BreadCrumb from "../../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../../components/Common/FilterJobs/FilterJobs";
import DownloadIcon from "../../../../../asset/jobs/download.svg"
import "./ReportCompletedJobs.scss"
import ReportJobsTable from "../../../../../components/Common/ReportJobsTable/ReportJobsTable";

const CompletedJobs = () => {
  return (
    <div className="report-completed-wrapper">
    <JobsHeader title={"Completed Jobs"} />
    <BreadCrumb title={"Completed Jobs"} />
    <FilterComponent
      title={"Completed Jobs"}
      professional={"Completed Jobs"}
      dowload={"Download Pdf"}
      downloadIcon={DownloadIcon}
    />
    <ReportJobsTable/>
  </div>
    )
}

export default CompletedJobs;