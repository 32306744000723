import React, { useEffect, useState } from "react";
import "./Profile.scss";
import user from "../../asset/recommendation/user.webp";
import location from "../../asset/recommendation/location.svg";
import star from "../../asset/recommendation/star.svg";
import Avtar from "../../asset/avtar.png";
import badge from "../../asset/badge.svg";
import { getRandomNumber } from "./RandomNumber";

// const getRandomNumber = () => Math.floor(Math.random() * (20 - 5 + 1)) + 5;
function Profile({ data, hightLighted, recommendedUserAbsolute }) {
  console.log("Profiledata", data);
  // console.log("recommendedUserAbsolute", recommendedUserAbsolute);
  
  const randomNumber = getRandomNumber();
  const isAbsoluteProfile = recommendedUserAbsolute?.some(abs => abs.id === data.id);
  const isSimilarProfile = !isAbsoluteProfile;

  return (
    <div
      className="profile-wrapper"
      style={{
        boxShadow:
          hightLighted === data?.id
            ? "-2px 3px 9px -1px rgba(0, 0, 0, 0.75)"
            : "1px 1px 1px 1px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div className="profile-container-top">
        {data?.marksPercentage >= 60 && (
          <div
            className="dp-badge-hover"
            data-message="Jobizo Assessment Qualified"
          >
            <img src={badge} className="dp-badge" />
          </div>
        )}
        {/* <h5>SimilarMatch</h5> */}
        {/* <div className="flag-tape">Similar Profile</div> */}
        <div className="flag-tape">
          {isAbsoluteProfile && "Recommended"}
          {isAbsoluteProfile && isSimilarProfile && " & "}
          {isSimilarProfile && "Similar Profile"}
        </div>
        <img
          src={data?.avatar?.url || Avtar}
          className={
            data?.marksPercentage >= 60
              ? "badge-border"
              : "profile-container-top-img"
          }
          alt="profile-photo"
        />
        <h3>{data?.name?.full}</h3>
        <div>
          <img src={location} alt="location" />
          <p>{data?.address?.address}</p>
        </div>
      </div>
      <div className="profile-container-bottom">
        <div className="user-exp">
          <h3>{data?.professionalType?.name}</h3>
          <p>{data?.numExperience?.name} of experience</p>
        </div>
        <div className="ratting">
          {data?.numberOfRatings >= 5 ? (
            <>
              {typeof data?.averageRating === "number" &&
              data?.averageRating >= 0 ? (
                new Array(Math.round(data?.averageRating))
                  .fill(1)
                  .map((_, index) => (
                    <React.Fragment key={index}>
                      <img src={star} alt="star" />
                      {index === Math.round(data?.averageRating) - 1 && (
                        <span
                          style={{
                            position: "relative",
                            top: "-3px",
                            left: "5px",
                            fontSize: "0.8em",
                          }}
                        >
                          ({data?.numberOfRatings})
                        </span>
                      )}
                    </React.Fragment>
                  ))
              ) : (
                <span>Invalid average rating</span>
              )}
            </>
          ) : (
            <>
              {typeof data?.rating === "number" &&
              data?.rating >= 0 &&
              Number.isInteger(data?.rating) ? (
                <div style={{ display: "inline-block", position: "relative" }}>
                  {new Array(data?.rating).fill(1).map((_, index) => (
                    <img key={index} src={star} alt="star" />
                  ))}
                  <span
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "calc(100% + 2px)",
                      transform: "translateY(-50%)",
                      fontSize: "0.8em",
                    }}
                  >
                    ({randomNumber + data?.rating})
                  </span>
                </div>
              ) : (
                <span>Invalid rating</span>
              )}
            </>
          )}
        </div>
        {/* <span>{data?.numberOfRatings} people rated</span>  */}
        {/* <span>(1234)</span> */}
      </div>
    </div>
  );
}

export default Profile;

/* {new Array(data.rating).fill(0).map(() => {
            return <img src={star} alt="star" />;
          })} */
