import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
import "./LOpenJobs.scss";
import graphqlRequest from "../../../../requestMethod";
import { message } from "../../../../components/Common/SnakeAlert/SnakeAlert";

const LOpenjobs = () => {
  let [tableData, setTableData] = useState([]);
  let [filterTable, setFilterTable] = useState([]);
  let [hospitalBranchValue, setHospitalBranchValue] = useState("");

  let [departmentList, setDepartmentList] = useState([]);
  let [departmentValue, setDepartmentValue] = useState("");

  let [professionalList, setProfessionalList] = useState([]);
  let [professionsValue, setProfessionValue] = useState("");

  let [hospitalBranchList, setHospitalBranchList] = useState([]);

  let [globalInputSearch, SetGlobalInputSearch] = useState("");
  const [filterDate, setFilterDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [totalData, setTotalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  let [filterByProfession, setFilterByProfession] = useState("");
  let [filterByDepartment, setFilterByDepartment] = useState("");
  let [filterByStartDate, setFilterByStartDate] = useState("");
  let [filterByEndDate, setFilterByEndDate] = useState("");
  let [filterByBranch, setFilterByBranch] = useState("");
  let [hospitalBranchData, setHospitalBranchData] = useState([]);
  const [hospitalBranch, setHospitalBranch] = useState("");
  const [hospitalBranchId, setHospitalBranchId] = useState("");

  const [loading, setLoading] = useState(false);
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  function fetchHospitalDropdownData() {
    const query = `
    query{
      withAuth(token:"${token}"){
        listMiniJobBranches{
          id
          jobLocation {
            hospitalName
            branchName
          }
       }
       }
     }
  `;
    graphqlRequest(query).then((response) => {
      // console.log("response>>>>>", response);
      setHospitalBranchData(response.data.withAuth.listMiniJobBranches);
    });
  }
  // console.log("hospitalBranchData", hospitalBranchData);
  const hospitalBranchOptions = [];
  hospitalBranchData.map((ele) => {
    // console.log("ele", ele);
    let obj = {
      key: ele.id,
      value: ele.jobLocation.branchName,
    };

    hospitalBranchOptions.push(obj);
  });

  // console.log("hospitalBranchOptions", hospitalBranchOptions);

  const handleBranchFilterChange = (e) => {
    const selectedBranch = e.target.value;
    let selectedBranchObjectID;

    hospitalBranchOptions.forEach((branch) => {
      // console.log("branch", branch);
      if (branch.value === selectedBranch) {
        // selectedBranchObjectID = branch.key;
        selectedBranchObjectID = branch.value;
      }
    });

    setHospitalBranch(selectedBranch);
    setHospitalBranchId(selectedBranchObjectID)

    // dispatch(filterRole(selectedRoleObjectID));
  };

  let fetchOpenJob = () => {
    setLoading(true);
    const query = `
      query {
        withAuth(token: "${token}") {
          listMiniJobs(limit: ${limit}, offset: ${currentPage},search: "${keyword}", filterByProfession: "${filterByProfession}", department: "${filterByDepartment}",  dateRange:{startDate: "${filterByStartDate}", endDate: "${filterByEndDate}"}, filterByHospitalBranch: "${hospitalBranchId}"){
            id
            code
            department
            specialization
            shift
            start
            end
            applications
            filled
            professionalType
            hospitalName
          }
        }
    }
    `;
    graphqlRequest(query).then((response) => {
      // console.log("response>>>>>",response);
      setTotalData(response?.data?.totalJobsCount);
      setLoading(false);
      setTableData(response.data.withAuth.listMiniJobs);
      setFilterTable(response.data.withAuth.listMiniJobs);
      filterDepartment(response.data.withAuth.listMiniJobs);
      filterProfessional(response.data.withAuth.listMiniJobs);
      filterHospitalBranch(response.data.withAuth.listMiniJobs);
    });
  };

  let [reasonToRemove, setReasonToRemove] = useState({
    reason: "",
    comment: "",
  });

  const [revokeId, setRevokeId] = useState("");

  let revokeMiniJobOffers = (id) => {
    const query = `
      mutation{
        mutationWithAuth(token:"${token}"){
          update{
            revokeMiniJobOffers(job:"${id}", comment: "${reasonToRemove.comment}", rejectReason: "${reasonToRemove.reason}")
          }
          
        }
      }
    `;

    graphqlRequest(query).then((response) => {
      if (response?.data?.mutationWithAuth?.update?.revokeMiniJobOffers) {
        message.success("Revoke Job Offers Successful!");
      }
      fetchOpenJob();
    });
  };
  let keepMiniJobOffers = (id) => {
    const query = `
    mutation{
      mutationWithAuth(token:"${token}"){
        update{
            keepMiniJobOffers(job:"${id}", comment: "${reasonToRemove.comment}", rejectReason: "${reasonToRemove.reason}")
        }
      }
    }
    `;

    graphqlRequest(query).then((response) => {
      if (response?.data?.mutationWithAuth?.update?.keepMiniJobOffers) {
        message.success("Revoke Job Offers Successful!");
      }
      fetchOpenJob();
    });
  };
  let fetchProfessionalList = () => {
    let query = `
    query {
      withAuth(token: "${token}") {
        listProfessionalType {
          id
          name
        }
      }
  }
    `;

    graphqlRequest(query).then((response) => {
      setProfessionalList(response.data.withAuth.listProfessionalType);
    });
  };
  function filterDepartment(data) {
    let onlyDep = Array.from(new Set(data.map((data) => data.department)));
    setDepartmentList(onlyDep);
  }
  function filterProfessional(data) {
    let onlyPro = Array.from(
      new Set(data.map((data) => data.professionalType))
    );
    setProfessionalList(onlyPro);
  }
  function filterHospitalBranch(data) {
    let onlyPro = Array.from(new Set(data.map((data) => data.hospitalName)));
    setHospitalBranchList(onlyPro);
  }
  function check(start, end, current) {
    if (current >= start && current <= end) {
      return true;
    } else {
      return false;
    }
  }

  function filterOpenJobTable(query) {
    function search(user) {
      return Object.keys(this).every((key) => {
        if (
          (key == "start" || key == "end") &&
          check(this["start"], this["end"], new Date(Number(user["start"])))
        ) {
          return true;
        } else if (key != "end") {
          return user[key] === this[key];
        }
      });
    }
    let filterData = tableData.filter(search, query);

    setFilterTable(filterData);
  }
  function filterGlobalSearch() {
    let data = tableData.filter((data) => {
      if (
        data.code.toUpperCase().includes(globalInputSearch.toUpperCase()) ||
        data.specialization
          .toUpperCase()
          .includes(globalInputSearch.toUpperCase())
      ) {
        return data;
      }
    });
    setFilterTable(data);
  }
  useEffect(() => {
    fetchOpenJob();
    // fetchProfessionalList();
  }, [
    currentPage,
    limit,
    keyword,
    filterByProfession,
    filterByDepartment,
    filterByStartDate,
    filterByEndDate,
    filterByBranch,
    hospitalBranch,
    hospitalBranchId
  ]);

  useEffect(() => {
    fetchHospitalDropdownData();
  }, []);

  useEffect(() => {
    let query = {};
    !!departmentValue && (query["department"] = departmentValue);

    !!professionsValue && (query["professionalType"] = professionsValue);
    // create this like query and passed in funcation as an object
    !!filterDate[0]?.startDate &&
      (query["start"] = new Date(filterDate[0]?.startDate));
    !!filterDate[0]?.endDate &&
      (query["end"] = new Date(filterDate[0]?.endDate));

    query?.end && query?.end?.setHours(23, 59, 59);

    filterOpenJobTable(query); // call that funcation, which filter the table data
  }, [departmentValue, professionsValue, filterDate]);

  useEffect(() => {
    filterGlobalSearch();
  }, [globalInputSearch]);

  return (
    <div className="open-job-container">
      <div className="open-job-wrapper">
        <JobsHeader title={"Open Jobs"} />
        <BreadCrumb action={"Locum"} title={"Open Jobs"} />
        <FilterComponent
          fetchAllData={fetchOpenJob}
          tableData={tableData}
          setFilterTable={setFilterTable}
          departmentList={departmentList}
          professionalList={professionalList}
          hospitalBranchList={hospitalBranchList}
          title={"OPENJOBS"}
          professional={"TYPE OF OPENJOB"}
          departmentValue={departmentValue}
          setDepartmentValue={setDepartmentValue}
          professionsValue={professionsValue}
          setProfessionValue={setProfessionValue}
          globalInputSearch={globalInputSearch}
          SetGlobalInputSearch={SetGlobalInputSearch}
          setFilterDate={setFilterDate}
          filterDate={filterDate}
          keyword={keyword}
          setKeyword={setKeyword}
          filterByProfession={filterByProfession}
          setFilterByProfession={setFilterByProfession}
          filterByDepartment={filterByDepartment}
          setFilterByDepartment={setFilterByDepartment}
          filterByStartDate={filterByStartDate}
          setFilterByStartDate={setFilterByStartDate}
          filterByEndDate={filterByEndDate}
          setFilterByEndDate={setFilterByEndDate}
          filterByBranch={filterByBranch}
          setFilterByBranch={setFilterByBranch}
          hospitalBranch={hospitalBranch}
          handleBranchFilterChange={handleBranchFilterChange}
          hospitalBranchOptions={hospitalBranchOptions}
          setHospitalBranch={setHospitalBranch}
          setHospitalBranchId={setHospitalBranchId}
          limit={limit}
          setLimit={setLimit}
        />
        <AcceptedTable
          // fetchOpenJob={fetchOpenJob}
          loading={loading}
          filterTable={filterTable}
          revokeId={revokeId}
          setRevokeId={setRevokeId}
          reasonToRemove={reasonToRemove}
          setReasonToRemove={setReasonToRemove}
          revokeMiniJobOffers={revokeMiniJobOffers}
          keepMiniJobOffers={keepMiniJobOffers}
          hospitalBranchValue={hospitalBranchValue}
          setHospitalBranchValue={setHospitalBranchValue}
          professionsValue={professionsValue}
          setProfessionValue={setProfessionValue}
          totalData={totalData}
          currentPage={currentPage}
          limit={limit}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default LOpenjobs;
