import React, { useState } from "react";
import "./CalendarRange.scss";
import { DateRange} from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';

function CalendarRange({ setOpenRangeCalendar, filterByStartDate, setFilterByStartDate, filterByEndDate, setFilterByEndDate }) {
  
  const startDate = filterByStartDate ? new Date(filterByStartDate) : null;
  const endDate = filterByEndDate ? new Date(filterByEndDate) : null;

  const handleDateChange = (ranges) => {
    if (ranges.selection) {
      const formattedStartDate = format(ranges.selection.startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(ranges.selection.endDate, 'yyyy-MM-dd');
      setFilterByStartDate(formattedStartDate);
      setFilterByEndDate(formattedEndDate);
    }
  };
  return (
    <div className="range-container">
     <DateRange
      startDate={startDate}
      endDate={endDate}
      ranges={[
        {
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        },
      ]}
      onChange={handleDateChange}
      />
      <button className="btn-ok" onClick={() => setOpenRangeCalendar(false)}>
        OK
      </button>
    </div>
  );
}

export default CalendarRange;
