import React from "react";
import "./JobOptionModel.scss";
import locum from "../../asset/layout/locum.svg";
import parmanent from "../../asset/layout/parmanent.svg";
import { useDispatch } from "react-redux";
import { setModel } from "../../redux/userReducer";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { message } from "../Common/SnakeAlert/SnakeAlert";
import { Modal } from "@mui/material";
import { useState } from "react";
import UpcomingEvent from "../PopUp/UpcomingEvent/UpcomingEvent";
function JobOptionModel(props) {
  let dispatch = useDispatch();
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(false);

  function handleJobPost(jobType) {
    dispatch(setModel());
  }
  const upcomingPermannentJob = () => {
    setIsUpcomingOpen(!isUpcomingOpen);
    // message.success("Permanent Jobs Coming Soon..");
    // dispatch(setModel());
  };

  //Added ID
  console.log("ID ADDED")


  const navigate = useNavigate();
  return (
    <div className="model-card">
      <div className="model-card-wrapper">
        <div className="model-card-top">
          <h3>Post a Job</h3>
          <p>Please choose appropriate type of post</p>
        </div>
        <div className="model-card-bottom">
          <Link
            to="/locum-job"
            className="locum-button"
            id="Pst_L_Jobs"
            onClick={() => {
              handleJobPost("locum");
            }}
          >
            <div className="locum-job">
              <img src={locum} alt="locum" style={{ width: "136px" }} />
              <button className="locum-button">Locum</button>
            </div>
          </Link>
          <Link
            to="/"
            id="Pst_P_Jobs"
            className="permanent-button"
            onClick={() => setIsUpcomingOpen(true)}
          >
            <div className="permanent-job">
              <img src={parmanent} alt="parmanent" style={{ width: "136px" }} />
              <button className="permanent-button">Permanent</button>
            </div>
          </Link>
        </div>
      </div>

      <Modal
        open={isUpcomingOpen}
        onClose={() => setIsUpcomingOpen(!isUpcomingOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpcomingEvent setIsUpcomingOpen={setIsUpcomingOpen} />
      </Modal>
    </div>
  );
}

export default JobOptionModel;
