import React from "react";
import "./Jobpopup.scss";
function Jobpopup({ setOpenModel, PostLocumJob, jobDetails, totalDays }) {
  let handleConfirm = () => {
    setOpenModel(false);
    PostLocumJob();
  };
  return (
    <div className="job-popup-container" style={{ flexDirection: "column" }}>
      <div>
        <h3 className="job-popup-heading1">
          You are going to post a job for {jobDetails?.noOfProfessional}{" "}
          {jobDetails?.noOfProfessional > 1 ? "professionals" : "professional"}{" "}
          for {totalDays} {totalDays > 1 ? "days" : "day"}.
        </h3>
        <h2 className="job-popup-heading2">Do you wish to proceed?</h2>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <button onClick={() => setOpenModel(false)} className="cancelButton">
          No
        </button>
        <button onClick={handleConfirm} className="confirmButton" id="Jobs_Posted">
          Yes
        </button>
      </div>
    </div>
  );
}

export default Jobpopup;
