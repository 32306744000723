import React, { useEffect, useState } from "react";
import "./JobInfo.scss";
import job from "../../../asset/dashboard/job.svg"
import UpcomingEvent from "../../PopUp/UpcomingEvent/UpcomingEvent";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setActiveTab } from "../../../redux/userReducer";
import { useDispatch } from "react-redux";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

function JobInfo({ data }) {
  // console.log("allDataDashboard-->", data)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(false);

  const handleLocum = () => {
    // dispatch(setActiveTab({ index: 3 }));
    navigate("locum/open-jobs");
    dispatch(setActiveTab({ index: 3, locumActive: 1, LocumJobToggle: true }));
  };

  const handleActiveLocum = () => {
    // dispatch(setActiveTab({ index: 3 }));
    dispatch(setActiveTab({ index: 3, locumActive: 3, LocumJobToggle: true }));
    navigate("locum/ongoing-jobs");
  };

  // const handleActivePermanentJob = () => {
  //   // dispatch(setActiveTab({ index: 3 }));
  //   dispatch(setActiveTab({ index: 4, LocumJobToggle: true }));
  //   navigate("permanent/open-jobs");
  // };

 

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackLocumJobPosted = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BRD_LCM
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackActiveLocumJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BRD_ACTV
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };
  return (
    <>
      <div
        className="frameParent"
        // onClick={() => {
        //   handleLocum();
        //   trackLocumJobPosted();
        // }}
        // style={{ cursor: "pointer" }}
      >
        <div className="job-details" id="Dash_Brd_Locum">
          <div className="jd-top" id="Dash_Brd_Locum">
            <p>{"LOCUM JOBS POSTED"}</p>
          </div>
          <div className="jd-bottom" id="Dash_Brd_Locum">
            <div className="job-label-icon" id="Dash_Brd_Locum">
              <img src={job} alt="jobicon" />
              {/* <p>{data?.locumJobs}</p> */}
              <p>{data?.last12MonthJobs}</p>
            </div>
            {/* <div className="percentage-icon">
            <img src={toparrow} alt="top-arrow" />
            <p>{data.stats}%</p>
          </div> */}
          </div>
        </div>
      </div>
 
      <div
        className="frameParent"
        onClick={() => {
          // handleActiveLocum();
          handleLocum();
          trackActiveLocumJob();
        }}
        style={{ cursor: "pointer" }}
        // id="ongoing-jobs"
        id="open-jobs"
      >
        <div className="job-details">
          <div className="jd-top" id="open-jobs">
            {/* <p>{"ACTIVE LOCUM JOBS"}</p> */}
            <p>{"OPEN JOBS"}</p>
          </div>
          <div className="jd-bottom"   id="Dash_Brd_Actv">
            <div className="job-label-icon"   id="Dash_Brd_Actv">
              <img src={job} alt="jobicon" />
              {/* <p>{data?.activeLocumJobs}</p> */}
              <p>{data?.activeOpenLocumJobs}</p>
            </div>
            {/* <div className="percentage-icon">
            <img src={toparrow} alt="top-arrow" />
            <p>{data.stats}%</p>
          </div> */}
          </div>
        </div>
      </div>

      {/* <div className="frameParent">
        <div className="job-details">
          <div className="jd-top">
            <p>{"PERMANENT JOBS POSTED"}</p>
          </div>
          <div className="jd-bottom">
            <div className="job-label-icon">
              <img src={job} alt="jobicon" />
              <p>{data?.permanentJob}</p>
            </div>
            <div className="percentage-icon">
            <img src={toparrow} alt="top-arrow" />
            <p>{data.stats}%</p>
          </div>
          </div>
        </div>
      </div> */}

      <div className="frameParent"
     onClick={() => setIsUpcomingOpen(true)}
      
      style={{ cursor: "pointer" }}
      id="upcoming-events">
        <div className="job-details">
          <div className="jd-top" id="upcoming-events">
          
            <p>{"PERMANENT JOB COUNT"}</p>
          </div>
          <div className="jd-bottom" id="Dash_Brd_Interv_Sch">
            <div className="job-label-icon" id="Dash_Brd_Interv_Sch">
              <img src={job} alt="jobicon" />
              <p>{data?.permanentJob}</p>
            </div>
           
          </div>
        </div>
      </div>
      

      <div
        className="frameParent"
        onClick={() => {
          handleActiveLocum();
        }}
        style={{ cursor: "pointer" }}
        id="ongoing-jobs"
      >
        <div className="job-details">
          <div className="jd-top" id="ongoing-jobs">
            {/* <p>{"CURRENT JOBS"}</p> */}
            <p>{"ONGOING JOBS"}</p>
          </div>
          <div className="jd-bottom">
            <div className="job-label-icon">
              <img src={job} alt="jobicon" />
              {/* <p>{data?.activeLocumJobs}</p> */}
              <p>{data?.locumOngoingJobs}</p>
            </div>
            {/* <div className="percentage-icon">
            <img src={toparrow} alt="top-arrow" />
            <p>{data.stats}%</p>
          </div> */}
          </div>
        </div>
      </div>
      <Modal
        open={isUpcomingOpen}
        onClose={() => setIsUpcomingOpen(!isUpcomingOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpcomingEvent setIsUpcomingOpen={setIsUpcomingOpen} />
      </Modal>
    </>
  );
}

export default JobInfo;

{
  /* <div className="frameParent" id="upcoming-events">
        <div className="job-details">
          <div className="jd-top" id="upcoming-events">
            {/* <p>{"YESTERDAY POST JOB "}</p> */
}
//       <p>{"PREVIOUS DAY JOBS"}</p>
//     </div>
//     <div className="jd-bottom">
//       <div className="job-label-icon">
//         <img src={job} alt="jobicon" />
//         <p>{data?.yesterdayPostedJobs}</p>
//       </div>
//       {/* <div className="percentage-icon">
//       <img src={toparrow} alt="top-arrow" />
//       <p>{data.stats}%</p>
//     </div> */}
//     </div>
//   </div>
// </div> */}
