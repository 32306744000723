import React from "react";
import BreadCrumb from "../../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../../components/Common/FilterJobs/FilterJobs";
import DownloadIcon from "../../../../../asset/jobs/download.svg"
import "./ReportOpenJobs.scss"
import ReportJobsTable from "../../../../../components/Common/ReportJobsTable/ReportJobsTable";
const OpenJobs = () => {
  return (
    <div className="report-openJob-wrapper">
      <JobsHeader title={"Open Jobs"} />
      <BreadCrumb title={"Open Jobs"} />
      <FilterComponent
        title={"Open Jobs"}
        professional={"Open Jobs"}
        dowload={"Download Pdf"}
        downloadIcon={DownloadIcon}
      />
      <ReportJobsTable/>
    </div>
  );
};

export default OpenJobs;
