import React from 'react'
import "./BreadCrumb.scss";
import Arrow from "../../../asset/jobs/Vector.svg"
const BreadCrumb = ({action,title}) => {
  return (
    <div className="openjob-path">
    <p className="openjob-type">{action}</p>
    <img src={Arrow} alt="left arrow" />
    <p className="openjob-title">{title}</p>
  </div>
  )
}

export default BreadCrumb