import React from "react";
import "./InterviewDetails.scss";
import line from "../../asset/calendar/line.svg";

function InterviewDetails({ data }) {
  return (
    <>
      <div className="interview-details">
        <div className="id-one">
          <img src={line} alt="line" />
          <h3>
            {data?.candidate?.name?.full}
            {/* {data?.job?.department?.departmentType?.name} */}
          </h3>
        </div>
        <div className="id-two">
          <div className="id-two-left">
            <p>Date: {data.date}</p>
            <p>Time: {data.startTime}</p>
          </div>
          <div className="id-two-right">
            <p>Event days: </p>
            <div className="event-day">
              {/* {data?.suggestedSlot.map((data) => (
                <label>
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "short",
                  }).format(new Date(data.date))}
                </label>
              ))} */}
              <label>
                {new Intl.DateTimeFormat("en-US", {
                  weekday: "short",
                }).format(new Date(data.date))}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterviewDetails;
