// Permanent Jobs Components State
const state = {
  loading: false,
  success: false,
  error: "",
  //Open Jobs
  permanentOpenJobs: {
    openJobsLoading: true,
    openJobsError: "",
    openJobsInfo: [],
  },
  //Accepted Jobs
  permanentAcceptedJobs: {
    acceptedJobsLoading: true,
    acceptedJobsError: "",
    acceptedJobsInfo: [],
  },
  //Cancelled Jobs
  permanentCancelledJobs: {
    cancelledJobsLoading: true,
    cancelledJobsError: "",
    cancelledJobsInfo: [],
  },
  //Ongoing Jobs
  permanentOngoingJobs: {
    ongoingJobsLoading: true,
    ongoingJobsError: "",
    ongoingJobsInfo: [],
  },
  //Completed Jobs
  permanentCompletedJobs: {
    completedJobsLoading: true,
    completedJobsError: "",
    completedJobsInfo: [],
  },
};

export default state;
