import { TextareaAutosize, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setJobDetaialsModal } from "../../../redux/userReducer";
// import CommonInput from "../commonInput/commonInput";
import "./JobCancelledPostDetails.scss";
import CrossIcon from "../../../asset/jobs/close-circle.svg";

const JobCancelledPostDetails = ({selectedData}) => {
  let dispatch = useDispatch();
  function handleJobPost(jobType) {
    dispatch(setJobDetaialsModal());
  }
  return (
  <>
    <div className="JobPostDetailsModal-container">
     <div style={{display:"flex",justifyContent:"space-between"}}> <p>Job Details</p>
      <img src={CrossIcon} onClick={() => handleJobPost()} style={{cursor:"pointer"}}/></div>
      <div className="jobsDetails-inputWrapper">
        <TextField
          // id="outlined-required"
          disabled
          label="Hospital"
          defaultValue={selectedData?.allData?.job?.hospitalName}
          sx={{ height: "40px", width: "328px" }}
        />
          <TextField
          disabled
            // id="outlined-required"
            label="Type of Professional"
            defaultValue={selectedData?.allData?.professional?.professionalType?.name}
            sx={{ height: "40px", width: "328px" }}
          />
        </div>
    
      <div className="jobsDetails-inputWrapper">
        <TextField
        disabled
          // id="outlined-required"
          label="Department"
          defaultValue={selectedData?.allData?.job?.department}
          sx={{ height: "40px", width: "328px" }}
        />
        <TextField
        disabled
          // id="outlined-required"
          label="Specialization*"
          defaultValue={selectedData?.specialization}
          sx={{ height: "40px", width: "328px" }}
        />
       
      </div>
      <div className="jobsDetails-inputWrapper">
      <TextField
      disabled
          // id="outlined-required"
          label="Qualification"
          defaultValue={selectedData?.allData?.professional?.qualification?.name}
          sx={{ height: "40px", width: "328px" }}
        />
        <TextField
        disabled
          // id="outlined-required"
          label="Experience"
          defaultValue={selectedData?.allData?.professional?.numExperience?.name}
          sx={{ height: "40px", width: "328px" }}
        />
       {" "}
      </div>
      <div className="jobsDetails-inputWrapper">
      {/* <TextField
          // id="outlined-required"
          label="Shift"
          defaultValue={"Shift"}
          sx={{ height: "40px", width: "328px" }}
        /> */}
        <TextField
          // id="outlined-required"
          disabled
          label="No. of Jobs"
          defaultValue={selectedData?.allData?.job?.applications.split("/")[1]}
          sx={{ height: "40px", width: "328px" }}
        />
      </div>
      <div className="detailsModal-desc">
        <p style={{color:"#454545"}}>Job description</p>
        <textarea
        disabled
          aria-label="minimum height"
          value={selectedData?.allData?.job?.jobDescription}
          rows="8"
          cols="80"
          placeholder="Type Here"
          style={{
            // width: "100%",
            border: "none",
            outline: "none",
            background: "#F7F7F7",
            borderRadius: "8px",
            padding: "10PX",
            resize: "none",
            overFlowX:"auto"
          }}
        ></textarea>
      </div>
      {/* <div className="detailsModal-buttonsDiv">
        <button
          className="detailsModal-buttonsNo"
          onClick={() => handleJobPost()}
        >
          No
        </button>
        <button
          className="detailsModal-buttonsYes"
          onClick={() => handleJobPost()}
        >
          Yes
        </button>
      </div> */}
    </div>
  </>
  );
};

export default JobCancelledPostDetails;
