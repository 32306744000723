import React from "react";
import BreadCrumb from "../../../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../../../components/Common/JobsHeader/JobsHeader";
import FilterComponent from "../../../../components/Common/FilterJobs/FilterJobs";
import AcceptedTable from "../../../../components/Common/JobsTable/JobsTable";
import "./OngoingJobs.scss";
const OngoingJobs = () => {
  return (
    <div className="ongoingJob-wrapper">
      <JobsHeader title={"Ongoing Jobs"} />
      <BreadCrumb action={"Locum"}  title={"Ongoing Jobs"} />
      <FilterComponent
        title={"ONGOINGJOBS"}
        professional={"TYPE OF ONGOING JOBS"}
      />
      <AcceptedTable />
    </div>
  );
};

export default OngoingJobs;
