import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import "./EmailOtp.scss";
import Button from "@mui/material/Button";
import graphqlRequest from "../../../../requestMethod";
import { message } from "../../../Common/SnakeAlert/SnakeAlert";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

let initialValue = 60;

const EmailOtp = ({
  setOpenOtpHandler,
  openOtpHandler,
  verifyMode,
  emailToken,
  setIsEmailVerifyed,
  setModalForEmailOtp,
  isEmailVerifyed,
  emailLabel,
  verifyEmail,
  userRegistration

}) => {
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otpExpire, setOtpExpire] = useState(initialValue);
  const [otpField, setOtpField] = useState(new Array(4).fill(""));

  const closeOtpHandleModal = () => {
    setOpenOtpHandler(false);
  };

  //user input otp will be store in a state otpField
  const handleOtp = (e, index) => {
    if (isNaN(e.target.value)) return false;
    setOtpField([
      ...otpField.map((d, idx) => (idx === index ? e.target.value : d)),
    ]);
    if (e.target.nextSibling && e.target.value !== "") {
      e.target.nextSibling.select();
    }
  };

  // In order to move the cursor backward in the OTP field
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      if (e.target.previousSibling) {
        e.target.previousSibling.select();
      }
    }
  };

  const VerifyEmailOtp = () => {
 
    const query = `
    mutation {
    verify(verifyToken:"${emailToken}",otp:"${otpField.join("")}")
    }
    `;

    graphqlRequest(query).then((response) => {
    
     
      if (response?.errors) {
        setIsEmailVerifyed(false);
        message.error("Invalid Otp!");
      } else {
        setIsEmailVerifyed(true);
       
        setModalForEmailOtp(false);
        window.dataLayer.push({event:"sign_up : email_verified"})
        message.success("OTP Verified successfullY!");
        window.dataLayer.push({event:"sign_up : email_verified"})
        console.log("DATA LAYER",window.dataLayer)
       
      }
    });
  };
  function callResendOtp() {
    setOtpExpire(initialValue);
    setOtpField(new Array(4).fill(""));
    setInvalidOtp(false);
    verifyEmail();
  }
  useEffect(() => {
    let timer;
    if (true) {
      timer =
        otpExpire > 0 && setInterval(() => setOtpExpire(otpExpire - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [isEmailVerifyed, otpExpire]);
  console.log("otpFieldotpField", otpField);


  const trackUserEmailVerified = async () => {
    // console.log("111111>>>>", userRegistration)
    // console.log("11111111111");
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    // console.log(">>>>>>>>>>",uniqueId);
    
    if (window.Moengage) {
      // console.log("2222222222222")
      // window.Moengage.setDebugLevel(2)
      // console.log("1111111>>>>",uniqueId);
      //set app id
      // set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_attribute("userName", userRegistration.name);
      // console.log("1111111>>>>",userRegistration);
      window.Moengage.add_user_name(userRegistration.name);
      window.Moengage.add_email(userRegistration.email);
      // window.Moengage.add_mobile(userRegistration.phone);

      //set event data
      window.Moengage.track_event("Email_Signup_verified", {
        // "userId": uniqueId,
        // "userName": userRegistration.name,
        // "userEmail": userRegistration.email,
        // "date": Date.now(),
      });
      // window.Moengage.destroy_session();
    }
  };

  return (
    <>
      <Dialog
        open={openOtpHandler}
        onClose={closeOtpHandleModal}
        className="webOTP"
      >
        <div className="w-otp-container">
          <div className="w-otp-top">
            <div className="w-otp-top-closeicon">
              {/* <HighlightOffRoundedIcon
                onClick={closeOtpHandleModal}
                sx={{ position: "relative", right: "1rem", cursor: "pointer" }}
              /> */}
              {/* <CloseOutlinedIcon/ className="w-otpcloseIcon"> */}
            </div>
            <div className="w-otp-top-otplabel">
              <h3 className="w-otp -top-heading">Verify {verifyMode}</h3>
              <span className="w-otp-top-info">
                {` Please enter the 4 digit code received on your registered Email Id ${emailLabel}.`}
              </span>
            </div>
          </div>
          <div className="w-otp-center">
            <div className="w-otp-input-box-container">
              {otpField?.map((data, index) => {
                return (
                  <input
                    onInput={(e) => handleOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    type="tel"
                    name="otp"
                    // key={index}
                    maxLength={1}
                    // value={data}
                    onFocus={(e) => e.target.select()}
                    className={
                      !invalidOtp ? "w-otp-input-box" : "w-otp-input-box error"
                    }
                  />
                );
              })}
            </div>
            <div
              // style={{
              //   width: "70%",
              //   display: "flex",
              //   justifyContent: "flex-start",
              //   margin: "10px auto",
              // }}
              className="resent-timer"
            >
              {invalidOtp ? (
                <span className="resend" style={{ cursor: "pointer" }}>
                  {" "}
                  Incorrect OTP.
                  <strong
                    style={{
                      fontWeight: "700",
                      fontSize: "30px",
                      cursor: "pointer",
                    }}
                    onClick={callResendOtp}
                    className="resend-otp-link"
                  >
                    Resend OTP
                  </strong>
                </span>
              ) : otpExpire == 0 ? (
                <span className="resend">
                  Didn’t receive the OTP?{" "}
                  <strong
                    style={{ fontWeight: "500", cursor: "pointer" }}
                    onClick={callResendOtp}
                  >
                    Resend OTP
                  </strong>
                </span>
              ) : (
                <p className="resend" style={{ margin: 0 }}>
                  Resend OTP in :{" "}
                  <span>{otpExpire < 10 ? "0" + otpExpire : otpExpire}</span>
                  sec
                </p>
              )}
            </div>
            <div
              // style={{
              //   margin: "2rem 0",
              //   width: "85%",
              //   display: "flex",
              //   justifyContent: "flex-end",
              // }}
              className="bottom-verify-button"
            >
              <Button
                // onClick={VerifyEmailOtp}
                onClick={() =>{
                  VerifyEmailOtp()
                  trackUserEmailVerified()
                 }}
                variant="contained"
                disabled={
                  otpField[0] && otpField[1] && otpField[2] && otpField[3]
                    ? false
                    : true
                }
                // style={{backgroundColor :isEmailVerifyed && "#BBD6B8" }}
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EmailOtp;
