import React, { useState, useEffect } from "react";
import graphqlRequest from "../../requestMethod";
import "./SuggestionBox.css";

function SuggestionBox({ fillForm }) {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let loginData = localStorage.getItem("auth");
        let token = JSON.parse(loginData)?.accessToken;

        if (!token) {
          throw new Error("Token not found");
        }

        const query = `
    query{
        withAuth(token:"${token}"){
        listPostedJobs{
          id
          code
          state
          organization {
            id
            isDeleted
            name
            tld
            sld
            ssl{
              privateKey
              certificate
            }
            
          }
          company {
            id
            name
          }
          department {
            id
            departmentType{
              id
              name
              }    
          }
          branch {
            id
            jobLocation{branchName hospitalName}
            creditLimit
            utilizedLimit
          }
          cancelReason
          isDeleted
          qualification {
            id
            name
        
          }
          numExperience {
            id
            name
            
          }
          specialization {
            id
            name
          }
          candidateType {
            id
            name
          }
          additionalSkill{
            id
            name
          }
          timingsType
          consequtiveTime{
            start{
              year
              month
              date
              hour
              min
              sec
              millis
              tz
              ts
              datetime
            }
            end{
              year
              month
              date
              hour
              min
              sec
              millis
              tz
              ts
              datetime
            }
          }
          nonConsequtiveTime{
            start{
              year
              month
              date
              hour
              min
              sec
              millis
              tz
              ts
              datetime
            }
            end{
              year
              month
              date
              hour
              min
              sec
              millis
              tz
              ts
              datetime
            }
            weekdays
          }
          randomTime {
            year
            month
            date
            hour
            min
            sec
            millis
            tz
            ts
            datetime
          }
          jobDescription
          numberOfJobs
          numberOfDays
          minRate
          maxRate
          cost
          shift
          startTime
          endTime
          shiftLogs {
            sid
            cid
            shift
            isExtended
            approved
            rejectReason
            invoiced
          }
          contactDetail {
            contactPerson
            email
            phone
          }
          isFood
          isAccommodation
          isTransport
          shortListCount
          rejectedCount
          interviewCount
          roleOfferedCount
          recommendedUsers {
            id
          }
          dates
          isInvited
          createdAt
          updatedAt
          createdBy {
            id
          }
          updatedBy {
            id
          }
          isAvailable
          
        }
      }
    }
  `;

        const data = await graphqlRequest(query);
        // console.log("188-->", data);
        const suggestions = data.data.withAuth.listPostedJobs;

        setSuggestions(suggestions);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOptionClick = () => {
    setShowSuggestions((prevShowSuggestions) => !prevShowSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    fillForm(suggestion);
  };

  if (loading) {
    return <p>Loading suggestions...</p>;
  }

  if (error) {
    return <p>Error retrieving suggestions: {error.message}</p>;
  }

  return (
    <div className="suggestion-box">
      <button className="show-hide-button" onClick={handleOptionClick}>
        {showSuggestions ? "Previously Posted Job" : "Previously Posted Job"}
      </button>
      <ul className={`suggestion-list ${showSuggestions ? "show" : "hide"}`}>
        {suggestions.length === 0 ? (
          <li className="no-data-message">
            Hey, We couldn’t find previously posted job in your account. Please
            post new job by filling up the form below
          </li>
        ) : (
          suggestions.map((suggestion) => (
            <li
              key={suggestion?.id}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              {suggestion?.branch?.jobLocation?.hospitalName},
              {suggestion?.department?.departmentType?.name},
              {suggestion?.candidateType?.name}
            </li>
          ))
        )}
        {/* {suggestions.map((suggestion) => (
          <li
            key={suggestion.id}
            onClick={() => handleSuggestionClick(suggestion)}
            className="suggestion-item"
          >
            {suggestion.branch.jobLocation.hospitalName},
            {suggestion.department.departmentType.name},
            {suggestion.candidateType.name}
          </li>
        ))} */}
      </ul>
    </div>
  );
}

export default SuggestionBox;
