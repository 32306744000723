import React, { useEffect, useState } from "react";
import dashboard from "../../asset/sidebar/category.svg";
import downArrow from "../../asset/sidebar/arrowdown.svg";
import whiteArrow from "../../asset/sidebar/whiteArrow.svg";
import calendar from "../../asset/sidebar/calendar.svg";
import profile from "../../asset/sidebar/briefcase.svg";
import heart from "../../asset/sidebar/heart.svg";
// import profile from "../../asset/sidebar/profile.svg";
import calendarCircle from "../../asset/sidebar/calendarcircle.svg";
import user from "../../asset/sidebar/user.svg";
import stickyNote from "../../asset/sidebar/stickynote.svg";
import profileUser from "../../asset/sidebar/userProfile.svg";
import "./Sidebar.scss";
import { Link } from "react-router-dom";
import dropdownStick from "../../asset/jobs/dropdownStick.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setJobPostedReducer,
  setModel,
} from "../../redux/userReducer";
import { motion } from "framer-motion";
import UpcomingEvent from "../PopUp/UpcomingEvent/UpcomingEvent";
import Modal from "@mui/material/Modal/Modal";
import PostingJobModal from "../Dashboard/JobPost/PostingModal";
import graphqlRequest from "../../requestMethod";
import eventHelpers from "../../Helpers/Event";
import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";
import hamburgerIcon from "../../asset/hamburgerIcon.svg";
import hamburgerCross from "../../asset/hamburgerCross.svg";

function Sidebar(props) {
  let {
    activeTab,
    reduxLocumToggle,
    reduxLocumJobActiveTab,
    reduxLocumReportToggle,
    reduxLocumReportActiveTab,
  } = useSelector((state) => state.user);
  let dispatch = useDispatch();

  const [active, setActive] = useState(activeTab);

  const [LocumJobToggle, setLocumJobToggle] = useState(reduxLocumToggle);
  const [locumActive, setLocumActive] = useState(reduxLocumJobActiveTab);
  const [LocumReportToggle, setLocumReportToggle] = useState(false);
  const [locumReportActive, setLocumReportActive] = useState(0);

  const [permanentActive, setPermanentActive] = useState(0);
  const [PermanentJobToggle, setPermanentJobToggle] = useState(false);
  const [PermanentReportToggle, setPermanentReportToggle] = useState(false);

  const [userActive, setUserActive] = useState(0);
  const [userToggle, setUserToggle] = useState(false);

  const [billingToogle, setBillingToggle] = useState(false);
  const [billingActive, setBillingActive] = useState(0);
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(false);

  //JOB POSTED STATUS
  const { postingJob } = useSelector((state) => state.user);

  const [discardPostingJob, setDisacardPostingJob] = useState(false);

  

  // const [screenSize, setScreenSize] = useState(getCurrentDimension());
  // const { height, width } = useWindowDimensions();

  // function getCurrentDimension() {
  //   return {
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   };
  // }

  // useEffect(() => {
  //   const updateDimension = () => {
  //     setScreenSize(getCurrentDimension());
  //   };
  //   window.addEventListener("resize", updateDimension);

  //   return () => {
  //     window.removeEventListener("resize", updateDimension);
  //   };
  // }, [screenSize]);

  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      setIsMenuOpen(false); // Close the menu when resizing
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
console.log("outside")
console.log("isMobile",isMobile)
// console.log("isMenuOpen", isMenuOpen)
  const toggleMenu = () => {
    console.log("inside toggle")
    setIsMenuOpen(!isMenuOpen);
  };

  const setPostingJob = () => {
    dispatch(setJobPostedReducer());
  };

  const handleChange = (index) => {
    dispatch(setActiveTab({ index }));
    setActive(index);
    setLocumJobToggle(false);
    setLocumActive(0);
    setPermanentActive(0);
    setPermanentJobToggle(false);
    setUserToggle(false);
    setUserActive(0);
  };

  const upcomingPermannentJob = () => {
    setIsUpcomingOpen(!isUpcomingOpen);
    // message.success("Permanent Jobs Coming Soon..");
    // dispatch(setModel());
  };

  //when clicking on dropdown (locum permanent job) it set the tab and toggle condition
  const handleDropdown = (index) => {
    setActive(index);
    dispatch(setActiveTab({ index, LocumJobToggle: !LocumJobToggle }));
    setLocumJobToggle(!LocumJobToggle);
    setLocumReportToggle(false);
    setUserToggle(false);
    setUserActive(0);
  };

  //calling redux ruducer when locum job is click on any tab present in locum job dropdown
  const HandleLocumJobActiveTab = (index, locumActive) => {
    setLocumActive(locumActive);
    dispatch(
      setActiveTab({ index, LocumJobToggle: LocumJobToggle, locumActive })
    );
    setLocumReportToggle(false);
    setLocumReportActive(0);
  };

  //calling redux reducer when dropdown tab is active for report tab
  const handleLocumReportToggle = () => {
    setLocumReportToggle(!LocumReportToggle);
    dispatch(
      setActiveTab({
        index: activeTab,
        LocumJobToggle: true,
        locumActive: locumActive,
        LocumReportToggle: !LocumReportToggle,
        locumReportActive: locumReportActive,
      })
    );
  };

  //calling redux reducer when we click on locum report dropdown
  const handleLocumReportActive = (locumreportindex) => {
    setLocumReportActive(locumreportindex);
    setLocumActive(0);

    dispatch(
      setActiveTab({
        index: activeTab,
        LocumJobToggle: true,
        locumActive: 0,
        LocumReportToggle: LocumReportToggle,
        locumReportActive: locumreportindex,
      })
    );
  };

  //when we refresh the page the value which we persit for sidebar tab set automatically
  useEffect(() => {
    setActive(activeTab);
    setLocumJobToggle(reduxLocumToggle);
    setLocumActive(reduxLocumJobActiveTab);
    setLocumReportToggle(reduxLocumReportToggle);
    setLocumReportActive(reduxLocumReportActiveTab);
  }, [
    activeTab,
    reduxLocumToggle,
    reduxLocumJobActiveTab,
    reduxLocumReportToggle,
    reduxLocumReportActiveTab,
  ]);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackOpenJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.OPN_JOB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackAcceptedJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.ACPT_JOB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackOngoingJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.ONG_JOB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackCompletedJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CMPT_JOB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackCancelledJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CAN_JOB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackShiftlogJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.SHFT_LOG
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReportJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RPT_LOG
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackUpcomingJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.UP_EVNTS
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackDashboardJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BRD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackDashboardBilling = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.BILLING
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReOpenJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RE_OPN_JB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReAcceptedJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RE_ACPT_JB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReOngoingJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RE_ONG_JB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReCompletedJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RE_CMPT_JB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackReCancelledJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.RE_CAN_JB
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackPermanentJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.PERMNT_JOBS
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <>
       {isMobile && (
        <div >
          <img
           src={isMenuOpen ? hamburgerCross : hamburgerIcon}
           alt={isMenuOpen ? "Close menu" : "Open menu"}
           onClick={() => {
            console.log("Hamburger icon clicked");
            window.alert("Hamburger icon clicked");
            toggleMenu();
          }}
           style={{
             width: "50px",
             height: "50px",
             cursor: "pointer",
           }}
        />
          {/* {isMenuOpen && (
        <div className="sidebar-wrapper">
        <Link to={postingJob ? "/locum-job" : "/"}>
            <div
              className={active === 1 ? "sidebar-item active" : "sidebar-item"}
              onClick={() => {
                postingJob ? setDisacardPostingJob(true) : handleChange(1);
                trackDashboardJob();
              }}
            >
              <div className="sidebar-left" id="dashboard">
                <img src={dashboard} alt="dashboard" />
                <label>Dashboard</label>
              </div>
            </div>
          </Link>
      </div>
      )} */}
        </div>
      )}
      
       {!isMobile && (
        <div className="sidebar-wrapper">
          <Link to={postingJob ? "/locum-job" : "/"}>
            <div
              className={active === 1 ? "sidebar-item active" : "sidebar-item"}
              onClick={() => {
                postingJob ? setDisacardPostingJob(true) : handleChange(1);
                trackDashboardJob();
              }}
            >
              <div className="sidebar-left" id="dashboard">
                <img src={dashboard} alt="dashboard" />
                <label>Dashboard</label>
              </div>
            </div>
          </Link>
          {/* <Link to={postingJob ? "/locum-job" : "/upcoming-events"}>
            <div
              className={active === 2 ? "sidebar-item active" : "sidebar-item"}
              onClick={() => {
                postingJob ? setDisacardPostingJob(true) : handleChange(2);
                trackUpcomingJob();
              }}
            >
              <div className="sidebar-left" id="upcoming-events">
                <img src={calendar} alt="calendar" />
                <label>Upcoming Events</label>
              </div>
            </div>
          </Link> */}

          {/* Locum jobs tab start */}

          <div
            className={active === 3 ? "sidebar-item active" : "sidebar-item"}
            onClick={() => {
              postingJob ? setDisacardPostingJob(true) : handleDropdown(3);
            }}
          >
            <div
              className="sidebar-left"
              onClick={() => {
                setLocumJobToggle((prev) => !prev);
                //SET USER TOOGGLE FALSE
                setUserToggle((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return prev;
                  }
                });
                //SET PERMANENT JOB TOOGGLE FALSE
                setPermanentJobToggle((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return prev;
                  }
                });
                //SET REPORTS TOOGGLE FALSE
                setPermanentReportToggle((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return prev;
                  }
                });
              }}
            >
              <img src={profile} />
              <label>Locum Jobs</label>
            </div>
            <div>
              <motion.img
                src={LocumJobToggle || active === 3 ? downArrow : whiteArrow}
                className="sidebar-right"
                alt="downarrow"
                animate={{ rotate: LocumJobToggle ? 0 : -90 }}
              />
            </div>
          </div>
          {LocumJobToggle && (
            <motion.div className="sidebar-item-li">
              <Link to="/locum/open-jobs">
                <img src={dropdownStick} className="dropdownStick-one" />
                <li
                  className={locumActive === 1 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 1);
                    trackOpenJob();
                  }}
                >
                  Open Jobs
                </li>
              </Link>
              <Link to="/locum/accepted-jobs">
                <img src={dropdownStick} className="dropdownStick-two" />
                <li
                  className={locumActive === 2 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 2);
                    trackAcceptedJob();
                  }}
                >
                  Accepted Jobs
                </li>
              </Link>
              <Link to="/locum/ongoing-jobs">
                <img src={dropdownStick} className="dropdownStick-three" />
                <li
                  className={locumActive === 3 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 3);
                    trackOngoingJob();
                  }}
                  id="ongoing-jobs"
                >
                  Ongoing Jobs
                </li>
              </Link>
              <Link to="/locum/completed-jobs">
                <img src={dropdownStick} className="dropdownStick-four" />
                <li
                  className={locumActive === 4 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 4);
                    trackCompletedJob();
                  }}
                  id="completed-jobs"
                >
                  Completed Jobs
                </li>
              </Link>
              <Link to="/locum/cancelled-jobs">
                <img src={dropdownStick} className="dropdownStick-five" />
                <li
                  className={locumActive === 5 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 5);
                    trackCancelledJob();
                  }}
                  id="cancelled-jobs"
                >
                  Cancelled Jobs
                </li>
              </Link>
              <Link to="/locum/shift-logs">
                <img src={dropdownStick} className="dropdownStick-six" />
                <li
                  className={locumActive === 6 ? "li-active" : ""}
                  onClick={() => {
                    HandleLocumJobActiveTab(active, 6);
                    trackShiftlogJob();
                  }}
                  id="shift-logs"
                >
                  Shift Logs
                </li>
              </Link>
              <div
                onClick={() => {
                  handleLocumReportToggle();
                  trackReportJob();
                }}
              >
                <img src={dropdownStick} className="dropdownStick-seven" />
                <li
                  className={LocumReportToggle ? "li-active" : ""}
                  id="reports"
                >
                  Reports
                  <motion.img
                    src={LocumReportToggle ? downArrow : whiteArrow}
                    animate={{ rotate: LocumReportToggle ? 0 : -90 }}
                  />
                </li>
              </div>
            </motion.div>
          )}

          {LocumReportToggle && (
            <div className="sidebar-report-item-li">
              <Link to="/locum/open-jobs-report">
                <img src={dropdownStick} className="dropdownStick-report-one" />
                <li
                  className={locumReportActive === 1 ? "li-active" : ""}
                  onClick={() => {
                    handleLocumReportActive(1);
                    trackReOpenJob();
                  }}
                >
                  Open Jobs
                </li>
              </Link>
              <Link to="/locum/accepted-jobs-report">
                <img src={dropdownStick} className="dropdownStick-report-two" />
                <li
                  className={locumReportActive === 2 ? "li-active" : ""}
                  onClick={() => {
                    handleLocumReportActive(2);
                    trackReAcceptedJob();
                  }}
                >
                  Accepted Jobs
                </li>
              </Link>
              <Link to="/locum/ongoing-jobs-report">
                <img
                  src={dropdownStick}
                  className="dropdownStick-report-three"
                />
                <li
                  className={locumReportActive === 3 ? "li-active" : ""}
                  onClick={() => {
                    handleLocumReportActive(3);
                    trackReOngoingJob();
                  }}
                >
                  Ongoing Jobs
                </li>
              </Link>
              <Link to="/locum/completed-jobs-report">
                <img
                  src={dropdownStick}
                  className="dropdownStick-report-four"
                />
                <li
                  className={locumReportActive === 4 ? "li-active" : ""}
                  onClick={() => {
                    handleLocumReportActive(4);
                    trackReCompletedJob();
                  }}
                >
                  Completed Jobs
                </li>
              </Link>
              <Link to="/locum/cancelled-jobs-report">
                <img
                  src={dropdownStick}
                  className="dropdownStick-report-five"
                />
                <li
                  className={locumReportActive === 5 ? "li-active" : ""}
                  onClick={() => {
                    handleLocumReportActive(5);
                    trackReCancelledJob();
                  }}
                >
                  Cancelled Jobs
                </li>
              </Link>
            </div>
          )}

          {/* Locum jobs tab end */}

          {/* Parmanent jobs tab start */}

          <div
            className={active === 4 ? "sidebar-item active" : "sidebar-item"}
            onClick={() => {
              // handleChange(4);
              // setPermanentJobToggle(!PermanentJobToggle);

              postingJob
                ? setDisacardPostingJob(true)
                : upcomingPermannentJob("permanent");
            }}
          >
            <div
              className="sidebar-left"
              onClick={() => {
                // setPermanentJobToggle((prev) => !prev);
                //SET USER TOOGGLE FALSE
                setUserToggle((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return prev;
                  }
                });
                trackPermanentJob();
              }}
            >
              <img src={profile} />
              <label>Permanent Jobs</label>
            </div>
            <div>
              <motion.img
                src={
                  PermanentJobToggle || active === 4 ? downArrow : whiteArrow
                }
                className="sidebar-right"
                alt="downarrow"
                animate={{ rotate: PermanentJobToggle ? 0 : -90 }}
              />
            </div>
          </div>

          {PermanentJobToggle && (
            <div className="sidebar-item-li">
              <Link to="/permanent/open-jobs">
                <img src={dropdownStick} className="dropdownStick-one" />
                <li
                  className={permanentActive === 1 ? "li-active" : ""}
                  onClick={() => setPermanentActive(1)}
                >
                  Open Jobs
                </li>
              </Link>
              <Link to="/permanent/accepted-jobs">
                <img src={dropdownStick} className="dropdownStick-two" />
                <li
                  className={permanentActive === 2 ? "li-active" : ""}
                  onClick={() => setPermanentActive(2)}
                >
                  Accepted Jobs
                </li>
              </Link>
              <Link to="/permanent/ongoing-jobs">
                <img src={dropdownStick} className="dropdownStick-three" />
                <li
                  className={permanentActive === 3 ? "li-active" : ""}
                  onClick={() => setPermanentActive(3)}
                >
                  Ongoing Jobs
                </li>
              </Link>
              <Link to="/permanent/completed-jobs">
                <img src={dropdownStick} className="dropdownStick-four" />
                <li
                  className={permanentActive === 4 ? "li-active" : ""}
                  onClick={() => setPermanentActive(4)}
                >
                  Completed Jobs
                </li>
              </Link>
              <Link to="/permanent/cancelled-jobs">
                <img src={dropdownStick} className="dropdownStick-five" />
                <li
                  className={permanentActive === 5 ? "li-active" : ""}
                  onClick={() => setPermanentActive(5)}
                >
                  Cancelled Jobs
                </li>
              </Link>
              <Link to="/permanent/shift-logs">
                <img src={dropdownStick} className="dropdownStick-six" />
                <li
                  className={permanentActive === 6 ? "li-active" : ""}
                  onClick={() => setPermanentActive(6)}
                >
                  Shift Logs
                </li>
              </Link>
              <div
                onClick={() =>
                  setPermanentReportToggle((prev) => !PermanentReportToggle)
                }
              >
                <img src={dropdownStick} className="dropdownStick-seven" />
                <li>Reports</li>
              </div>
            </div>
          )}
          {PermanentReportToggle && (
            <div className="sidebar-report-item-li">
              <Link to="/permanent/open-jobs-report">
                <img src={dropdownStick} className="dropdownStick-report-one" />
                <li>Open Jobs</li>
              </Link>
              <Link to="/permanent/accepted-jobs-report">
                <img src={dropdownStick} className="dropdownStick-report-two" />
                <li>Completed Jobs</li>
              </Link>
              <Link to="/permanent/cancelled-jobs-report">
                <img
                  src={dropdownStick}
                  className="dropdownStick-report-three"
                />
                <li>Cancelled Jobs</li>
              </Link>
            </div>
          )}

          {/* Parmanent jobs tab end */}

          <div
            className={active === 5 ? "sidebar-item active" : "sidebar-item"}
            onClick={() => {
              postingJob ? setDisacardPostingJob(true) : handleChange(5);
              setUserToggle(!userToggle);
            }}
          >
            <div
              className="sidebar-left"
              onClick={() => {
                setUserToggle((prev) => !prev);

                //SET REPORTS TOOGGLE FALSE
                setPermanentReportToggle((prev) => {
                  if (prev) {
                    return false;
                  } else {
                    return prev;
                  }
                });
              }}
            >
              <img src={profileUser} />
              <label>Users</label>
            </div>
            <div>
              <motion.img
                src={userToggle || active === 5 ? downArrow : whiteArrow}
                className="sidebar-right"
                alt="downarrow"
                animate={{ rotate: userToggle ? 0 : -90 }}
              />
            </div>
          </div>

          {userToggle && (
            <div className="sidebar-item-li">
              <Link to="/user/user-list">
                <img src={dropdownStick} className="dropdownStick-one" />
                <li
                  className={userActive === 1 ? "li-active" : ""}
                  onClick={() => setUserActive(1)}
                >
                  User List
                </li>
              </Link>
              <Link to="/user/permission">
                <img src={dropdownStick} className="dropdownStick-two" />
                <li
                  className={userActive === 2 ? "li-active" : ""}
                  onClick={() => setUserActive(2)}
                >
                  Permissions
                </li>
              </Link>
            </div>
          )}

          {/* Billing */}

          <Link to={postingJob ? "/locum-job" : "/billing"}>
            <div
              className={active === 6 ? "sidebar-item active" : "sidebar-item"}
              onClick={() => {
                postingJob ? setDisacardPostingJob(true) : handleChange(6);
                trackDashboardBilling();
              }}
            >
              <div className="sidebar-left">
                <img src={calendar} alt="calendar" />
                <label>Billing</label>
              </div>
            </div>
          </Link>

          {/* Rating */}

          {/* <Link to={postingJob ? "/locum-job" : "/rating"}>
          <div
            className={active === 7 ? "sidebar-item active" : "sidebar-item"}
            onClick={() => {
              postingJob ? setDisacardPostingJob(true) : handleChange(7);
            }}
          >
            <div className="sidebar-left">
              <img src={heart} alt="heart" />
              <label>Rating</label>
            </div>
          </div>
        </Link> */}
        </div>
      )}

      <Modal
        open={isUpcomingOpen}
        onClose={() => setIsUpcomingOpen(!isUpcomingOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpcomingEvent setIsUpcomingOpen={setIsUpcomingOpen} />
      </Modal>

      {/* Confirmation Modal */}

      {/* Posting a job Confirmation Modal */}
      <Modal
        open={discardPostingJob}
        // onClose={() => setDiscardPostingJob(!discardPostingJob)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PostingJobModal
          setDiscardPostingJob={setDisacardPostingJob}
          setPostingJob={setPostingJob}
          dispatch={dispatch}
          setModel={setModel}
        />
      </Modal>
    </>
  );
}

export default Sidebar;
