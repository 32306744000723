import React, { useState } from "react";
import "./JobPost.scss";
import red from "../../../asset/dashboard/redPointer.svg";
import grey from "../../../asset/dashboard/greyPointer.svg";
import blue from "../../../asset/dashboard/bluePointer.svg";
import PieChart from "../PieChart/PieChart";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

//Checking
function JobPost({ data }) {
  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackDashLocumJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BD_LOCUM
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackDashPermanentJobPosted = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BD_PRMNT
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  //  console.log("1--->", data);
  return (
    <>
      <div className="post-wrapper">
        <div className="post-container">
          <div className="post-left" onMouseOver={trackDashLocumJob}>
            <div>
              {/* <h3>{"LOCUM JOBS"}</h3> */}
              <h3>{"TOTAL Vs ACTIVE"}</h3>
            </div>
            <div>
              {/* <h3 className="postHeader">{data?.locumJobs}</h3> */}
              <h3 className="postHeader">{data?.last12MonthJobs}</h3>
              <p>
                {/* <img src={red} /> {"Open Jobs"} */}
                <img src={red} /> {"Locum Jobs Posted"}
              </p>
            </div>
            <div>
              <h3 className="postHeader">{data?.activeOpenLocumJobs}</h3>
              <p>
                <img src={grey} /> {"Active Locum Jobs"}
              </p>
            </div>
          </div>
          <div className="post-right" id="active-jobs-chart">
            <PieChart
              title1={"Active Locum Jobs"}
              title2={"Locum Jobs Posted"}
              // title2={"Open Jobs"}
              value1={data?.activeOpenLocumJobs}
              // value2={data?.locumJobs}
              value2={data?.last12MonthJobs}
              // title1={"Open Jobs"}
              // title2={"Active Locum Jobs"}
              // value1={data?.locumJobs}
              // value2={data?.activeLocumJobs}
              color1="#AAAAAA"
              color2="#F36D5F"
            />
          </div>
        </div>
      </div>

      <div className="post-wrapper">
        <div className="post-container">
          <div className="post-left" onMouseOver={trackDashPermanentJobPosted}>
            <div>
              <h3>{"PERMANENT JOBS POSTED"}</h3>
            </div>
            <div>
              <h3 className="postHeader">{data?.permanentJob}</h3>
              <p>
                <img src={blue} /> {"Permanent Jobs Posted"}
              </p>
            </div>
            <div>
              <h3 className="postHeader">{data?.interviewScheduled}</h3>
              <p>
                <img src={grey} /> {"Interview Scheduled"}
              </p>
            </div>
          </div>
          <div className="post-right" id="permanent-jobs-chart">
            <PieChart
              title1={"Permanent Jobs Posted"}
              title2={"Interview Scheduled"}
              value1={data?.permanentJob}
              value2={data?.interviewScheduled}
              color1="#6096C9"
              color2="#AAAAAA"
            />
          </div>
        </div>
      </div>

      {/* <div className="post-wrapper">
      <div className="post-container">
        <div className="post-left">
          <div>
            <h3>{"LOCUM JOBS"}</h3>
          </div>
          <div>
            <h3 className="postHeader">{data?.locumJobs}</h3>
            <p>
              <img src={grey} /> {"Open Jobs"}
            </p>
          </div>
          <div>
            <h3 className="postHeader">{data?.interviewScheduled}</h3>
            <p>
              <img src={red} /> {"Interview Scheduled"}
            </p>
          </div>
        </div>
        <div className="post-right">
          <PieChart data={data} />
        </div>
      </div>
    </div> */}
    </>
  );
}
export default JobPost;
