import React, { useEffect, useState } from "react";
import "./LogIn.scss";
import Button from "@mui/material/Button";
import loginImage from "../../asset/Login/LoginContent.png";
import { fontWeight } from "@mui/system";
import logo from "../../asset/navbar/logo.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { loginSchema } from "../../schema/authSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { login } from "../../redux/apiCall";
import { useDispatch } from "react-redux";
import publicRequest from "../../requestMethod";
import graphqlRequest from "../../requestMethod";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import { async } from "q";
import VerifyOTP from "./PhoneOTP/VerifyOTP";
import { IconButton, InputAdornment, Modal, Tooltip } from "@mui/material";
import PhoneEmailOtp from "../../components/PopUp/Otp/PhoneEmailOtp/PhoneEmailOtp";
import ForgetPassword from "../../components/PopUp/Otp/ForgetPassword/ForgetPassword";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import AppleLogin from "react-apple-login";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
// import carousel1 from "../../asset/carousel/Carousel1.png";
// import carousel2 from "../../asset/carousel/carousel2.png";
// import carousel3 from "../../asset/carousel/carousel3.png";
import carousel1 from "../../asset/carousel1/carousel1.png";
import carousel2 from "../../asset/carousel1/carousel2.png";
import carousel3 from "../../asset/carousel1/carousel3.png";
import carousel4 from "../../asset/carousel1/carousel4.png";
import carousel5 from "../../asset/carousel1/carousel5.png";
import loginProfIcon from "../../asset/loginProfIcon.svg";

import {
  auth,
  siginInWithAppleID,
  signInWithGoogle,
} from "../../services/firebase";

import Google from "../../services/Google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useRef } from "react";
import MobileViewPopUp from "../../components/PopUp/MobileViewPopUp/MobileViewPopUp";
import { signUpSuccess } from "../../redux/userReducer";
import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";
import blitz from "blitzllama-js";
import eventHelpers from "../../Helpers/Event";

const LogIn = () => {
  const [Tabvalue, setTabValue] = React.useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFatching } = useSelector((state) => state);
  const [isOtpModelOpen, setOtpModelOpen] = useState(false);
  const [userInput, setUserInput] = useState(null);
  const [token, setToken] = useState("");
  const [forgetModel, setForgetModel] = useState(false);
  const [forgetEmailOrPassword, setForgetEmailOrPassword] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [forgetPasswordToken, setForgetPasswordToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successForgetToken, setSuccessForgetToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showMobilePopUp, setShowMobilePopUp] = useState(false);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  let [emailError, setEmailError] = useState({
    test: "",
  });
  let [logincred, setLoginCred] = useState({
    email: "",
    password: "",
  });
  let [otpCred, setOtpCred] = useState({
    emailPhone: "",
  });
  let [otpCredError, setOTPCredError] = useState({
    emailPhone: "",
  });
  let [loginCredError, setLoginCredError] = useState({
    test: "",
  });
  let [passwordChange, setPasswordChnage] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  let [passwordChangeError, setPasswordChangeError] = useState({
    test: "",
  });

  const [googleLoginUser, setGoogleLoginUser] = useState({});

  // const [userInputForget, SetUserInputForget] = useState()
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const {
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  // console.log("register");

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response?.data?.withAuth?.userDetails?.id)
      localStorage.setItem("UserId", response?.data?.withAuth?.userDetails?.id);
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };
  const openBlitzllamaSurvey = async () => {
    const user = await fetchUserId();
    // console.log("1--->", user.data.withAuth.userDetails.emails[0].address)
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    //const combined = "USER_ID"; // Replace with the actual user identifier
    blitz.init(process.env.REACT_APP_BLITZLLAMA_API_KEY);
    blitz.logout();
    blitz.createUser(userId);
    const eventName = "nps";
    const eventData = { event_property: "LOGIN" };
    blitz.triggerEvent(eventName, eventData);
    blitz.setUserProperties({ name: userName, email: userEmail });
  };

  const trackUserLogin = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGIN_FINAL_PASWRD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
      window.Moengage.addEventListener(
        "MOE_AUTOMATED_EVENTS",
        function (event) {
          if (
            event.detail.name === "MOE_ONSITE_MESSAGE_SHOWN" &&
            event.detail.data &&
            event.detail.data.length
          ) {
            //do some processing on event.detail.data
          }
          if (
            event.detail.name === "MOE_ONSITE_MESSAGE_CLICKED" &&
            event.detail.data &&
            event.detail.data.length
          ) {
            //do some processing on event.detail.data
          }
          if (
            event.detail.name === "MOE_ONSITE_MESSAGE_DISMISSED" &&
            event.detail.data &&
            event.detail.data.length
          ) {
            //do some processing on event.detail.data
          }
          if (
            event.detail.name === "MOE_ONSITE_MESSAGE_AUTO_DISMISS" &&
            event.detail.data &&
            event.detail.data.length
          ) {
            //do some processing on event.detail.data
          }
        }
      );
    }
  };

  const handleLoginSubmit = async (data, e) => {
    e.preventDefault();
    const response = await login(dispatch, data);
    console.log("login Response-->", response.data.loginV1);
    // if (response?.data?.loginV1 && screenSize.width > 767) {
    if (response?.data?.loginV1) {

      if (response?.data?.loginV1?.isAdmin === true) {
        // console.log("Storing token in localStorage:", response.data.login.accessToken);
        navigate("/admin/login");
        localStorage.setItem("adminToken", response.data.loginV1.accessToken);
      } else if (
        response?.data?.loginV1?.verifyToken &&
        response.data?.loginV1?.accessToken == null
      ) {
        navigate("/login/setpassword");
        localStorage.setItem(
          "SetVerifyToken",
          response.data.loginV1.verifyToken
        );
      } else {
        navigate("/");
        message.success("Login Successful!");
        trackUserLogin();
        openBlitzllamaSurvey();
      }
    }
    //  else if (screenSize.width < 767 && response?.data?.loginV1) {
    //   setShowMobilePopUp(true);
    //   openBlitzllamaSurvey();
    // }
     else {
      message.error("Email or Password is incorrect");
    }
  };

  const inputRef = useRef(null);

  function loginWithOtp() {
    const query = `
    query {
      loginWithOtp(emailOrPhone:"${userInput}",isWeb:true)
    } 
  `;

    graphqlRequest(query).then((response) => {
      if (response?.errors?.[0]?.message === "Check your credentials!") {
        message.error("Email/Phone number is not registered with us!");
      } else {
        console.log(response);
        setToken(response?.data?.loginWithOtp);
        setOtpModelOpen(true);
        message.success("OTP has been sent.");
      }
    });
  }
  function loginWithForgetOtp() {
    const query = `
    query {
      forgotPasswordHospital(email:"${forgetEmailOrPassword}")
    } 
  `;
    graphqlRequest(query).then((response) => {
      if (response?.data?.forgotPasswordHospital) {
        setForgetPasswordToken(response?.data?.forgotPasswordHospital);
        message.success("OTP Sent Successfully ");
        setForgetModel(true);
      } else {
        message.error("Email is not registered with us!");
        // console.log("Forgetresponse",response)
        // alert("Check your credentials!")
        // setToken(response.data.loginWithOtp)
      }
    });
  }
  function handleResendForgetOtp() {
    const query = `
    query {
      forgotPasswordHospital(email:"${forgetEmailOrPassword}")
    } `;
    graphqlRequest(query).then((response) => {
      setForgetPasswordToken(response?.data?.forgotPasswordHospital);
    });
  }
  function handleResendOtp() {
    const query = `
    query {
      loginWithOtp(emailOrPhone:"${userInput}",isWeb:true,resend:true)
    }
  `;

    graphqlRequest(query).then((response) => {
      setToken(response.data.loginWithOtp);
    });
  }
  let handleOtpVerification = () => {
    loginWithOtp();
  };
  let handleForgetModel = () => {
    setForgetModel(!forgetModel);
  };
  function validatemyEmail() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!forgetEmailOrPassword) {
      error.email = "please provide you mail id ";
    }
    if (!regex.test(forgetEmailOrPassword)) {
      error.email = "Invalid Mail Id ";
    }
    return error;
  }
  useEffect(() => {
    setEmailError(validatemyEmail);
  }, [forgetEmailOrPassword]);

  // //On MOUNT
  // useEffect(() => {
  //   auth.onAuthStateChanged(async (user) => {
  //     const userInfo = user;
  //     console.log("USER", userInfo);

  //     setGoogleLoginUser(userInfo);
  //   });
  // }, []);

  let handleForgetPassword = () => {
    loginWithForgetOtp();
  };
  const handleNewPAssword = () => {
    const query = `mutation {
      resetPassword(
          resetToken: "${successForgetToken}",
          password: "${passwordChange?.newPassword}"
      )
    }`;
    graphqlRequest(query).then((response) => {
      if (response) {
        message.success("Password Reset successfully");
        setTabValue(0);
      } else {
        message.error("Someting Went Wrong");
      }
    });
  };

  function validateLoginError() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!logincred.email) {
      error.email = "Please provide you email";
    }
    if (!regex.test(logincred.email)) {
      error.email = "Please enter valid Email";
    }
    if (!logincred.password) {
      error.password = "Please provide your password";
    }
    if (!logincred.password) {
      error.password = "Please provide your password";
    }
    if (logincred?.password?.length == 0) {
      error.password = "Password Field cannot be blank";
    }
    if (
      logincred?.password?.length > 0 &&
      (logincred?.password?.length < 8 || logincred?.password?.length > 16)
    ) {
      error.password = "Please enter valid Password";
    }
    return error;
  }
  function validateChangePassword() {
    const regex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+={}|[\]\\:;\"\'<>,./?-]).{8,16}$/;
    let error = {};
    if (passwordChange.newPassword !== passwordChange.confirmPassword) {
      error.password = "Password Missmatch";
    }
    if (!regex.test(passwordChange.newPassword)) {
      error.password = "Invalid Password";
    }

    return error;
  }
  function validateOTPError() {
    let error = {};

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    // let isValidEmail = emailRegex.test(value);
    // let isValidPhone = phoneRegex.test(value);

    // if (!isValidEmail && !isValidPhone) {
    //   return false;
    // }
    // if (isValidEmail) {
    //   return "emailAddress";
    // }
    // if (isValidPhone) return "number";

    let isValidEmail = emailRegex.test(userInput);
    let isValidPhone = phoneRegex.test(userInput);
    if (!userInput) {
      console.log("11111");
      error.emailPhone = "Email or Mobile Number Field cannot be Blank";
    } else if (!isValidEmail && !isValidPhone) {
      error.emailPhone = "Please enter Valid Email or Mobile Number";
    }
    // if (isValidEmail) {
    //   return "emailAddress";
    // }
    // if (isValidPhone) return "number";
    // if (!emailRegex.test(userInput) || !phoneRegex.test(userInput)) {
    //   console.log("22222");
    //   error.emailPhone = "invalid email/phone";
    // }
    // if (!phoneRegex.test(userInput)) {
    //   console.log("33333");
    //   error.emailPhone = "invalid phone";
    // }
    return error;
  }
  useEffect(() => {
    setLoginCredError(validateLoginError());
  }, [logincred]);
  useEffect(() => {
    setOTPCredError(validateOTPError());
  }, [userInput]);
  useEffect(() => {
    setPasswordChangeError(validateChangePassword());
  }, [passwordChange]);
  // useEffect(() => {
  //   console.log("EMAIL", document.getElementById("email"));
  //   inputRef?.current?.click();
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      // document.getElementById("header").click();
      inputRef.current.focus();
      // console.log("clicked", document.getElementById("header"));
    }, "3000");
  }, []);

  useEffect(() => {
    dispatch(signUpSuccess());
  }, [window.location.pathname]);

  const pressEnter = (e) => {
    if (e.keyCode == 13) {
      handleLoginSubmit(logincred, e);
    }
  };
  const pressEnterOTP = (e) => {
    if (e.keyCode == 13) {
      handleOtpVerification(userInput, e);
    }
  };

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const trackUserLoginWithOTP = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGIN_INT_OTP
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackUserLoginWithPssrd = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      // console.log("12345678i")
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGIN_INT_PASWRD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackUserFinalLoginWithOTP = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      // console.log("12345678i")
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGIN_FINAL_OTP
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackUserForgetPassword = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      // console.log("12345678i")
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.FRGT_PASWRD
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    // <>
    // {
    //   screenSize.width<767?
    //   <h2>Use desktop mode for better view</h2>
    //   :

    <>
      <div className="login-parent-container" id="header">
        <div className="login-inner-container">
          <div className="login-header">
            <div>{/* <img src={logo} /> */}</div>
            {/* part time set */}
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <div>
              <img src={logo} /> 
              {/* <img src={logo} style={{ marginRight: "345px"  }} /> */}
              <Button
                id="sign-up-button"
                variant="outlined"
                // sx={{ padding: "8px 25px", fontWeight: "bold" }}
                onClick={() => navigate("/signup")}
                disabled={false}
              >
                Sign Up
              </Button>
            </div>
            {/* <div>
              <Button
                id="sign-up-button"
                variant="outlined"
                sx={{ padding: "8px 25px", fontWeight: "bold" }}
                onClick={() => navigate("/signup")}
                disabled={false}
              >
                Sign Up
              </Button>
            </div> */}
          </div>
          <div className="login-outer-content-container">
            <div className="login-content-left-container">
              <div className="login-content-left-containerContent">
                {/* <div
                  className="login-content-left-containerContent-Div"
                
                >
                  <div style={{ marginTop: "-35px" }}>
                  </div>
                  <br />
                  <h3 id="headline">
                    {" "}
                    The Future of <br class="break" /> Healthcare Hiring is
                    here!
                  </h3>
                  <h4 id="headline1" style={{ fontWeight: "normal" }}>
                    Made in India for the World.
                  </h4>
                </div> */}

                {width > 480 ? (
                  <div className="login-content-left-containerContent-Div">
                    {/* <div style={{ marginTop: "-35px" }}></div> */}
                    <br />
                    <h3>
                      {" "}
                      The Future of <br class="break" /> Healthcare Hiring is
                      here!
                    </h3>
                    <h4 style={{ fontWeight: "normal" }}>
                      Made in India for the World.
                    </h4>
                  </div>
                ) : (
                  <>
                    <h3
                      id="headline"
                      style={{
                        width: "324px",
                        marginTop: "10px",
                        marginLeft: "16px",
                        textAlign: "left",
                        color: "white",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                      }}
                    >
                      Let us help you get the perfect person for your job.
                    </h3>

                    <h4
                      style={{
                        width: "324px",
                        marginTop: "1px",
                        marginLeft: "16px",
                        textAlign: "left",
                        color: "white",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Posting a job on our Platform is quick and easy, and it
                      wont take more than 10 minutes to do so.
                    </h4>
                  </>
                )}

                <p
                  className="login-content-left-containerContent-P"
                  style={{
                    fontFamily: "Noto Sans",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "16px",
                    width: "50%",
                    color: "#fff",
                    marginTop: "-2px",
                    marginLeft: "3px",
                    alignItems: "center",
                    textAlign: "left",
                    display: width > 480 ? "block" : "none",
                  }}
                >
                  India's first healthcare marketplace for connecting verified
                  healthcare professionals with employers/hiring managers.
                </p>
              </div>

              {width < 480 && (
                <div>
                <img
                  src={loginProfIcon}
                  style={{
                    // width: "100%",
                    // height: "100%",
                    // width: "224",
                    // height: "200",
                    marginTop: "-14px",
                    marginLeft: "-160px",
                  }}
                />
              </div>
              )}

              {width > 480 && (
                <AutoplaySlider
                  play={true}
                  organicArrows={false}
                  infinite={true}
                  bullets={false}
                  style={{ backgroundColor: "#fff", }}
                  fillParent={false}
                >
                  <div>
                    <img
                      src={carousel1}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel2}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel3}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel4}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Noto Sans",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: "50%",
                        color: "#fff",
                        marginTop: "-2px",
                        // marginLeft: "2.5rem",

                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      Our Clients:
                    </p>
                    <img
                      src={carousel5}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </AutoplaySlider>
              )}
              {/* {width < 767 && (
                <AutoplaySlider
                  play={true}
                  organicArrows={false}
                  infinite={true}
                  bullets={false}
                  style={{ backgroundColor: "#fff" }}
                  fillParent={false}
                >
                  <div>
                    <img
                      src={carousel1}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel2}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel3}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <img
                      src={carousel4}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Noto Sans",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "16px",
                        width: "50%",
                        color: "#fff",
                        marginTop: "-2px",
                        // marginLeft: "2.5rem",

                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      Our Clients:
                    </p>
                    <img
                      src={carousel5}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </AutoplaySlider>
              )} */}
            </div>
            <div className="login-content-right-container">
              <div className="login-inner-content-right-container">
                <div className="login-inner-heading">
                  <span>Get Started Now</span>
                  <span>Enter your credentials to access your account.</span>
                </div>
                <div className="login-inner-tabs">
                  <Tabs
                    value={Tabvalue}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Login with password"
                      onClick={trackUserLoginWithPssrd}
                    />
                    <Tab
                      label="Login with OTP"
                      onClick={trackUserLoginWithOTP}
                    />
                  </Tabs>
                </div>
                <div className="login-inner-credentials">
                  {/* Login with password */}
                  {Tabvalue === 0 && (
                    <Box sx={{ p: 3 }}>
                      <form>
                        <form noValidate>
                          <div className="input-fields">
                            <TextField
                              name="email"
                              id="email"
                              label="Email"
                              // autoComplete="new-password"
                              // autoComplete={true}
                              type={"text"}
                              fullWidth
                              onBlur={() => {
                                if (loginCredError.email) {
                                  message.error(loginCredError.email);
                                }
                              }}
                              // InputProps={{ autoComplete: "new-password" }}
                              // inputRef={inputRef}
                              onChange={(e) =>
                                setLoginCred({
                                  ...logincred,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </form>
                        <form noValidate>
                          <div className="input-fields">
                            <TextField
                              // autoComplete="new-password"
                              name="password"
                              id="password"
                              // InputProps={{ autoComplete: "new-password" }}
                              // autoComplete={true}
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              onBlur={() => {
                                if (loginCredError.password) {
                                  message.error(loginCredError.password);
                                }
                              }}
                              onChange={(e) =>
                                setLoginCred({
                                  ...logincred,
                                  password: e.target.value,
                                })
                              }
                              onKeyDown={pressEnter}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <span
                              onClick={(e) => {
                                handleChange(e, 2);
                                trackUserForgetPassword();
                              }}
                              style={{
                                cursor: "pointer",
                                width: "125px",
                                marginLeft: "auto",
                              }}
                            >
                              Forgot Password?
                            </span>
                          </div>
                        </form>
                        <div className="input-fields">
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={Object.keys(loginCredError).length !== 0}
                            onClick={(e) => handleLoginSubmit(logincred, e)}
                          >
                            {isFatching ? <CircularProgress /> : "Log In"}
                          </Button>

                          <div className="login-buttons">
                            <GoogleOAuthProvider clientId="1010923642758-7j3ub289s55cphbel85vtnkov74vhjjq.apps.googleusercontent.com">
                              <Google dispatch={dispatch} navigate={navigate} />
                            </GoogleOAuthProvider>
                           
                          </div>
                        </div>
                      </form>
                    </Box>
                  )}
                  {/* login with otp */}
                  {Tabvalue === 1 && (
                    <Box sx={{ p: 3 }}>
                      <div className="input-otp-fields">
                        <span>
                          Please enter your registered email ID or mobile number
                          with us.
                        </span>
                        <TextField
                          name="emailPhone"
                          id="emailPhone"
                          label="Email / Phone"
                          fullWidth
                          onBlur={() => {
                            if (otpCredError.emailPhone) {
                              message.error(otpCredError.emailPhone);
                            }
                          }}
                          onChange={(e) => setUserInput(e.target.value)}
                          onKeyDown={pressEnterOTP}
                        />
                      </div>
                      <div className="input-otp-fields">
                        <Button
                          variant="contained"
                          fullWidth
                          // onClick={handleOtpVerification}
                          disabled={Object.keys(otpCredError).length !== 0}
                          onClick={(e) => {
                            handleOtpVerification(userInput, e);
                            trackUserFinalLoginWithOTP();
                          }}
                        >
                          Log In
                        </Button>
                      </div>
                    </Box>
                  )}
                  {/* reset password */}
                  {Tabvalue === 2 && (
                    <Box sx={{ p: 3 }}>
                      <div className="input-otp-fields">
                        <span>
                          Please enter your registered email ID to reset
                          Password.
                        </span>
                        <TextField
                          label="Verify Email"
                          fullWidth
                          autoComplete="off"
                          onBlur={() => {
                            if (emailError.email) {
                              message.error(emailError.email);
                            }
                          }}
                          onChange={(e) =>
                            setForgetEmailOrPassword(e.target.value)
                          }
                        />
                      </div>
                      <div
                        className="input-otp-fields"
                        style={{ display: "flex", gap: "20px" }}
                      >
                        <Button
                          variant="contained"
                          style={{ width: "40%" }}
                          fullWidth
                          onClick={() => setTabValue(0)}
                          // disabled={Object.keys(emailError).length !==0}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={handleForgetPassword}
                          disabled={Object.keys(emailError).length !== 0}
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  )}
                  {/* //createPassword */}
                  {Tabvalue === 3 && (
                    <Box sx={{ p: 3 }}>
                      <Tooltip
                        placement="right"
                        title="Your password must be 8-16 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number."
                      >
                        <InfoIcon />
                      </Tooltip>
                      <div className="input-otp-fields">
                        <span>Create New Password</span>
                        <TextField
                          label="Enter New Password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                          onBlur={() => {
                            if (passwordChangeError.password) {
                              message.error(passwordChangeError.password);
                            }
                          }}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword}>
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setPasswordChnage({
                              ...passwordChange,
                              newPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="input-otp-fields">
                        <TextField
                          label="Re-Enter New Password"
                          type={showPassword2 ? "text" : "password"}
                          fullWidth
                          autoComplete="off"
                          onBlur={() => {
                            if (passwordChangeError.password) {
                              message.error(passwordChangeError.password);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword2}>
                                  {showPassword2 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setPasswordChnage({
                              ...passwordChange,
                              confirmPassword: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="input-otp-fields">
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={handleNewPAssword}
                          disabled={
                            Object.keys(passwordChangeError).length !== 0
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </Box>
                  )}
                </div>
                <div className="login-bottom-content">
                  <div>
                    <div></div>
                    <span>OR</span>
                    <div></div>
                  </div>
                  <div>
                    <span>
                      Don't Have an Account? <Link to="/signup">Sign Up</Link>
                    </span>
                  </div>
                  <div>
                    {/* <span>
                      <a
                        href="https://ifanflex.staging.jobizo.com/#/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </span> */}
                    <span>
                      <a
                        // href="https://ifanflex.staging.jobizo.com/#/terms-and-condition"
                        href="https://jobizo.com/terms-and-conditions/"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOtpModelOpen}
        onClose={setOtpModelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PhoneEmailOtp
          openOtpHandler={isOtpModelOpen}
          setOpenOtpHandler={setOtpModelOpen}
          verifyMode="Email id"
          token={token}
          handleResendOtp={handleResendOtp}
          userInput={userInput}
        />
      </Modal>
      <Modal
        open={forgetModel}
        onClose={handleForgetModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ForgetPassword
          setSuccessForgetToken={setSuccessForgetToken}
          forgetPasswordToken={forgetPasswordToken}
          setTabValue={setTabValue}
          forgetModel={forgetModel}
          setForgetModel={setForgetModel}
          handleResendForgetOtp={handleResendForgetOtp}
          forgetEmailOrPassword={forgetEmailOrPassword}
        />
      </Modal>
      <Modal
        open={showMobilePopUp}
        onClose={setShowMobilePopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MobileViewPopUp
          openOtpHandler={showMobilePopUp}
          setOpenOtpHandler={setShowMobilePopUp}
        />
      </Modal>
    </>
    // }

    // </>
  );
};

export default LogIn;