import * as yup from 'yup';


export const loginSchema = yup.object().shape({
    email: yup.string().email().required("Email can not be blank"),
    password: yup.string().required("Password can not be blank")
})

export const signUpSchema = yup.object().shape({
    salutation :yup.string().required("Salutation is required"),
    name :yup.string().required("Name is required"),
    registered_org :yup.string().required("Registered Organization is required"),
    offical_email :yup.string().email().required("Official Email is required"),
    phone :yup.number("Phone Must be a number").positive("Phone Number Must be Positive").required("Salutation is required"),
    role :yup.string().required("Role is required"),
    company_type:yup.string().required("Company Type is required"),
    website :yup.string().required("Website is required"),
    terms_condition:yup.bool().required("Please accept terms & condition")
})
