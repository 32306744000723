import React from "react";
import "./ProfileList.scss";
import green from "../../../asset/jobProfile/green.svg";
import red from "../../../asset/jobProfile/red.svg";
import blue from "../../../asset/jobProfile/blue.svg";
import grey from "../../../asset/jobProfile/grey.svg";
import yellow from "../../../asset/jobProfile/yellow.svg";
import Avtar from "../../../asset/avtar.png";
import user from "../../../asset/navbar/user.svg";
import badge from "../../../asset/badge.svg";
import tickMark from "../../../asset/tickMark.svg";

function ProfileList({ data, img, selectedProfile }) {
  function selectFlag(state) {
    // OPEN, ACCEPTED, REJECTED, PENDING_TEST, REVIEW, PENDING_INTERVIEW, FINISHED, WITHDRAW
    if (state === "ACCEPTED") {
      return green;
    }
    if (state === "REJECTED") {
      return red;
    }
    if (state === "PENDING_TEST") {
      return grey;
    }
    if (state === "REVIEW") {
      return yellow; //yellow
    }
    if (state === "PENDING_INTERVIEW") {
      return blue;
    }
    if (state === "SCHEDULED_INTERVIEW") {
      return blue;
    }
    if (state === "OPEN") {
      return grey;
    }
    if (state === "WITHDRAW") {
      return red;
    }
  }

  // function selectFlagStatus(status) {
  //   switch (status) {
  //     case "BGVTRIGGERED":
  //       return "Pending Verification";
  //     // case "BGVFAILED":
  //     //   return "BGVFAILED";
  //     default:
  //       return "";
  //   }
  // }  BGVFAILED

  function selectFlagStatus(status) {
    if (status === "BGVTRIGGERED") {
      return "Pending Verification";
    }
    return "";
  }

  const getFlagTapeClass = () => {
    return data?.professional?.status === "BGVTRIGGERED"
      ? "flag-tape flag-tape-visible"
      : "flag-tape";
  };

  // const getFlagTapeClass = () => {
  //   switch (data?.professional?.status) {
  //     case "BGVTRIGGERED":
  //       return "flag-tape flag-tape-amber";
  //     // case "BGVFAILED":
  //     //   return "flag-tape flag-tape-red";
  //     default:
  //       return "flag-tape";
  //   }
  // };

  console.log("RecommendationUserData", data);

  return (
    <div
      className={
        selectedProfile?.id === data?.id
          ? "profile-card-container active"
          : "profile-card-container"
      }
      // style={{ backgroundColor: selectedProfile?.id === data?.id ? "red" : "" }}
    >
      <div className="profile-card-wrapper">
        <div className="user-photo-profile">
          {data?.professional?.marksPercentage >= 60 && (
            <div
              className="dp-badge-hover"
              data-message="Jobizo Assessment Qualified"
            >
              <img src={badge} className="dp-badge" />
            </div>
          )}
          <img
            src={data?.professional?.avatar?.url || Avtar}
            className={
              data?.professional?.marksPercentage >= 60 ? "badge-border" : ""
            }
          />

          {/* {data?.professional?.avatar?.url ? (
            <img src={data?.professional?.avatar?.url} />
          ) : (
            <img src={user} />
          )} */}
        </div>
        <div className="user-details">
          <div className="ud-one">
            <h4>
              {/* {data?.professional?.name?.salutation}{" "} */}
              {data?.professional?.name?.full
                ? data?.professional?.name?.full
                : "N.A"}
            </h4>
            {data?.professional?.status === "ACTIVE" ? (
              <img src={tickMark} className="dp-tickmark" alt="Tick Mark" />
            ) : null}
          </div>
          <div className="ud-two">
            <p>
              {data?.professional?.specialization?.name
                ? data?.professional?.specialization?.name
                : "N.A"}
            </p>
          </div>
        </div>
        <div className="user-experience">
          <p>
            Exp.{" "}
            {data?.professional?.numExperience?.name
              ? data?.professional?.numExperience?.name
              : "N.A"}
          </p>
        </div>
      </div>
      {/* <div className="flag-tape">Similar Profile</div> */}
      <div className={getFlagTapeClass()}>
        {selectFlagStatus(data?.professional?.status)}
      </div>
      {/* <div className="banner-tag">
        <img src={selectFlag(data.state)} />
      </div> */}
    </div>
  );
}

export default ProfileList;
