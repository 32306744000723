import React from "react";
import "./Navbar.scss";
import logo from "../../asset/navbar/logo.svg";
import notification from "../../asset/navbar/Vector.svg";
import user from "../../asset/navbar/user.svg";
import downArrow from "../../asset/navbar/downArrow.svg";
import dp from "../../asset/popup/dp.svg";
import Avtar from "../../asset/avtar.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setJobPostedReducer,
  setModel,
  setPostingJobReducer,
} from "../../redux/userReducer";
import { Link, useNavigate } from "react-router-dom";
import graphqlRequest from "../../requestMethod";
import { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { Modal } from "@mui/material";
import LogoutConfirmation from "../PopUp/LogoutConfirmation/LogoutConfirmation";
import signOut from "../../asset/dashboard/signOut.svg";
import profileIcon from "../../asset/dashboard/profileIcon.svg";
import commentIcon from "../../asset/dashboard/commentbox.jpg";
import PostingJobModal from "../Dashboard/JobPost/PostingModal";
import eventHelpers from "../../Helpers/Event";
import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";
import JobShiftPostDetails from "./../PopUp/JobShiftPostDetails/JobShiftPostDetails";
import {
  setJobModal,
  setJobDetaialsModal,
} from "../../redux/userReducer";
 
function Navbar(props) {

  let { jobModal, detailsJobModal } = useSelector((state) => state.user);
  let [selectedData, setSelectedData] = useState({});

  let dispatch = useDispatch();

  const handleCloseDetail = () => dispatch(setJobDetaialsModal());
  const navigate = useNavigate();
  const [postingJOb, setPostingJob] = useState(false);

  const [discardPostingJob, setDiscardPostingJob] = useState(false);

  const jobPostingState = useSelector((state) => state);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openProfile = () => {
    navigate("/user-profile");
  };

  const [userData, setUserData] = useState();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false);

  const [showQueryModal, setShowQueryModal] = useState(false);

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;


  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);


  let fetchUserDetails = () => {
    const query = `
    query {
      withAuth(token: "${token}") {
          userDetails {
              id
              marksPercentage
              avatar{
                url
              }
              name{
                first
                full
              }
              organizations{
                organization{
                  id
                  companies{
                    id
                    name
                    logo{
                      url
                    }
                  }
                  name
                  
                }
                role{
                  id
                  name
                }
              }
          }
      }   
  }
    `;

    graphqlRequest(query).then((response) => {
      setUserData(response?.data?.withAuth);
      // if (response?.errors) {
      //   message?.error("Already invited!");
      // }
      // else if (response?.data?.withAuth?.inviteUser) {
      //   message?.success("Email has been sent");
      // }
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  function handleLogout() {
    dispatch(setActiveTab(1));
    localStorage.removeItem("auth");
    window.location.reload();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  let navigateToProfile = () => {
    setIsSidebarOpen(!isSidebarOpen);
    navigate("/user-profile");
  };

  useEffect(() => {
    if (window.location.pathname == "/locum-job") {
      setPostingJob(true);

      dispatch(setPostingJobReducer());
    } else if (window.location.pathname == "/permanent-job") {
      setPostingJob(true);
    } else {
      setPostingJob(false);
      dispatch(setJobPostedReducer());
    }
  }, [window.location.pathname, postingJOb]);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackInitialPost = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.INT_JOB_PST
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackProfileSetting = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.PRFL_VIEW
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLogoutYes = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGOUT_Y
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackLogoutNo = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.LOGOUT_N
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  // console.log("userData", userData)


  const handleWriteToUsClick = () => {
    setShowQueryModal(true);
    // Check if setShowQueryModal is updating the state correctly
    console.log("Show Query Modal:", showQueryModal);
  }

  return (
    <div className="navbar-wrapper">
      <div className="left">
        {/* <Link to="/"> */}
        <img
          src={logo}
          alt="jobizo-logo"
          onClick={() => {
            // navigate("/");b o
            window.location.reload();
          }}
        />
        {/* </Link> */}
      </div>
      <div className="right">
        <button
          onClick={() => {
            postingJOb ? setDiscardPostingJob(true) : dispatch(setModel());
            trackInitialPost();
          }}
          id="post-a-job"
        >
          Post a Job!
        </button>
        {/* <img src={notification} /> */}

        

      {width > 480 ? (
         <div className="navbar-profile">
         <div className="one" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
           <img
             src={userData?.userDetails?.avatar?.url || user}
             style={{ width: "40px", height: "40px", borderRadius: "50%" }}
             alt=""
           />
         </div>
         <div className="two" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
           <div className="two-top">
             <p>{userData?.userDetails?.name?.full}</p>
           </div>
            {/* <div className="two-bottom">
             <p>Super Admin</p>
           </div> */}
         </div>
         <div
           className="three"
           onClick={() => setIsSidebarOpen(!isSidebarOpen)}
         >
           <motion.img
             src={downArrow}
             animate={{ rotate: isSidebarOpen ? 0 : -90 }}
           />
         </div>
         <Modal
           open={isSidebarOpen}
           onClose={() => setIsSidebarOpen(!isSidebarOpen)}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
         >
           <div className="mini-sidebar-container">
             <div className="mini-sidebar-wrapper">
               <div className="ms-top">
                 <div className="ms-left">
                   <img
                     src={userData?.userDetails?.avatar?.url || user}
                     alt="dp"
                   />
                 </div>
                 <div className="ms-right">
                   <h3>{userData?.userDetails?.name?.full}</h3>
                   {/* <p>Super Admin</p> */}
                 </div>
               </div>
               <hr />

               <div
                 style={{ display: "flex", cursor: "pointer" }}
                 onClick={() => {
                   navigateToProfile();
                   trackProfileSetting();
                 }}
               >
                 <img src={profileIcon} style={{ marginRight: "1rem" }} />
                 <h3 className="p-setting">Profile Settings</h3>
               </div>

               <div
                 style={{ display: "flex", cursor: "pointer" }}
                 onClick={handleWriteToUsClick}
               >
                 <img src={commentIcon} style={{ marginRight: "10px", height: "26px", width: "26px", marginTop: "12px" }} />
                 <h3 className="p-setting">Write To Us</h3>
               </div>

               <div
                 style={{ display: "flex", cursor: "pointer" }}
                 onClick={() => {
                   setIsLogoutConfirmationOpen(!isLogoutConfirmationOpen);
                   setIsSidebarOpen(!isSidebarOpen);
                 }}
               >
                 <img src={signOut} style={{ marginRight: "1rem", marginLeft:"4px" }} />

                 <h3 className="signout">Log out</h3>
               </div>
               {/* <p style={{color:"#696969", fontFamily:"cursive", textAlign:"center", fontSize:"12px"}}>Version 1.0.0</p> */}
             </div>
           </div>
         </Modal>
       </div>
      ): (
        <div className="navbar-profile">
        <div className="one" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <img
            src={userData?.userDetails?.avatar?.url || user}
            style={{ width: "40px", height: "40px", borderRadius: "50%" ,}}
            alt=""
          />
        </div>
        <div className="two" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <div className="two-top">
            <p>{userData?.userDetails?.name?.full}</p>
          </div>
          
        </div>
        {/* <div
          className="three"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <motion.img
            src={downArrow}
            animate={{ rotate: isSidebarOpen ? 0 : -90 }}
          />
        </div> */}
        <Modal
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(!isSidebarOpen)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="mini-sidebar-container">
            <div className="mini-sidebar-wrapper">
              <div className="ms-top">
                <div className="ms-left">
                  <img
                    src={userData?.userDetails?.avatar?.url || user}
                    alt="dp"
                  />
                </div>
                <div className="ms-right">
                  <h3>{userData?.userDetails?.name?.full}</h3>
                  {/* <p>Super Admin</p> */}
                </div>
              </div>
              <hr />

              <div
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  navigateToProfile();
                  trackProfileSetting();
                }}
              >
                <img src={profileIcon} style={{ marginRight: "1rem" }} />
                <h3 className="p-setting">Profile Settings</h3>
              </div>

              <div
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  setIsLogoutConfirmationOpen(!isLogoutConfirmationOpen);
                  setIsSidebarOpen(!isSidebarOpen);
                }}
              >
                <img src={signOut} style={{ marginRight: "1rem" }} />

                <h3 className="signout">Log out</h3>
              </div>
              {/* <p style={{color:"#696969", fontFamily:"cursive", textAlign:"center", fontSize:"12px"}}>Version 1.0.0</p> */}
            </div>
          </div>
        </Modal>
      </div>
      )}


      </div>
      <Modal
        open={isLogoutConfirmationOpen}
        onClose={() => setIsLogoutConfirmationOpen(!isLogoutConfirmationOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LogoutConfirmation
          setIsLogoutConfirmationOpen={setIsLogoutConfirmationOpen}
          handleLogout={handleLogout}
        />
      </Modal>

      {/* Posting a job Confirmation Modal */}
      <Modal
        open={discardPostingJob}
        // onClose={() => setDiscardPostingJob(!discardPostingJob)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PostingJobModal
          setDiscardPostingJob={setDiscardPostingJob}
          setPostingJob={setPostingJob}
          dispatch={dispatch}
          setModel={setModel}
        />
      </Modal>
      <Modal
  open={showQueryModal}
  onClose={() => setShowQueryModal(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <JobShiftPostDetails
    handleCloseDetail={handleCloseDetail}
    setShowQueryModal={setShowQueryModal}
    showQueryModal={showQueryModal}
    selectedData={selectedData}
    // fetchShiftLogs={fetchShiftLogs}
  />
</Modal>
    </div>
  );
}

export default Navbar;
