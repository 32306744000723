import React, { useState } from "react";
import FilterDropDown from "../DropDown/DropDown";
import FilterIcon from "../../../asset/jobs/filter.svg";
import filter from "../../../asset/jobs/filter.svg";
import "./FilterJobs.scss";
import SearchIcon from "@mui/icons-material/Search";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { DateRange } from "react-date-range";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import CalendarRange from "../../PopUp/CalendarRange/CalendarRange";

const FilterJobs = ({
  filterDate,
  setFilterDate,
  tableData,
  setFilterTable,
  globalInputSearch,
  SetGlobalInputSearch,
  departmentList,
  departmentValue,
  setDepartmentValue,
  professionsValue,
  setProfessionValue,
  fetchAllData,
  title,
  professional,
  downloadIcon,
  dowload,
  professionalList,
  keyword,
  setKeyword,
  filterByProfession,
  setFilterByProfession,
  filterByDepartment,
  setFilterByDepartment,
  filterByStartDate,
  setFilterByStartDate,
  filterByEndDate,
  setFilterByEndDate,
  filterByBranch,
  setFilterByBranch,
  hospitalBranchList,
  hospitalBranch,
          handleBranchFilterChange,
          hospitalBranchOptions,
          setHospitalBranch,
          setHospitalBranchId
}) => {
  let [openRangeCalendar, setOpenRangeCalendar] = useState(false);

  function resetAll() {
    setDepartmentValue("");
    setProfessionValue("");
    setFilterByProfession("");
    setFilterByDepartment("");
    setFilterByBranch("");
    setHospitalBranch("");
    setHospitalBranchId("")
    setKeyword("");
    setFilterByStartDate("");
    setFilterByEndDate("");
    SetGlobalInputSearch("");
    setFilterTable(tableData);
    fetchAllData();
    // setFilterDate([{ key: "selection" }]);
  }

  function formatDate(date) {
    // return date instanceof Date ? date.toLocaleDateString("en-GB"): date;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  // console.log("start date", filterByStartDate)
  // console.log("hospitalBranchList", hospitalBranchList)
  return (
    <div className="sl-filter">
      <label className="global-search-container">
        <input
          type="text"
          placeholder="Search"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          // style={{ width: "50px" }}
        />
        <div>
          <SearchIcon />
        </div>
      </label>

      <FormControl sx={{}}>
        <TextField
          select
          sx={{ width: "170px" }}
          size="small"
          value={filterByDepartment}
          label="Departments"
          onChange={(e) => setFilterByDepartment(e.target.value)}
          displayEmpty
          labelId="demo-simple-select-autowidth-label"
          inputProps={{ "aria-label": "Without label" }}
        >
          {departmentList?.map((data) => (
            <MenuItem value={data}>{data}</MenuItem>
          ))}
        </TextField>
      </FormControl>

      <FormControl sx={{}}>
        <TextField
          select
          sx={{ width: "170px" }}
          size="small"
          label="Profession"
          value={filterByProfession}
          onChange={(e) => setFilterByProfession(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          labelId="demo-simple-select-autowidth-label"
        >
          {professionalList?.map((data) => (
            <MenuItem value={data}>{data}</MenuItem>
          ))}
        </TextField>
      </FormControl>

      <FormControl sx={{}}>
        <TextField
          select
          sx={{ width: "170px" }}
          size="small"
          // value={filterByBranch}
          value={hospitalBranch}
          label="Hospital Branch"
          // onChange={(e) => setFilterByBranch(e.target.value)}
          onChange={handleBranchFilterChange}
          displayEmpty
          labelId="demo-simple-select-autowidth-label"
          inputProps={{ "aria-label": "Without label" }}
        >
          {/* {hospitalBranchList?.map((data) => (
            <MenuItem value={data}>{data}</MenuItem>
          ))} */}
          
          {hospitalBranchOptions?.map((data) => (
            <MenuItem value={data.value}>{data.value}</MenuItem>
          ))}
        </TextField>
      </FormControl>

      <div className="filter-button-a" onClick={resetAll}>
        <RotateLeftIcon />
      </div>

      <label className="date-range-container">
        {/* <input
          type="text"
          placeholder="Search"
          value={`${filterDate[0]?.startDate.toLocaleDateString()} - ${filterDate[0]?.endDate.toLocaleDateString()} `}
        /> */}
        <input
          type="text"
          placeholder="Search"
          // value={
          //   filterByStartDate instanceof Date && filterByEndDate instanceof Date
          //     ? `${filterByStartDate.toLocaleDateString()} - ${filterByEndDate.toLocaleDateString()}`
          //     : "Date Range"
          // }
          value={
            filterByStartDate && filterByEndDate
              ? `${formatDate(filterByStartDate)} - ${formatDate(
                  filterByEndDate
                )}`
              : "Date Range"
          }
          style={{ width: "100px" }}
        />

        <div onClick={() => setOpenRangeCalendar(!openRangeCalendar)}>
          <EventNoteIcon />
        </div>
      </label>
      <Modal
        open={openRangeCalendar}
        onClose={() => setOpenRangeCalendar(!openRangeCalendar)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CalendarRange
          setOpenRangeCalendar={setOpenRangeCalendar}
          // setFilterDate={setFilterDate}
          // filterDate={filterDate}
          filterByStartDate={filterByStartDate}
          setFilterByStartDate={setFilterByStartDate}
          filterByEndDate={filterByEndDate}
          setFilterByEndDate={setFilterByEndDate}
        />
      </Modal>
    </div>
  );
};

export default FilterJobs;
