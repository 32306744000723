import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useState } from 'react'

import { IconButton, Rating } from '@mui/material';

import { Box, fontFamily } from '@mui/system';


const RatingGrid = ({data}) => {
  var rowArray = [];
  var i=1;

  if(data.length>0)
  {
     rowArray = [];
  
     let RowObj= {
       id:"",
       jobID:"",
       date:"",
       overallRating:"",
       department:"",
   
     }
     rowArray.push({...RowObj})
  }
  console.log("ROW DATA ARRAY",rowArray)


  //COLUMNS
const columns = [
    {
        field: "jobID",
        headerClassName: 'billing--header',
        headerName: `JOB ID`,
      
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "date",
        headerName: `Date`,
        headerClassName: 'billing--header',
       
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "department",
        headerClassName: 'billing--header',
        headerName: `Department`,
       
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "overallRating",
        headerClassName: 'billing--header',
        headerName: `Overall Rating for Job`,
        renderCell: (params) =>{
            console.log("Rating",params.row.overallRating);
            return(
                <Rating name="half-rating" defaultValue={params.row.overallRating} precision={0.5} />
            )
        
        },
        // valueGetter: getID,
        flex: 1,
    },
]

const rows=[
    {
        id:"1",
       jobID:"JO1234567",
       date:"19/12/2022",
       overallRating:4,
       department:"Consultant Cardiologist",
    }
]

  return (
    <Box
    sx={{
      '& .billing--header': {
        backgroundColor: 'rgba(226, 236, 254, 1)',
        color:'rgba(38, 38, 38, 1)',
        fontWeight:"800",
        
      },
    }}
  >
                 <DataGrid
                          getRowId={(row) => row.id}
                          columns={columns}
 
                          rows={ data.length>0 ? rowArray :rows}
                        
                          pagination
                          pageSize={10}
                          style={{margin:0, marginTop:"1rem"}}
                          
                          rowsPerPageOptions={[10]}
                          sx={{
                            backgroundColor: "#ffff",
                            color: "grey",
                            height: "20rem",
                            margin: "1rem",
                            borderRadius: "0.5rem",
                            "& .MuiDataGrid-cell": {
                              padding: "15px",
                            },
                            borderColor: "light-grey",
                            "& .MuiDataGrid-cell:hover": {
                              color: "info",
                            },
                          }}
                        />
    </Box>
  )
}


export default RatingGrid;