import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import graphqlRequest from "../../../../requestMethod";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./ForgetPassword.scss";
import { message } from "../../../Common/SnakeAlert/SnakeAlert";
import useOnClickOutside from "../../../Common/UseOnClickOutside/UseOnClickOutside";
import CreateNewPassword from "../../CreateNewPassword/CreateNewPassword";
import CrossIcon from "../../../../asset/jobs/close-circle.svg";

let initialValue = 60;

const ForgetPassword = ({
  setForgetModel,
  forgetModel,
  forgetEmailOrPassword,
  forgetPasswordToken,
  handleResendForgetOtp,
  setTabValue,
  setLoginMethod,
  loginMethod,
  setSuccessForgetToken,
}) => {
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otpField, setOtpField] = useState(new Array(4).fill(""));
  const [isFatching, setIsFatching] = useState(false);
  const [otpExpire, setOtpExpire] = useState(initialValue);
  const [newForgetPassword, setNewForgetPassword] = useState();
  const navigate = useNavigate();

  const closeOtpHandleModal = () => {
    setForgetModel(false);
    setOtpExpire(initialValue);
  };
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setForgetModel(false);
  });
  //user input otp will be store in a state otpField
  const handleOtp = (e, index) => {
    if (isNaN(e.target.value)) return false;
    setOtpField([
      ...otpField.map((d, idx) => (idx === index ? e.target.value : d)),
    ]);
    if (e.target.nextSibling && e.target.value !== "") {
      e.target.nextSibling.select();
    }
  };
  // In order to move the cursor backward in the OTP field
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      if (e.target.previousSibling) {
        e.target.previousSibling.select();
      }
    }
  };
  const openPasswordPage = () => {
    navigate("/createPassword", {
      state: forgetPasswordToken,
    });
  };
  const handleVerifyOtp = () => {
    const query = `  mutation{
      verifyHospital(verifyToken:"${forgetPasswordToken}"
      otp:"${otpField.join("")}"
      )
    }`;
    graphqlRequest(query).then((response) => {
      console.log("1-->",response)
      if (response?.data?.verifyHospital) {
        
        setSuccessForgetToken(response?.data?.verifyHospital);
        message.success("verified successfully");
        setForgetModel(false);

        // setNewForgetPassword(true)
        // openPasswordPage()


        setTabValue(3);
        setLoginMethod("setnewpassword")
      } else {
        message.error("Wrong OTP! Please enter correct OTP");
      }
    });
  };
  function callResendOtp() {
    setOtpExpire(initialValue);
    handleResendForgetOtp();
    setOtpField(new Array(4).fill(""));
    setInvalidOtp(false);
    message.success("OTP Re-sent Please check your Email Id");
  }

  useEffect(() => {
    let timer;
    if (true) {
      timer =
        otpExpire > 0 && setInterval(() => setOtpExpire(otpExpire - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [forgetModel, otpExpire]);

  return (
    <>
      <div>
        {/* <Dialog
        open={forgetModel}
        onClose={closeOtpHandleModal}
        className="webOTP"
      > */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="reset-webOTP">
            <div className="reset-otp-container">
              <div className="reset-otp-top">
                <div className="reset-otp-top-closeicon">
                  {/* <CloseOutlinedIcon/ className="reset-otpcloseIcon"> */}
                </div>
                <div className="reset-otp-top-otplabel">
                  <div>
                    <h3 className="reset-otp-top-heading">Verify Email Id</h3>
                  </div>
                  <span className="reset-otp-top-info">
                    {` Please enter the 4 digit code received on your registered email
                    ${forgetEmailOrPassword
                      .split("")
                      .map((data, index) => (index > 4 ? data : "*"))
                      .join("")} to reset password.`}
                  </span>
                </div>
              </div>
              <div className="reset-otp-center">
                <div className="reset-otp-input-box-container">
                  {otpField?.map((data, index) => {
                    return (
                      <input
                        onInput={(e) => handleOtp(e, index)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        type="tel"
                        name="otp"
                        // key={index}
                        maxLength={1}
                        // value={data}
                        onFocus={(e) => e.target.select()}
                        className={
                          !invalidOtp
                            ? "reset-otp-input-box"
                            : "reset-otp-input-box error"
                        }
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "10px auto",
                  }}
                >
                  {invalidOtp ? (
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      className="resend"
                    >
                      Incorrect OTP.
                      <strong
                        style={{
                          fontWeight: "700",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                        onClick={callResendOtp}
                        className="resend"
                      >
                        Resend OTP
                      </strong>
                    </span>
                  ) : otpExpire == 0 ? (
                    <span className="resend">
                      Didn’t receive the OTP?{" "}
                      <strong
                        style={{ fontWeight: "500", cursor: "pointer" }}
                        onClick={callResendOtp}
                      >
                        Resend OTP
                      </strong>
                    </span>
                  ) : (
                    <p className="resend" style={{ margin: 0 }}>
                      Resend OTP in :{" "}
                      <span>
                        {otpExpire < 10 ? "0" + otpExpire : otpExpire}
                      </span>
                      sec
                    </p>
                  )}
                </div>

                {/* <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "flex-start",
                margin: "10px auto",
              }}
            >
              {otpExpire < 1 ? (
                <span
                  className="resend"
                  onClick={callResendOtp}
                  style={{ cursor: "pointer" }}
                >
                  Resend OTP{" "}
                </span>
              ) : (
                <p className="resend">
                  {" "}
                  Resend OTP in <span>{otpExpire}</span> sec
                </p>
              )}
            </div> */}
                <div
                  style={{
                    margin: "2rem 0",
                    width: "85%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleVerifyOtp}
                    variant="contained"
                    disabled={
                      otpField[0] && otpField[1] && otpField[2] && otpField[3]
                        ? false
                        : true
                    }
                  >
                    {isFatching ? <CircularProgress /> : "Verify"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Dialog> */}
      </div>
      {/* <CreateNewPassword
    setNewForgetPassword={setNewForgetPassword}
    newForgetPassword={newForgetPassword}/> */}
    </>
  );
};

export default ForgetPassword;
