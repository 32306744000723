import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { makeStyles, withStyles } from "@mui/styles";
import { message } from "../SnakeAlert/SnakeAlert";
// import { withStyles } from "@material-ui/core/styles";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const DAYS = [
  {
    key: 0,
    label: "S",
  },
  {
    key: 1,
    label: "M",
  },
  {
    key: 2,
    label: "T",
  },
  {
    key: 3,
    label: "W",
  },
  {
    key: 4,
    label: "T",
  },
  {
    key: 5,
    label: "F",
  },
  {
    key: 6,
    label: "S",
  },
];

const ToggleDays = ({ weekdays, setWeekDays, jobDetailsError }) => {
  //   const [weekdays, setWeekDays] = useState([]);

  return (
    <>
      <ToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={weekdays}
        onChange={(event, value) => setWeekDays(value)}
      >
        {DAYS.map((day, index) => (
          <ToggleButton
            onBlur={() => {
              if (jobDetailsError.weekdays) {
                message.error(jobDetailsError.weekdays);
              }
            }}
            key={day.key}
            value={day.key}
            aria-label={day.key}
            sx={{
              margin: "0.8rem",
              border: "1px solid lightgrey !important",
              borderRadius: "2rem !important",
              width: "2.2rem",
              height: "2rem",
              "&.Mui-selected, &.Mui-selected:hover": {
                color: "white",
                backgroundColor: "#9ae0ca",
              },
            }}
          >
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default ToggleDays;
