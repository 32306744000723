// import React, { useState } from 'react';
// import graphqlRequest from "../../requestMethod";
// import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
// // import './DeleteUserAccount.css';

// function DeleteAccountPage() {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [verifyToken, setVerifyToken] = useState(''); // Make sure verifyToken is set before using it
//   const [error, setError] = useState(null);

//   const handleSendOtp = async () => {
//     try {
//     //   const adminToken = localStorage.getItem("adminToken");
//     //   const query = `
//     //     mutation {
//     //       sendOtp(input: { token: "${adminToken}", phoneNumber: "${phoneNumber}" }) {
//     //         success
//     //         message
//     //         verifyToken
//     //       }
//     //     }
//     //   `;
//     const query = `
//     query{
//         sendVerificationOtp(emailOrPhone:"${phoneNumber}")
//       }
//       `;

//       const response = await graphqlRequest(query);
//     //   console.log("31>>", response.data.sendVerificationOtp)

//       if (response.data.sendVerificationOtp) {
//         message.success('OTP sent successfully!');
//         setVerifyToken(response.data.sendVerificationOtp);
//       } else {
//         setError(response || 'Failed to send OTP');
//       }
//     } catch (error) {
//       console.error("Error sending GraphQL request:", error);
//       setError("Failed to send OTP. Please try again.");
//     }
//   };

// //   console.log("45>>", verifyToken)
//   const handleDeleteAccount = async () => {
//     try {
//       const query = `
//         mutation {
//             verifyUserDeletion(verifyToken: "${verifyToken}", otp: "${otp}")
//           }
//       `;
  
//       const response = await graphqlRequest(query);
//     //   console.log("55>>", response)
  
//       if (response.data.verifyUserDeletion) {
//         const deletionMessage = response.data.verifyUserDeletion;
//         console.log("Account deleted successfully!");
//         message.success(`${deletionMessage}`);
  
//       } else {
//         // The OTP verification failed
//         setError(response || 'Failed to verify OTP and delete account');
//       }
//     } catch (error) {
//       console.error("Error sending GraphQL request:", error);
//       setError("Failed to verify OTP and delete account. Please try again.");
//     }
//   };
  

//   return (
//     <div>
//       <h1>Delete Account</h1>
//       <label>
//         Phone Number:
//         <input
//           type="tel"
//           value={phoneNumber}
//           onChange={(e) => setPhoneNumber(e.target.value)}
//         />
//       </label>
//       {!verifyToken ? (
//         <button onClick={handleSendOtp}>Send OTP</button>
//       ) : (
//         <>
//           <label>
//             OTP:
//             <input
//               type="text"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//             />
//           </label>
//           <button onClick={handleDeleteAccount}>Delete Account</button>
//         </>
//       )}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// }

// export default DeleteAccountPage;

import React, { useState } from 'react';
import { TextField, Button, Typography, createTheme, ThemeProvider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import graphqlRequest from "../../requestMethod";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import Logo from "../../asset/navbar/logo.svg";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    margin: 'auto',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '100px', 
    marginBottom: '16px',
  },
  title: {
    marginBottom: '16px',
    color: theme.palette.primary.main,
  },
  input: {
    width: '100%',
    marginBottom: '16px',
  },
  button: {
    width: '50%',
    marginTop: '16px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    color: theme.palette.error.main,
    marginTop: '16px',
  },
});

function DeleteAccountPage() {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verifyToken, setVerifyToken] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  
  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^\d]/g, '').slice(0, 10);

    setPhoneNumber(sanitizedValue);
    if (sanitizedValue === '' || sanitizedValue.length < 10) {
      setVerifyToken('');
      setOtp('')
      setError("");
      // setError("Please enter a valid 10-digit mobile number.");
      // setTimeout(() => {
      //   setError(null);
      // }, 1000);
    }
  
  };

  const handleOtpChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^\d]/g, '').slice(0, 4);

    setOtp(sanitizedValue);
  };

  const handleSendOtp = async () => {
    try {
      if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
        setError("Please enter a valid 10-digit mobile number.");

        setTimeout(() => {
          setError(null);
        }, 2000);

        return;
      }

      const query = `
        query {
          sendVerificationOtp(emailOrPhone: "${phoneNumber}")
        }
      `;

      const response = await graphqlRequest(query);
      // console.log("1-->",response)
      if (response?.errors?.[0]?.message === "Check your credentials!") {
        message.error("Phone number is not registered with us!");
      }else {
        message.success('OTP sent successfully!');
        setVerifyToken(response.data.sendVerificationOtp);
      }
      //  else {
      //   setError(response || 'Failed to send OTP');
      // }
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
      setError("Failed to send OTP. Please try again.");
    }
  };

  const handleDeleteAccount = async () => {
    try {
      if (!otp || !/^\d{4}$/.test(otp)) {
        setError("Please enter the OTP before deleting the account.");
        setTimeout(() => {
          setError(null);
        }, 2000);

        return;
      }
      const query = `
        mutation {
          verifyUserDeletion(verifyToken: "${verifyToken}", otp: "${otp}")
        }
      `;
  
      const response = await graphqlRequest(query);
        // console.log("2-->", response);
        if (response?.errors?.[0]?.message === "Invalid OTP") {
          message.error("Please enter a valid OTP");
        }else{
        const deletionMessage = response.data.verifyUserDeletion;
        // console.log("Account deleted successfully!");
        // navigate("/login");
        message.success(`${deletionMessage}`);
        window.location.reload();
  
      }
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
      setError("Failed to verify OTP and delete account. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
      <img src={Logo} alt="Logo" className={classes.logo} />
        <Typography variant="h4" className={classes.title} gutterBottom>
          Delete Account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.input}
              label="Enter Phone Number exluding(0 and +91)"
              type="tel"
              value={phoneNumber}
              // onChange={(e) => setPhoneNumber(e.target.value)}
              onChange={handlePhoneNumberChange}
              maxLength={10}
            />
          </Grid>
          {!verifyToken ? (
            <Grid item xs={12} className={classes.center}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSendOtp}
              >
                Send OTP
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  className={classes.input}
                  label="Enter OTP"
                  type="text"
                  value={otp}
                  // onChange={(e) => setOtp(e.target.value)}
                  onChange={handleOtpChange}
                  maxLength={4}
                />
              </Grid>
              <Grid item xs={12} className={classes.center}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteAccount}
                >
                  Delete Account
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {error && <p className={classes.error}>{error}</p>}
      </div>
    </ThemeProvider>
  );
}

export default DeleteAccountPage;



