import React from "react";
import "./ShortStory.scss";
import one from "../../../asset/dashboard/ssone.svg";
import { Link } from "react-router-dom";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

function ShortStory({ data }) {
  let url = "https://www.google.com/";

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackShortStoryImage = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.CREATIVE
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="slider-container">
      <div className="slider-wrapper">
        <div className="ss-details" id="creative">
          <h3>{data?.eventName}</h3>
          {/* <p>{data?.description}</p> */}
        </div>
        <div className="ss-image-container" id="creative">
          {/* <a href={data?.hyperLink} target="_blank" rel="noreferrer"> */}
          {/* <Link to={`//${data?.hyperLink}`} target="_blank"> */}
          <Link
            to={
              data?.hyperLink
                ? data?.hyperLink?.includes("http")
                  ? `//${data?.hyperLink?.split("//")[1]}`
                  : `//${data?.hyperLink}`
                : null
            }
            target={data?.hyperLink ? "_blank" : null}
          >
            <img
              src={data?.files[0]?.url}
              atl="bg-image"
              style={{ height: "10rem", objectFit: "contain" }}
              onClick={trackShortStoryImage}
            />
            {/* </a> */}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ShortStory;
