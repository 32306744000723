import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import "./PhoneEmailOtp.scss";
import Button from "@mui/material/Button";
import graphqlRequest from "../../../../requestMethod";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./PhoneEmailOtp.scss";
import { message } from "../../../Common/SnakeAlert/SnakeAlert";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import eventHelpers from "../../../../Helpers/Event";

let initialValue = 60;

const PhoneEmailOtp = ({
  setOpenOtpHandler,
  openOtpHandler,
  verifyMode,
  token,
  handleResendOtp,
  userInput,
}) => {
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otpField, setOtpField] = useState(new Array(4).fill(""));
  const [isFatching, setIsFatching] = useState(false);
  const [otpExpire, setOtpExpire] = useState(initialValue);
  const navigate = useNavigate();

  const closeOtpHandleModal = () => {
    setOpenOtpHandler(false);
  };

  //user input otp will be store in a state otpField
  const handleOtp = (e, index) => {
    if (isNaN(e.target.value)) return false;
    setOtpField([
      ...otpField.map((d, idx) => (idx === index ? e.target.value : d)),
    ]);
    if (e.target.nextSibling && e.target.value !== "") {
      e.target.nextSibling.select();
    }
  };

  // In order to move the cursor backward in the OTP field
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      if (e.target.previousSibling) {
        e.target.previousSibling.select();
      }
    }
  };

  const handleVerifyOtp = () => {
    // setIsFatching(true);

    const query = `mutation{verifyLogin(verifyToken:"${token}",otp:"${otpField.join(
      ""
    )}"){accessToken,refreshToken}}`;

    graphqlRequest(query).then((response) => {
      if (!response?.errors) {
        setIsFatching(false);
        if (response?.errors) {
          setIsFatching(false);
        } else {
          setIsFatching(false);
          window.dataLayer.push({event:"OTP_Verfied"})
          localStorage.setItem(
            "auth",
            JSON.stringify(response.data.verifyLogin)
          );
          navigate("/");
          message.success("Login Successfull!");
       
        }
      } else {
        // setInvalidOtp(true)
        setIsFatching(false);
        message.error("Wrong OTP! Please enter correct OTP ");
      }
    });
  };
  function callResendOtp() {
    setOtpExpire(initialValue);
    handleResendOtp();
    setOtpField(new Array(4).fill(""));
    setInvalidOtp(false);
    message.success("OTP Re-sent Please check your Email Id/Phone");
  }

  useEffect(() => {
    let timer;
    if (true) {
      timer =
        otpExpire > 0 && setInterval(() => setOtpExpire(otpExpire - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [openOtpHandler, otpExpire]);

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackUserVerifyOTP = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      // console.log("12345678i")
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.OTP_VERFIED
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  const trackUserResendOTP = async () => {
    // const user = await fetchUserId();
    // console.log("user---->", user);
    // const userId = user.data.withAuth.userDetails.id;
    // const userName = user.data.withAuth.userDetails.name.full;
    // const userEmail = user.data.withAuth.userDetails.emails[0].address;
    // const timestamp = new Date().getTime();
    // const randomString = Math.random().toString(36).substring(2, 12);
    // const uniqueId = `${timestamp}${randomString}`;
    if (window.Moengage) {
      // console.log("12345678i")
      //set unique id
      // window.Moengage.add_unique_user_id(uniqueId);
      //set user attributes
      // window.Moengage.add_user_name(userName);
      // window.Moengage.add_email(userEmail);
      //set event data
      window.Moengage.track_event(
        eventHelpers.RSND_OTP
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <>
      <Dialog
        open={openOtpHandler}
        // onClose={closeOtpHandleModal}
        className="webOTP"
      >
        <div className="w-otp-container">
          <div className="w-otp-top">
            <div className="w-otp-top-closeicon">
              <HighlightOffRoundedIcon
                onClick={closeOtpHandleModal}
                sx={{ position: "relative", right: "1rem", cursor: "pointer" }}
              />

              {/* <CloseOutlinedIcon/ className="w-otpcloseIcon"> */}
            </div>
            <div className="w-otp-top-otplabel">
              <h3 className="w-otp-top-heading">
                Verify Phone number or email id
              </h3>
              <span className="w-otp-top-info">
                {` Please enter the 4 digit code received on your registered Email/Phone ${userInput}. `}
              </span>
            </div>
          </div>
          <div className="w-otp-center">
            <div className="w-otp-input-box-container">
              {otpField?.map((data, index) => {
                return (
                  <input
                    onInput={(e) => handleOtp(e, index)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    type="tel"
                    name="otp"
                    // key={index}
                    maxLength={1}
                    // value={data}
                    onFocus={(e) => e.target.select()}
                    className={
                      !invalidOtp ? "w-otp-input-box" : "w-otp-input-box"
                    }
                  />
                );
              })}
            </div>
            <div
              // style={{
              //   width: "70%",
              //   display: "flex",
              //   justifyContent: "flex-start",
              //   margin: "10px auto",
              // }}
              className="resent-timer"
            >
              {invalidOtp ? (
                <span style={{ cursor: "pointer" }} className="resend">
                  Incorrect OTP.
                  <strong
                    style={{ fontWeight: "500", cursor: "pointer" }}
                    onClick={() => {
                      callResendOtp();
                      trackUserResendOTP();
                    }}
                    className="resend"
                  >
                    Resend OTP
                  </strong>
                </span>
              ) : otpExpire == 0 ? (
                <span className="resend">
                  Didn’t receive the OTP?{" "}
                  <strong
                    style={{ fontWeight: "500", cursor: "pointer" }}
                    onClick={() => {
                      callResendOtp();
                      trackUserResendOTP();
                    }}
                  >
                    Resend OTP
                  </strong>
                </span>
              ) : (
                <p className="resend" style={{ margin: 0 }}>
                  Resend OTP in :{" "}
                  <span>{otpExpire < 10 ? "0" + otpExpire : otpExpire}</span>
                  sec
                </p>
              )}
            </div>

            {/* <div
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "flex-start",
                margin: "10px auto",
              }}
            >
              {otpExpire < 1 ? (
                <span
                  className="resend"
                  onClick={callResendOtp}
                  style={{ cursor: "pointer" }}
                >
                  Resend OTP{" "}
                </span>
              ) : (
                <p className="resend">
                  {" "}
                  Resend OTP in <span>{otpExpire}</span> sec
                </p>
              )}
            </div> */}
            <div
              // style={{
              //   margin: "2rem 0",
              //   width: "85%",
              //   display: "flex",
              //   justifyContent: "flex-end",
              // }}
              className="bottom-verify-button"
            >
              <Button
                onClick={() => {
                  handleVerifyOtp();
                  trackUserVerifyOTP();
                }}
                variant="contained"
                disabled={
                  otpField[0] && otpField[1] && otpField[2] && otpField[3]
                    ? false
                    : true
                }
              >
                {isFatching ? <CircularProgress /> : "Verify"}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PhoneEmailOtp;
