import React from "react";
import "./DoYouKnow.scss";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";
function DoYouKnow({ data }) {
  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackDidYouKnow = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DID_U_KNW
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="dyks-container">
      <div onMouseOver={trackDidYouKnow}>
        <h3>{data.head}</h3>
        <p>{data.body}</p>
      </div>
    </div>
  );
}

export default DoYouKnow;
