import { React, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import useOnClickOutside from "../../Common/UseOnClickOutside/UseOnClickOutside";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useLocation } from "react-router";
import graphqlRequest from "../../../requestMethod";
import { message } from "../../Common/SnakeAlert/SnakeAlert";

const CreateNewPassword = ({ newForgetPassword, setNewForgetPassword }) => {
  const [userInput, setUserInput] = useState();
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setNewForgetPassword(false);
  });

  const location = useLocation();
  console.log("locationttt", location);
  const handleVerifyPassword = () => {
    const query = `mutation {
      resetPassword(
          resetToken: "${location?.state}",
          password: "123@hhh"
      )
  }
    }`;
    graphqlRequest(query).then((response) => {
      console.log(response)
      // if (response?.data?.verify) {
      //   message.success("verified successfully");
      //   // setNewForgetPassword(true)
      //   // openPasswordPage()
      // } else {
      //   message.error("Invallid Credentials");
      // }
    });
  };
  return (
    <
      // open={newForgetPassword}
      // onClose={closeOtpHandleModal}
      // className="webOTP"
    >
      <div ref={ref}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div>
            <p>New Password</p>

            <TextField
              placeholder="New Password"
              fullWidth
              onChange={(e) => setUserInput(e.target.value)}
            />
          </div>
          <div>
            <p>Confirm New Password</p>
            <TextField
              placeholder="Confirm Password"
              fullWidth
              // onChange={(e) => setForgetEmailOrPassword(e.target.value)}
            />
          </div>
          <div className="input-otp-fields">
            <Button
              variant="contained"
              fullWidth
              onClick={handleVerifyPassword}>
              Submit
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default CreateNewPassword;
