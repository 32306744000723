import moment from "moment";
import React from "react";
import "./Slote.scss";
function Slote({ data, startTime, endTime }) {
  let realData1 = data;
  let realdata2 = data;
  let realdata3 = data;

  const realDate =
    moment(realData1).format("LLL").split(",")[0].split(" ")[1] +
    " " +
    moment(realdata2).format("LLL").split(",")[0].split(" ")[0] +
    " " +
    moment(realdata3).format("LLL").split(" ")[2];


  function formatTime(timestamp) {
    const localTime = new Date(timestamp);
    let hours = localTime.getHours();
    const minutes = localTime.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const timeString = `${
      hours > 12 ? hours - 12 : hours === 0 ? 12 : hours
    }:${minutes.toString().padStart(2, "0")} ${amPm}`;
    return timeString;
  }
  let start = formatTime(startTime);
  let end = formatTime(endTime);
  return (
    <div className="slote-container">
      <div className="slote-wrapper">
        <h3>{realDate}</h3>
        <p>
          {start} - {end}
        </p>
      </div>
    </div>
  );
}

export default Slote;
