import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import Profile from "../../components/Profile/Profile";
import ProfileDetails from "../../components/ProfileDetails/ProfileDetails";
import "./Recommendation.scss";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/userReducer";

function Recommendation(props) {
  let {
    recommendedUsers,
    recommendedUserAbsolute,
    userSelectedProfile,
    userJobPostDetails,
  } = useSelector((state) => state.user);

 
  let dispatch = useDispatch();
  let [openProfileView, setOpenProfileView] = useState(
    Object.keys(userSelectedProfile)?.length > 1
  );
  const [hightLighted, setHighLighted] = useState();
  let [showProfileData, setShowProfileData] = useState(userSelectedProfile);

  function showProfile(data) {
    dispatch(setProfile(data));
    setOpenProfileView(true);
    setShowProfileData(data);
  }


  // console.log("28reco>>>", recommendedUsers);
  // console.log("29reco>>>", recommendedUserAbsolute)
  return (
    <div className="recommendation-wrapper">
      <div className="recommendation-container">
        <JobsHeader title={"Recommendation"} />
        <BreadCrumb action={"Profile"} title={"Recommendation"} />
        {/* <div className="recommendation-profiles">
          <div className="show-profile">
            {absoluteData?.map((data) => {
              return (
                <div
                  onClick={() => {
                    showProfile(data);
                    setHighLighted(data?.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Profile data={data} hightLighted={hightLighted} absoluteData={absoluteData} />
                </div>
              );
            })}
          </div>

          <motion.div
            className="show-profile-details"
            animate={{
              x: openProfileView ? 0 : "100vw",
            }}
            transition={{
              duration: 0.5,
            }}
            style={{ display: openProfileView ? "" : "none" }}
          >
            <ProfileDetails
              showProfileData={showProfileData}
              setOpenProfileView={setOpenProfileView}
              absoluteData={absoluteData}
            />
          </motion.div>
        </div> */}

        {/* //2 */}
        <div className="recommendation-profiles">
          <div className="show-profile">
          {recommendedUserAbsolute.slice(0, 5).map((data) => (
              <div
                key={data.id}
                onClick={() => {
                  showProfile(data);
                  setHighLighted(data.id);
                }}
                style={{ cursor: "pointer" }}
              >
                <Profile
                  data={data}
                  hightLighted={hightLighted}
                  recommendedUserAbsolute={recommendedUserAbsolute}
                />
              </div>
            ))}
            {recommendedUserAbsolute.length < 5 &&
              recommendedUsers?.slice(0, 5 - recommendedUserAbsolute.length).map((data) => (
                <div
                  key={data.id}
                  onClick={() => {
                    showProfile(data);
                    setHighLighted(data.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Profile
                    data={data}
                    hightLighted={hightLighted}
                    recommendedUserAbsolute={recommendedUserAbsolute}
                  />
                </div>
              ))}
            {/* {recommendedUsers?.map((data) => {
              return (
                <div
                  onClick={() => {
                    showProfile(data);
                    setHighLighted(data?.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Profile data={data} hightLighted={hightLighted} absoluteData={absoluteData} />
                </div>
              );
            })} */}
          </div>

          <motion.div
            className="show-profile-details"
            animate={{
              x: openProfileView ? 0 : "100vw",
            }}
            transition={{
              duration: 0.5,
            }}
            style={{ display: openProfileView ? "" : "none" }}
          >
            <ProfileDetails
              showProfileData={showProfileData}
              setOpenProfileView={setOpenProfileView}
              recommendedUserAbsolute={recommendedUserAbsolute}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Recommendation;
