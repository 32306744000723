import React, { useRef } from "react";
import "./Dashboard.scss";

import activeArrow from "../../asset/dashboard/activearrow.svg";
import doctorone from "../../asset/dashboard/doctor.avif";
import doctorone1 from "../../asset/dashboard/doctor1.png";
import leftarrow from "../../asset/dashboard/leftarrow.svg";
import rightarrow from "../../asset/dashboard/rightarrow.svg";
import unactiveArrow from "../../asset/dashboard/unactivearrow.svg";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import JobInfo from "../../components/Dashboard/JobInfo/JobInfo";
import JobPost from "../../components/Dashboard/JobPost/JobPost";
import ShortStory from "../../components/Dashboard/ShortStory/ShortStory";

import { Navigation } from "swiper";

import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import "swiper/css/navigation";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/scrollbar";
import DoYouKnow from "../../components/Dashboard/DoYouKnow/DoYouKnow";
import RangeChart from "../../components/Dashboard/RangeChart/RangeChart";
import graphqlRequest from "../../requestMethod";

import { Button, Modal } from "@mui/material";
import moment from "moment";
import eventHelpers from "../../Helpers/Event";
import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";

//test
function Dashboard(props) {
  console.log("Pipeline Checking");
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [shortReads, setShortReads] = useState([]);
  const [didYouKnowData, setDidYouKnow] = useState([]);

  const [userData, setUserData] = useState();

  const [userCardData, setUserCardData] = useState();
  const [value, setValue] = useState();

  const swiper = useSwiper();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [dashboardData, setDashboardData] = useState(userCardData);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const [filterDate, setFilterDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedFilter, setSelectedFilter] = useState("");

  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  let fetchUpcomingEventDetails = () => {
    const query = `
    {
  getUpcomingEvents(id: "${
    userData?.userDetails?.id ? userData?.userDetails?.id : " "
  }") {
    files {
     
      url
      }
    id
    eventName
    
    hyperLink
    
  }
}
   
    `;

    graphqlRequest(query).then((response) => {
      // setUserData(response?.data?.withAuth);

      setShortReads(response?.data?.getUpcomingEvents);
      setDidYouKnow([
        // {
        //   id: 1,
        //   head: "What is Locum?",
        //   body: "The locum economy uses digital platforms to connect freelancers with customers to provide short-term services or asset-sharing.",
        // },
        // {
        //   id: 2,
        //   head: "Did You Know?",
        //   body: "The result of a locum economy produces more efficient, and flexible services that can offer consumers a unique and fast alternative to regular, standardized business. In addition, it is solely operated through the internet, making it an extremely popular choice for the younger demographic.",
        // },
        {
          id: 1,
          head: "Jobizo Milestones",
          body: "Awarded ET “Startup of the Year”",
        },
        {
          id: 2,
          head: "Jobizo Milestones",
          body: "First HR Tech Platform for the Healthcare Industry.",
        },
        {
          id: 3,
          head: "Jobizo Milestones",
          body: "WhatsApp Chatbot facilitating job postings on the go.",
        },
        {
          id: 4,
          head: "Jobizo Milestones",
          body: "Total Patient Care Hours Fulfilled: 5,00,000+ Hours",
        },
        {
          id: 5,
          head: "Jobizo Milestones",
          body: "Total Jobs Fulfilled Till Date: 10,300+ Jobs",
        },

      ]);

      // if (response?.errors) {
      //   message?.error("Already invited!");
      // }
      // else if (response?.data?.withAuth?.inviteUser) {
      //   message?.success("Email has been sent");
      // }
    });
  };

  let fetchUserDetails = () => {
    const query = `
    query {
      withAuth(token: "${token}") {
          userDetails {
              id
              name{
                first
                full
              }
              organizations{
                organization{
                  id
                  companies{
                    id
                    name
                    logo{
                      url
                    }
                  }
                  name
                  
                }
                role{
                  id
                  name
                }
              }
          }
      }   
  }
    `;

    graphqlRequest(query).then((response) => {
      setUserData(response?.data?.withAuth);
      // if (response?.errors) {
      //   message?.error("Already invited!");
      // }
      // else if (response?.data?.withAuth?.inviteUser) {
      //   message?.success("Email has been sent");
      // }
    });
  };

  // ( startTime: "1680005047501", endTime: "1680004971780")
  // ( startTime: "${value}", endTime: "${value}")

  const currentDate = new Date(); // Today's date
const last12MonthsDate = new Date(); // Date 12 months ago
last12MonthsDate.setMonth(last12MonthsDate.getMonth() - 12);

const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"
const formattedLast12MonthsDate = last12MonthsDate.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"


  const [checkInDate, setCheckInDate] = useState(formattedLast12MonthsDate);
  const [checkOutDate, setCheckOutDate] = useState(formattedCurrentDate);
  let [openRangeCalendar, setOpenRangeCalendar] = useState(false);

  const [graphTitle, setGraphTitle] = useState("");
  const [categoriesData, setCategoriesData] = useState([ 
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);

  const [checkInMonth, setCheckInMonth] = useState(0);
  const [checkOutMonth, setCheckOutMonth] = useState(11);

  let monthsArray = [
    {
      key: "Jan",
      value: "0",
    },
    {
      key: "Feb",
      value: "1",
    },
    {
      key: "Mar",
      value: "2",
    },
    {
      key: "Apr",
      value: "3",
    },
    {
      key: "May",
      value: "4",
    },
    {
      key: "Jun",
      value: "5",
    },
    {
      key: "Jul",
      value: "6",
    },
    {
      key: "Aug",
      value: "7",
    },
    {
      key: "Sep",
      value: "8",
    },
    {
      key: "Oct",
      value: "9",
    },
    {
      key: "Nov",
      value: "10",
    },
    {
      key: "Dec",
      value: "11",
    },
  ];
  //

  const handleMonthArray = () => {
    let numarr = [];
    let arrmonth = [];

    if (checkInMonth < checkOutMonth) {
      for (let i = checkInMonth; i <= checkOutMonth; i++) {
        numarr.push(i);
        monthsArray.map((obj) => {
          if (obj.value == i) {
            arrmonth.push(obj.key);
          }
        });
      }
      setCategoriesData(arrmonth);
    } else if (checkInMonth == checkOutMonth) {
      for (let i = checkInMonth; i <= checkOutMonth + 4; i++) {
        numarr.push(i);
        monthsArray.map((obj) => {
          if (obj.value == i) {
            arrmonth.push(obj.key);
          }
        });
      }
      setCategoriesData(arrmonth);
    }
  };

  //FILTER TYPE
  const [filterType, setFilterType] = useState("");
  const [apiFilterType, setApiFilterType] = useState("MONTH");

  //Handle Date Change
  const handleDateChange = (dateValues) => {
    setValue(dateValues);

    let checkIn = dateValues[0].startDate;
    let checkOut = dateValues[0].endDate;

    let checkInMonthIndex = moment(checkIn).month();

    let checkOutMonthIndex = moment(checkOut).month();

    setCheckInMonth(checkInMonthIndex);
    setCheckOutMonth(checkOutMonthIndex);

    setCheckInDate(moment(checkIn).format("YYYY-MM-DD"));
    setCheckOutDate(moment(checkOut).format("YYYY-MM-DD"));
    // setDatePickerOpen(!datePickerOpen);
    // setIsSidebarOpen(!isSidebarOpen);
  };

  let fetchUserDataFunction = () => {
    const query = `
query {
  withAuth(token: "${token}"){
    listJobCounts( startTime: "${checkInDate}", endTime: "${checkOutDate}", type:${apiFilterType}) {
      locumJobs
      permanentJob
      activeLocumJobs
      interviewScheduled
      locumJobsTotal
      permanentJobTotal
      interviewScheduledTotal
      activeLocumJobsTotal
      totallocumJobs
      totalPermanentJobs
      totalInterviewScheduled
      totalActiveLocumJob
      yesterdayPostedJobs
      last12MonthJobs
      activeOpenLocumJobs
      locumOngoingJobs
    }
  }
}
     `;
    graphqlRequest(query).then((response) => {
      // console.log("response: ", response.data.withAuth);
      if (response?.data?.withAuth?.listJobCounts) {
        // message.success("Data get successfully")
        setUserCardData(response?.data?.withAuth?.listJobCounts);
      }
    });
  };

  //Get Start Or End Date of Month
  function getMonthDate(date, days) {
    return new Date(date.getFullYear(), date.getMonth(), days);
  }

  // Get Num Of Weeks In a Month
  function getNumWeeksForMonth(year, month) {
    let date = new Date(year, month, 1);
    let day = date.getDay();
    let numDaysInMonth = new Date(year, month, 0).getDate();
    return Math.ceil((numDaysInMonth + day) / 7);
  }

  //MONTH FILTER HANDLER
  const handleMonthFilter = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFilterType("MONTH");
    setApiFilterType("WEEK");
    let daysInMonth = moment(new Date()).daysInMonth();
    let startDate = moment(getMonthDate(new Date(), 1)).format("YYYY-MM-DD");
    let endDate = moment(getMonthDate(new Date(), daysInMonth)).format(
      "YYYY-MM-DD"
    );
    let numOfWeeks = getNumWeeksForMonth(
      moment(new Date()).month(),
      moment(new Date()).year()
    );

    if (numOfWeeks > 1) {
      let weekArr = [];
      for (let i = 1; i <= numOfWeeks; i++) {
        weekArr.push(`Week ${i}`);
      }
      setCategoriesData(weekArr);
      setCheckInDate(startDate);
      setCheckOutDate(endDate);
      setGraphTitle("Job Trends By Month");
    }
  };

  //Year Filter Handler
  const handleYearFilter = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFilterType("YEAR");
    setApiFilterType("MONTH");
    var startYear = moment(new Date(new Date().getFullYear(), 0, 1)).format(
      "YYYY-MM-DD"
    );
    var endYear = moment(new Date(new Date().getFullYear(), 11, 31)).format(
      "YYYY-MM-DD"
    );

    setCheckInDate(startYear);
    setCheckOutDate(endYear);
  };

  //Fortnight Filter Handler
  const handleFortnightFilter = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFilterType("FORTNIGHT");
    setApiFilterType("DAY");
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date())
      .subtract("15", "days")
      .format("YYYY-MM-DD");
    setCheckInDate(endDate);
    setCheckOutDate(startDate);

    setCategoriesData([
      `${
        moment(endDate).format("L").split("/")[1] +
        "/" +
        moment(endDate).format("L").split("/")[0]
      } - ${
        moment(endDate).add(3, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(3, "days").format("L").split("/")[0]
      }`,
      `${
        moment(endDate).add(3, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(3, "days").format("L").split("/")[0]
      } - ${
        moment(endDate).add(6, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(6, "days").format("L").split("/")[0]
      }`,
      `${
        moment(endDate).add(6, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(6, "days").format("L").split("/")[0]
      } - ${
        moment(endDate).add(9, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(9, "days").format("L").split("/")[0]
      }`,
      `${
        moment(endDate).add(9, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(9, "days").format("L").split("/")[0]
      } - ${
        moment(endDate).add(12, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(12, "days").format("L").split("/")[0]
      }`,
      ` ${
        moment(endDate).add(12, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(12, "days").format("L").split("/")[0]
      } - ${
        moment(endDate).add(15, "days").format("L").split("/")[1] +
        "/" +
        moment(endDate).add(15, "days").format("L").split("/")[0]
      }`,
    ]);
  };

  //Fortnight Filter Handler
  const handleSevenDaysFilter = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFilterType("SEVENDAYS");
    setApiFilterType("DAY");
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date()).subtract("7", "days").format("YYYY-MM-DD");

    setCheckInDate(endDate);
    setCheckOutDate(startDate);

    setCategoriesData([
      `${moment(endDate).format("MMM Do")}`,
      `${moment(endDate).add(1, "days").format("MMM Do")}`,
      `${moment(endDate).add(2, "days").format("MMM Do")}`,
      `${moment(endDate).add(3, "days").format("MMM Do")}`,
      `${moment(endDate).add(4, "days").format("MMM Do")}`,
      `${moment(endDate).add(5, "days").format("MMM Do")}`,
      `${moment(endDate).add(6, "days").format("MMM Do")}`,
    ]);
  };

  useEffect(() => {
    fetchUserDetails();
    fetchUserDataFunction();
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    fetchUpcomingEventDetails();
    setDashboardData(userCardData);
    if (filterType == "SEVENDAYS") {
      setGraphTitle("Job Trends of Past 7 Days");
      setDashboardData(userCardData);
    }

    if (filterType == "FORTNIGHT") {
      let junction1 =
        userCardData?.locumJobsTotal[0] +
        userCardData?.locumJobsTotal[1] +
        userCardData?.locumJobsTotal[2];
      let junction2 =
        userCardData?.locumJobsTotal[3] +
        userCardData?.locumJobsTotal[4] +
        userCardData?.locumJobsTotal[5];
      let junction3 =
        userCardData?.locumJobsTotal[6] +
        userCardData?.locumJobsTotal[7] +
        userCardData?.locumJobsTotal[8];
      let junction4 =
        userCardData?.locumJobsTotal[9] +
        userCardData?.locumJobsTotal[10] +
        userCardData?.locumJobsTotal[11];
      let junction5 =
        userCardData?.locumJobsTotal[12] +
        userCardData?.locumJobsTotal[13] +
        userCardData?.locumJobsTotal[14];

      setGraphTitle("Job Trends of Fortnight");
      setDashboardData({
        ...userCardData,
        locumJobsTotal: [junction1, junction2, junction3, junction4, junction5],
      });
    }

    //MONTH FILTER
    if (filterType == "MONTH") {
      setDashboardData(userCardData);
    }
    //YEAR FILTER
    if (filterType == "YEAR") {
      const year = new Date().getFullYear();
      let Quarter1 =
        userCardData?.locumJobsTotal[0] +
        userCardData?.locumJobsTotal[1] +
        userCardData?.locumJobsTotal[2];
      let Quarter2 =
        userCardData?.locumJobsTotal[3] +
        userCardData?.locumJobsTotal[4] +
        userCardData?.locumJobsTotal[5];
      let Quarter3 =
        userCardData?.locumJobsTotal[6] +
        userCardData?.locumJobsTotal[7] +
        userCardData?.locumJobsTotal[8];
      let Quarter4 =
        userCardData?.locumJobsTotal[9] +
        userCardData?.locumJobsTotal[10] +
        userCardData?.locumJobsTotal[11];

      setCategoriesData(["Jan - Mar", "Apr - Jun", "Jul - Sept", "Oct - Dec"]);
      setGraphTitle(`Job Trends of a Year ${year}`);    

      setDashboardData({
        ...userCardData,
        locumJobsTotal: [Quarter1, Quarter2, Quarter3, Quarter4],
      });
    }
  }, [userCardData]);

  const resetFilters = () => {
    setIsSidebarOpen(!isSidebarOpen);
    window.location.reload();
    // setFilterType("");
    // setApiFilterType("");
    // setCheckInMonth(0);
    // setCheckOutMonth(11);
    // setGraphTitle("");
    // setCategoriesData([
    //   "Jan",
    //   "Feb",
    //   "Mar",
    //   "Apr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Aug",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dec",
    // ]);
    // fetchUserDetails();
    // fetchUserDataFunction();
  };

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackDashboardDown = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.PERCENT_SCROLLED
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="dashboard-wrapper">
      {width > 480 ? (
        <div className="dashboard-container">
          <div className="one">
            <div>
              <h3 className="greeting">Hello,</h3>
              <h3 className="user-name" style={{ marginLeft: "5px" }}>
                {userData?.userDetails?.name?.first}
              </h3>
            </div>

            <div
              className="date-container"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <FilterAltIcon
                sx={{
                  height: "15rem",
                  position: "relative",
                  right: "1rem",
                  cursor: "pointer",
                }}
              />
              <p
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginLeft: "-1rem",
                }}
              >
                Date Filter
              </p>
            </div>

            <Modal
              open={isSidebarOpen}
              onClose={() => {
                setIsSidebarOpen(!isSidebarOpen);
                setDatePickerOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  width: "200px",
                  height: "150px",
                  position: "fixed",
                  right: "20px",
                  top: "160px",
                  // borderRadius: "8px",
                  borderColor: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  backgroundColor: "white",
                  padding: "10px 1px 10px 1px",
                }}
              >
                <Button
                  onClick={handleSevenDaysFilter}
                  fullWidth
                  // variant="outlined"

                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "SEVENDAYS" ? "#1E86D8" : "#FFF",
                    color: filterType == "SEVENDAYS" ? "#FFF" : "#111",
                  }}
                >
                  Past 7 Days
                </Button>
                <Button
                  onClick={handleFortnightFilter}
                  fullWidth
                  // variant="outlined"
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "FORTNIGHT" ? "#1E86D8" : "#FFF",
                    color: filterType == "FORTNIGHT" ? "#FFF" : "#111",
                  }}
                >
                  Fortnight
                </Button>

                <Button
                  onClick={handleMonthFilter}
                  fullWidth
                  // variant="outlined"
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "MONTH" ? "#1E86D8" : "#FFF",
                    color: filterType == "MONTH" ? "#FFF" : "#111",
                  }}
                >
                  Month
                </Button>

                <Button
                  onClick={handleYearFilter}
                  // variant="outlined"
                  fullWidth
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "YEAR" ? "#1E86D8" : "#FFF",
                    color: filterType == "YEAR" ? "#FFF" : "#111",
                  }}
                >
                  Year
                </Button>

                <Button
                  onClick={resetFilters}
                  fullWidth
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: selectedFilter === "RESET" ? "#1E86D8" : "#FFF",
                    color: selectedFilter === "RESET" ? "#FFF" : "#111",
                  }}
                >
                  Reset Filters
                </Button>

                {/* <Button
                 onClick={() => setDatePickerOpen(!datePickerOpen)}
                 sx={{ color: "black", fontSize: "0.8rem" }}
               >
                 Custom Date
               </Button> */}
                {/* <label
                 style={{
                   display: "flex",
                   justifyContent: "space-between",
                   alignItems: "center",
                 }}
               >
                 <input
                   type="text"
                   placeholder="Search"
                   style={{ width: "8.5rem" }}
                   value={`${filterDate[0]?.startDate.toLocaleDateString()} - ${filterDate[0]?.endDate.toLocaleDateString()} `}
                 />
                 <div onClick={() => setOpenRangeCalendar(!openRangeCalendar)}>
                   <EventNoteIcon />
                 </div>
               </label>
               <Modal
                 open={openRangeCalendar}
                 onClose={() => setOpenRangeCalendar(!openRangeCalendar)}
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
               >
                 <>
                   <CalendarRange
                     setFilterDate={setFilterDate}
                     filterDate={filterDate}
                  
                   />
                   <Button
                     variant="contained"
                      onClick={() => {
                       setOpenRangeCalendar(!openRangeCalendar);                      
                       setIsSidebarOpen(!isSidebarOpen);
                       }}
                     sx={{ position: "relative", top: "87.8%", left: "61%" }}
                   >
                     OK
                   </Button>
                 </>
               </Modal> */}
              </div>
            </Modal>
          </div>
          <div className="two">
            {/* {jobInfo.map((data) => ( */}
            <JobInfo data={userCardData} />
            {/* ))} */}
          </div>
          <div className="three">
            <div className="activity-analytics">
              <RangeChart
                dashboardData={dashboardData}
                categoriesData={categoriesData}
                chartTitle={graphTitle}
              />
            </div>
            <div className="did-you-know">
              <div className="dyk-wrapper">
                {/* <img src={doctorone} alt="bg-doctor-img" />   */}
                <img src={doctorone1} alt="bg-doctor-img" />  
                <div className="slider-container-dyk">
                  <Swiper
                    // navigation={{
                    //   prevEl: navigationPrevRef.current,
                    //   nextEl: navigationNextRef.current,
                    // }}
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled",
                    }}
                    loop="true"
                    modules={[Navigation]}
                    // onBeforeInit={(swiper) => {
                    //   swiper.params.navigation.prevEl = navigationPrevRef.current;
                    //   swiper.params.navigation.nextEl = navigationNextRef.current;
                    // }}
                    // pagination={{
                    //   type: "fraction",
                    // }}
                    // modules={[Pagination]}
                    className="mySwiper"
                  >
                    {didYouKnowData?.map((data) => {
                      return (
                        <SwiperSlide key={data.id}>
                          <DoYouKnow data={data} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <div className="navigation-button">
                    <div ref={navigationPrevRef}>
                      <img
                        src={leftarrow}
                        alt="leftarrow"
                        className="image-swiper-button-prev"
                      />
                    </div>
                    <div ref={navigationNextRef}>
                      <img
                        src={rightarrow}
                        alt="rightarrow"
                        className="image-swiper-button-next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="five">
            {/* {jobPost.map((data) => ( */}
            <JobPost data={userCardData} />
            {/* ))} */}
          </div>
          <div className="six" onMouseOver={trackDashboardDown}>
            <div className="short-story-slider">
              <div className="short-story-info">
                <h3>Short Reads</h3>
                <div className="handle-navigation-button">
                  <img
                    src={unactiveArrow}
                    alt="unactive-arrow"
                    className="image-swiper-button-prev-1"
                  />
                  <img
                    src={activeArrow}
                    alt="active-arrow"
                    className="image-swiper-button-next-1"
                  />
                </div>
              </div>
              <Swiper
                // scrollbar={{
                //   hide: false,
                // }}
                slidesPerView={2}
                // pagination={{
                //   type: "fraction",
                // }}
                // modules={[Scrollbar, Pagination]}
                navigation={{
                  nextEl: ".image-swiper-button-next-1",
                  prevEl: ".image-swiper-button-prev-1",
                  disabledClass: "swiper-button-disabled",
                }}
                loop="true"
                modules={[Navigation]}
                className="mySwiper"
              >
                {shortReads?.map((data) => {
                  return (
                    <SwiperSlide key={data.id}>
                      <ShortStory data={data} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard-container">
          <div className="one">
            <div>
              <h3 className="greeting">Hello,</h3>
              <h3 className="user-name" style={{ marginLeft: "5px" }}>
                {userData?.userDetails?.name?.first}
              </h3>
            </div>

            <div
              className="date-container"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <FilterAltIcon
                sx={{
                  height: "15rem",
                  position: "relative",
                  right: "1rem",
                  cursor: "pointer",
                }}
              />
              <p
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginLeft: "-1rem",
                }}
              >
                Date Filter
              </p>
            </div>

            <Modal
              open={isSidebarOpen}
              onClose={() => {
                setIsSidebarOpen(!isSidebarOpen);
                setDatePickerOpen(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                style={{
                  width: "200px",
                  height: "150px",
                  position: "fixed",
                  right: "20px",
                  top: "160px",
                  // borderRadius: "8px",
                  borderColor: "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  backgroundColor: "white",
                  padding: "10px 1px 10px 1px",
                }}
              >
                <Button
                  onClick={handleSevenDaysFilter}
                  fullWidth
                  // variant="outlined"

                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "SEVENDAYS" ? "#1E86D8" : "#FFF",
                    color: filterType == "SEVENDAYS" ? "#FFF" : "#111",
                  }}
                >
                  Past 7 Days
                </Button>
                <Button
                  onClick={handleFortnightFilter}
                  fullWidth
                  // variant="outlined"
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "FORTNIGHT" ? "#1E86D8" : "#FFF",
                    color: filterType == "FORTNIGHT" ? "#FFF" : "#111",
                  }}
                >
                  Fortnight
                </Button>

                <Button
                  onClick={handleMonthFilter}
                  fullWidth
                  // variant="outlined"
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "MONTH" ? "#1E86D8" : "#FFF",
                    color: filterType == "MONTH" ? "#FFF" : "#111",
                  }}
                >
                  Month
                </Button>

                <Button
                  onClick={handleYearFilter}
                  // variant="outlined"
                  fullWidth
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: filterType == "YEAR" ? "#1E86D8" : "#FFF",
                    color: filterType == "YEAR" ? "#FFF" : "#111",
                  }}
                >
                  Year
                </Button>

                <Button
                  onClick={resetFilters}
                  fullWidth
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    ":hover": {
                      bgcolor: "#1e86d86e",
                      color: "white",
                    },
                    bgcolor: selectedFilter === "RESET" ? "#1E86D8" : "#FFF",
                    color: selectedFilter === "RESET" ? "#FFF" : "#111",
                  }}
                >
                  Reset Filters
                </Button>

                {/* <Button
              onClick={() => setDatePickerOpen(!datePickerOpen)}
              sx={{ color: "black", fontSize: "0.8rem" }}
            >
              Custom Date
            </Button> */}
                {/* <label
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                style={{ width: "8.5rem" }}
                value={`${filterDate[0]?.startDate.toLocaleDateString()} - ${filterDate[0]?.endDate.toLocaleDateString()} `}
              />
              <div onClick={() => setOpenRangeCalendar(!openRangeCalendar)}>
                <EventNoteIcon />
              </div>
            </label>
            <Modal
              open={openRangeCalendar}
              onClose={() => setOpenRangeCalendar(!openRangeCalendar)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <>
                <CalendarRange
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
               
                />
                <Button
                  variant="contained"
                   onClick={() => {
                    setOpenRangeCalendar(!openRangeCalendar);                      
                    setIsSidebarOpen(!isSidebarOpen);
                    }}
                  sx={{ position: "relative", top: "87.8%", left: "61%" }}
                >
                  OK
                </Button>
              </>
            </Modal> */}
              </div>
            </Modal>
          </div>
          <div className="three-cut">
            <div className="did-you-know">
              <div className="dyk-wrapper">
                <img src={doctorone} alt="bg-doctor-img" />
                <div className="slider-container-dyk">
                  <Swiper
                    // navigation={{
                    //   prevEl: navigationPrevRef.current,
                    //   nextEl: navigationNextRef.current,
                    // }}
                    navigation={{
                      nextEl: ".image-swiper-button-next",
                      prevEl: ".image-swiper-button-prev",
                      disabledClass: "swiper-button-disabled",
                    }}
                    loop="true"
                    modules={[Navigation]}
                    // onBeforeInit={(swiper) => {
                    //   swiper.params.navigation.prevEl = navigationPrevRef.current;
                    //   swiper.params.navigation.nextEl = navigationNextRef.current;
                    // }}
                    // pagination={{
                    //   type: "fraction",
                    // }}
                    // modules={[Pagination]}
                    className="mySwiper"
                  >
                    {didYouKnowData?.map((data) => {
                      return (
                        <SwiperSlide key={data.id}>
                          <DoYouKnow data={data} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  <div className="navigation-button">
                    <div ref={navigationPrevRef}>
                      <img
                        src={leftarrow}
                        alt="leftarrow"
                        className="image-swiper-button-prev"
                      />
                    </div>
                    <div ref={navigationNextRef}>
                      <img
                        src={rightarrow}
                        alt="rightarrow"
                        className="image-swiper-button-next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="six">
          <div className="six-1" onMouseOver={trackDashboardDown}>
            <div className="short-story-slider">
              <div className="short-story-info">
                <h3>Short Reads</h3>
                <div className="handle-navigation-button">
                  <img
                    src={unactiveArrow}
                    alt="unactive-arrow"
                    className="image-swiper-button-prev-1"
                  />
                  <img
                    src={activeArrow}
                    alt="active-arrow"
                    className="image-swiper-button-next-1"
                  />
                </div>
              </div>
              <Swiper
                // scrollbar={{
                //   hide: false,
                // }}
                slidesPerView={2}
                // pagination={{
                //   type: "fraction",
                // }}
                // modules={[Scrollbar, Pagination]}
                navigation={{
                  nextEl: ".image-swiper-button-next-1",
                  prevEl: ".image-swiper-button-prev-1",
                  disabledClass: "swiper-button-disabled",
                }}
                loop="true"
                modules={[Navigation]}
                className="mySwiper"
              >
                {shortReads?.map((data) => {
                  return (
                    <SwiperSlide key={data.id}>
                      <ShortStory data={data} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          </div>
          <div className="two">
            {/* {jobInfo.map((data) => ( */}
            <JobInfo data={userCardData} />
            {/* ))} */}
          </div>
          <div className="three">
            <div className="activity-analytics">
              <RangeChart
                dashboardData={dashboardData}
                categoriesData={categoriesData}
                chartTitle={graphTitle}
              />
            </div>
          </div>
          <div className="five">
            {/* {jobPost.map((data) => ( */}
            <JobPost data={userCardData} />
            {/* ))} */}
          </div>
         
        </div>
      )}
    </div>
  );
}

export default Dashboard;
