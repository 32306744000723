import React from "react";
import { Outlet } from "react-router-dom";
import "./User.scss";
function User(props) {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default User;
