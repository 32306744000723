import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useState } from 'react'

import { Box } from '@mui/system';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";
import JSZip from 'jszip';


const BillingGrid = ({data}) => {
  var rowArray = [];
  var i=1;

  if(data.length>0)
  {
     rowArray = [];
  
     let RowObj= {
       id:"",
       jobID:"",
       billNumber:"",
       hospitalBranch:"",
       department:"",
       jobStartDate:"",
       numOfDays:"",
       amount:"",
       url:"",
       url2:"",
       uniqueIdentifier:"",
     }
    data.map((obj)=>{
     
      RowObj.billNumber=obj?.billNumber
      RowObj.amount=obj?.amount
      RowObj.hospitalBranch=obj?.branch?.jobLocation?.branchName
      RowObj.url = obj?.url
      RowObj.url2 = obj?.url2
      if(obj?.userInvoices.length>0)
      {
        obj?.userInvoices?.map((ele)=>{
          RowObj.id=i;
          RowObj.jobID=ele?.jobId
          RowObj.uniqueIdentifier=ele?.jobId + " "+obj?.billNumber
          RowObj.department=ele?.departmentName
          RowObj.numOfDays=ele?.numberOfDays
          if((ele?.consequtiveTime?.start?.ts))
          {
            let newDate =  moment(parseInt(ele?.consequtiveTime?.start?.ts)).add(5.5,"hours")?._d;
            RowObj.jobStartDate= moment(newDate).format('L')
            i++;
          }
         else if(ele?.nonConsequtiveTime?.start?.ts)
         {
          let newDate =  moment(parseInt(ele?.nonConsequtiveTime?.start?.ts)).add(5.5,"hours")?._d;
          RowObj.jobStartDate= moment(newDate).format('L')
          i++;
         }
         
          rowArray.push({...RowObj})
        })
        
      }

    })
  }



  var arrayUniqueByKey=[];
  if(rowArray)
  {
    const key = 'uniqueIdentifier';

     arrayUniqueByKey = [...new Map(rowArray.map(item =>
      [item[key], item])).values()];
  
  }
  

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
    query {
      withAuth(token: "${token}"){
       userDetails{
        id,
        name {
          salutation
          first
          middle
          last
          full
        },
        emails {
          isPrimary
          isVerified
          address
        },
        phoneNumbers{
          number
        }
      }
      }
    }
      `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  }; 
  
  const trackBillingDownload = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;
    // console.log("User>>>>>", userPhone)

    if(window.Moengage){
    //set unique id
    window.Moengage.add_unique_user_id(userId);
    //set user attributes
    window.Moengage.add_user_name(userName);
    window.Moengage.add_email(userEmail);
    // Moengage.add_mobile("+12399999999");
    window.Moengage.add_mobile(userPhone);
    //set event data
    window.Moengage.track_event(eventHelpers.BILLING_DOWLD
      // {
      // "ID": userId,
      // "name": userName,
      // "Email": userEmail,
      // "date": Date.now(),
    //}
    );
    // window.Moengage.destroy_session();
    }
    
  };
  
  const trackBillingExport = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    if(window.Moengage){
    //set unique id
    window.Moengage.add_unique_user_id(userId);
    //set user attributes
    window.Moengage.add_user_name(userName);
    window.Moengage.add_email(userEmail);
    //set event data
    window.Moengage.track_event(eventHelpers.BILLING_EXPORT
      // {
      // "ID": userId,
      // "name": userName,
      // "Email": userEmail,
      // "date": Date.now(),
    //}
    );
    // window.Moengage.destroy_session();
    }
    
  };

  //COLUMN

const columns = [
    {
        field: "jobID",
        headerClassName: 'billing--header',
        headerName: `JOB ID`,
      
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "billNumber",
        headerName: `Bill Number`,
        headerClassName: 'billing--header',
       
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "hospitalBranch",
        headerClassName: 'billing--header',
        headerName: `Hospital Branch`,
       
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "department",
        headerClassName: 'billing--header',
        headerName: `Department`,
        
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "jobStartDate",
        headerClassName: 'billing--header',
        headerName: `Job Start Date`,
      
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "numOfDays",
        headerClassName: 'billing--header',
        headerName: `Number of Days`,
        renderCell: (cellValues) => {
          const numOfDays = cellValues?.row?.numOfDays;
          return numOfDays;
        },
        // valueGetter: getID,
        flex: 1,
    },
  
    {
        field: "amount",
        headerClassName: 'billing--header',
        headerName: `Amount`,
        renderCell: (cellValues) => {
          const amount = cellValues?.row?.amount;
          return amount;
        },
        // valueGetter: getID,
        flex: 1,
    },
    {
        field: "action",
        headerClassName: 'billing--header',
        headerName: `Action`,
        renderCell: (cellValues) => {
          const downloadBothPdf = async () => {
            // console.log(">>>", cellValues?.row?.billNumber)
            const lastPart = cellValues?.row?.billNumber.split("/").pop();
            if (cellValues?.row) {
              const zip = new JSZip();
            
              const fetchAndAddToZip = async (url, fileName) => {
                if (url) {
                  const content = await fetch(url).then((response) => response.blob());
                  zip.file(fileName, content);
                }
              };
            
              await fetchAndAddToZip(cellValues.row.url, `D${lastPart}.pdf`);
              await fetchAndAddToZip(cellValues.row.url2, `${lastPart}.pdf`);
            
              zip.generateAsync({ type: 'blob' }).then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'files.zip';
                link.click();
              });
            } else {
              console.error('URLs are not available');
            }
            
            // if (cellValues?.row) {
            //   const zip = new JSZip();
            //   const pdf1Content = await fetch(cellValues.row.url).then((response) => response.blob());
            //   const pdf2Content = await fetch(cellValues.row.url2).then((response) => response.blob());
          
            //   zip.file("file1.pdf",pdf1Content);
            //   zip.file("file2.pdf",pdf2Content);
        
            //   zip.generateAsync({ type: 'blob' }).then((blob) => {
            //     const link = document.createElement('a');
            //     link.href = URL.createObjectURL(blob);
            //     link.download = 'files.zip';
            //     link.click();
            //   });
            // } else {
            //   console.error('URLs are not available');
            // }
          };
       
          return (
            <>
        <a href="#" onClick={() => {
          downloadBothPdf()
          trackBillingDownload()
        }}>
        <DownloadIcon sx={{color:"rgba(0, 70, 138, 1)", cursor:"pointer"}} />
        </a>
              
       
          </>
          )
          
        },
        
        // valueGetter: getID,
        flex: 1,
    },

    {
      field: "url",
      headerClassName: 'billing--header',
      headerName: `URL`,
      renderCell: (cellValues) => {
        let url = cellValues?.row?.url;
        let url2 = cellValues?.row?.url2;
        // return {url,url2};
        return (
          <div>
            <p>URL 1: {url}</p>
            <p>URL 2: {url2}</p>
          </div>
        );
      },
      // valueGetter: getID,
      flex: 5,
  },

    

]

const rows=[
    {
        id:"",
        billNumber:"",
        hospitalBranch:"",
        department:"",
        jobStartDate:"",
        numOfDays:"",
        amount:"",
        action:""
    }
]



  return (
    <Box
    sx={{
      '& .billing--header': {
        backgroundColor: 'rgba(226, 236, 254, 1)',
        color:'rgba(38, 38, 38, 1)',
        fontWeight:"800",
        
      },
    }}
  >
                 <DataGrid
                          getRowId={(row) => row.id}
                          columns={columns}
                          components={{ Toolbar: GridToolbar }}
                          
                          componentsProps={{
                          toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                          },
                          }}
                          rows={ data.length>0 ? arrayUniqueByKey :[]}
                        
                          pagination
                          pageSize={10}
                          style={{margin:0, marginTop:"1rem"}}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                 url:false,
                                 url2:false
                              },
                            },
                          }}
                          rowsPerPageOptions={[10]}
                          sx={{
                            backgroundColor: "#ffff",
                            color: "grey",
                            height: "20rem",
                            margin: "1rem",
                            borderRadius: "0.5rem",
                            "& .MuiDataGrid-cell": {
                              padding: "15px",
                            },
                            borderColor: "light-grey",
                            "& .MuiDataGrid-cell:hover": {
                              color: "info",
                            },
                          }}
                        />
    </Box>
  )
}

export default BillingGrid;