import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import FullCalendarComponent from "../../components/UpcomingEvents/FullCalendar/FullCalendar";
import InterviewDetails from "../../components/UpcomingEvents/InterviewDetails";
import graphqlRequest from "../../requestMethod";
import rightArrow from "../../asset/rightArrow.svg";
import leftArrow from "../../asset/leftIcon.png";

import "./UpcomingEvents.scss";
import { Navigation, Pagination } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
let upcomingEvent = [
  {
    id: 1,
    name: "Rohit sah",
    eventType: "Interview",
    date: "16th March,2023",
    time: "04:00 pm onwards",
    eventDay: ["Mon", "Tue"],
  },
  {
    id: 2,
    name: "Sidhart Singh",
    eventType: "Interview",
    date: "19th March,2023",
    time: "01:00 pm onwards",
    eventDay: ["Wed", "Sun"],
  },
];

function UpcomingEvents(props) {
  const [upcoming, setUpcoming] = useState();
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  function fetchDepartmentDropDown() {
    let query = `
    query{
      withAuth(token: "${token}") {
        listInterview {
          id
          candidate{
            name{
              full
            }
          }
          pubToken
          sessionId
          job {
            code
            department {
              departmentType {
                name
              }
            }
          }
          job {
            branch {
              jobLocation {
                branchName
              }
            }
          }
          suggestedSlot {
            start
            date
          }
          startTime
          date
        }
      }
    }
    `;
    graphqlRequest(query).then((response) => {
      setUpcoming(response.data.withAuth.listInterview);
    });
  }
  useEffect(() => {
    fetchDepartmentDropDown();
  }, []);

  const ref = useRef(null);
  console.log("upcoming", upcoming);

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == "left") {
        element.current.scrollLeft -= step;
      } else {
        element.current.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <div className="calender-wrapper">
      <div className="calender-container">
        <JobsHeader title={"Upcoming Events"} />
        <BreadCrumb action={"Upcoming events"} title={"Calendar"} />
        {upcoming?.length > 0 ? (
          <div className="interview-details-container" ref={ref}>
            <img
              src={leftArrow}
              onClick={() => sideScroll(ref, "left", 10, 500, 500)}
              style={{
                position: "sticky",
                top: "50%",
                left: 0,
                height: "30px",
                border: "none",
              }}
            />
            {upcoming?.map((data) => {
              return <InterviewDetails data={data} />;
            })}
            <img
              src={rightArrow}
              onClick={() => sideScroll(ref, "right", 10, 500, 500)}
              style={{
                position: "sticky",
                right: 0,
                top: "50%",
                height: "30px",
                border: "none",
              }}
            />
          </div>
        ) : null}
        <div className="calender">
          <FullCalendarComponent upcomingEvent={upcoming} />
        </div>
      </div>
    </div>
  );
}

export default UpcomingEvents;
