import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/Common/BreadCrumb/BreadCrumb";
import JobsHeader from "../../components/Common/JobsHeader/JobsHeader";
import "./UserList.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import graphqlRequest from "../../requestMethod";

const columns = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "contactNo",
    label: "Contact No.",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(name, email, contactNo) {
  return {
    name,
    email,
    contactNo,
  };
}

function UserList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = useState([]);

  let newRow = tableData?.map((data) => {
    return createData(data.name, data.email, data.contact);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;
  let fetchUserList = () => {
    let query = `
    query{
      withAuth(token:"${token}"){
        listOrganizationUsers {
          id
          email
          contact
          name
        }
       }
     }
    `;

    graphqlRequest(query, {}).then((response) => {
      setTableData(response.data.withAuth.listOrganizationUsers);
    });
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <div className="user-list-wrapper">
      <div className="user-list-container">
        <JobsHeader title={"User List"} />
        <BreadCrumb action={"User"} title={"User List"} />
        <div className="user-list-table">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 520 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#2D5FA1",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newRow
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            // console.log("column>>", column.id);
                            const value = row[column.id];
                            // console.log("value>>", value);

                            return column.id === "action" ? (
                              <TableCell key={column.id} align={column.align}>
                                <button className="raise-query">
                                  Raise a query
                                </button>
                              </TableCell>
                            ) : (
                              <TableCell key={column.id} align={column.align}>
                                {column?.id === "contactNo"
                                  ? `+${value}`
                                  : value}

                                {/* {column.format && typeof value === "number"
                                  ? "+" + column.format(value)
                                  : value} */}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={newRow?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default UserList;
