import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UpcomingEvents from "./pages/UpcomingEvents/UpcomingEvents";
import Dashboard from "./pages/Dashboard/Dashboard";
import Layout from "./pages/Layout/Layout";
import User from "./pages/Users/User";
import LogIn from "./pages/Authentication/LogIn.js";
import LoginPage from "./pages/Authentication/LoginWithOTP";
import SetNewPassword from "./pages/Authentication/SetNewPassword";
import DeleteAccountPage from "./pages/Authentication/DeleteUserAccount.js";

import LOpenJobs from "./pages/Jobs/Locum/LOpenJobs/LOpenJobs";
import LAcceptedJobs from "./pages/Jobs/Locum/LAcceptedJobs/LAcceptedJobs";
import LOngoingJobs from "./pages/Jobs/Locum/LOngoingJobs/LOngoingJobs";
import LCompletedJobs from "./pages/Jobs/Locum/LCompletedJobs/LCompletedJobs";
import LCancelledJobs from "./pages/Jobs/Locum/LCancelledJobs/LCancelledJobs";
import LShiftLogs from "./pages/Jobs/Locum/LShiftLogs/LShiftLogs";
import LOpenReportjobs from "./pages/Jobs/Locum/LReportsJobs/LOpenJobs/LReportOpenJobs";
import LCompletedReportjobs from "./pages/Jobs/Locum/LReportsJobs/LCompletedReportJobs/LCompletedReportJobs";

import OpenJobs from "./pages/Jobs/Permanent/PopenJobs/Popenjobs";
import AcceptedJobs from "./pages/Jobs/Permanent/AcceptedJobs/Acceptedjobs";
import OngoingJobs from "./pages/Jobs/Permanent/OngoingJobs/OngoingJobs";
import CompletedJobs from "./pages/Jobs/Permanent/CompletedJobs/CompletedJobs";
import CancelledJobs from "./pages/Jobs/Permanent/CancelledJobs/CancelledJobs";
import ShiftLogs from "./pages/Jobs/Permanent/ShiftLogs/ShiftLogs";
import ReportOpenJobs from "./pages/Jobs/Permanent/ReportsJobs/ReportOpenJobs/ReportOpenJobs";
import ReportCompletedJobs from "./pages/Jobs/Permanent/ReportsJobs/ReportCompletedJobs/ReportCompletedJobs";

import LocumJobForm from "./pages/LocumJobForm/LocumJobForm";
import PermanentJobForm from "./pages/PermanentJobForm/PermanentJobForm";
import Permission from "./pages/Permission/Permission";
import UserList from "./pages/UserList/UserList";
import Recommendation from "./pages/Recommendation/Recommendation.jsx";
import UserProfile from "./pages/UserProfile/UserProfile";
import OpenTok from "./components/InterviewPage/InterviewPage";
import LAcceptedReportJobs from "./pages/Jobs/Locum/LReportsJobs/LAcceptedReportJobs/LAcceptedReportJobs";
import LCancelledReportJobs from "./pages/Jobs/Locum/LReportsJobs/LCancelledReportJobs/LCancelledReportJob";
import OngoingReportJobs from "./pages/Jobs/Locum/LReportsJobs/LOngoingReportJobs/LOngoingReportJobs";
import SignUp from "./pages/Authentication/SignUp/SignUp";
import Home from "./pages/Authentication/Home.js";
import RegistrationForm from "./pages/Authentication/RegistrationForm.js";

import PrivateRoute from "./components/PrivateRouteComponent/PrivateRoute";
import CreateNewPassword from "./components/PopUp/CreateNewPassword/CreateNewPassword";
import Billing from "./pages/Billing/Billing";
import Rating from "./pages/Rating/Rating";
import { useState } from "react";

function App() {
  console.log("PIPEINE");
  const route = createBrowserRouter([
    {
      path: "/login",
      element: <LogIn />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    // {
    //   path: "/home/RegistrationForm",
    //   element: <RegistrationForm />,
    // },

    {
      path: "/admin/login",
      element: <LoginPage />,
    },
    {
      path: "/login/setpassword",
      element: <SetNewPassword />,
    },
    {
      path: "/interviewPage",
      element: <OpenTok />,
    },
    {
      path: "/createPassword",
      element: <CreateNewPassword />,
    },
    {
      path: "/deleteUserAcount",
      element: <DeleteAccountPage />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/",
      element: <PrivateRoute privateComponent={<Layout />} />,
      children: [
        {
          path: "/",
          element: <PrivateRoute privateComponent={<Dashboard />} />,
        },
        {
          path: "/locum-job",
          element: <LocumJobForm />,
        },
        // {
        //   path: "/interviewPage",
        //   element: <OpenTok />,
        // },
        {
          path: "/permanent-job",
          element: <PermanentJobForm />,
        },
        //Locum job Route
        {
          path: "/",
          children: [
            {
              path: "/locum/open-jobs",
              element: <LOpenJobs />,
            },
            {
              path: "/locum/accepted-jobs",
              element: <LAcceptedJobs />,
            },
            {
              path: "/locum/ongoing-jobs",
              element: <LOngoingJobs />,
            },
            {
              path: "/locum/completed-jobs",
              element: <LCompletedJobs />,
            },
            {
              path: "/locum/cancelled-jobs",
              element: <LCancelledJobs />,
            },
            {
              path: "/locum/shift-logs",
              element: <LShiftLogs />,
            },
            {
              path: "/",
              children: [
                {
                  path: "/locum/open-jobs-report",
                  element: <LOpenReportjobs />,
                },
                {
                  path: "/locum/accepted-jobs-report",
                  element: <LAcceptedReportJobs />,
                },
                {
                  path: "/locum/ongoing-jobs-report",
                  element: <OngoingReportJobs />,
                },
                {
                  path: "/locum/completed-jobs-report",
                  element: <LCompletedReportjobs />,
                },
                {
                  path: "/locum/cancelled-jobs-report",
                  element: <LCancelledReportJobs />,
                },
              ],
            },
          ],
        },
        // Permanent job Route
        {
          path: "/",
          children: [
            {
              path: "/permanent/open-jobs",
              element: <OpenJobs />,
            },
            {
              path: "/permanent/accepted-jobs",
              element: <AcceptedJobs />,
            },
            {
              path: "/permanent/ongoing-jobs",
              element: <OngoingJobs />,
            },
            {
              path: "/permanent/completed-jobs",
              element: <CompletedJobs />,
            },
            {
              path: "/permanent/cancelled-jobs",
              element: <CancelledJobs />,
            },
            {
              path: "/permanent/shift-logs",
              element: <ShiftLogs />,
            },
            {
              path: "/",
              children: [
                {
                  path: "/permanent/open-jobs-report",
                  element: <ReportOpenJobs />,
                },
                {
                  path: "/permanent/accepted-jobs-report",
                  element: <ReportCompletedJobs />,
                },
                {
                  path: "/permanent/cancelled-jobs-report",
                  element: <ReportCompletedJobs />,
                },
              ],
            },
          ],
        },
        //user route
        {
          path: "/",
          children: [
            {
              path: "/user/user-list",
              element: <UserList />,
            },
            {
              path: "/user/permission",
              element: <Permission />,
            },
          ],
        },
        {
          path: "/upcoming-events",
          element: <UpcomingEvents />,
        },
        {
          path: "/profile/recommendation",
          element: <Recommendation />,
        },
        {
          path: "/user-profile",
          element: <UserProfile />,
        },
        {
          path: "/billing",
          element: <Billing />,
        },
        {
          path: "/rating",
          element: <Rating />,
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={route} />
      
    </>
  );
}

export default App;
