import React from "react";

import { GoogleLogin } from "@react-oauth/google";
import { login, loginWithGmail } from "../redux/apiCall";
import { Navigate, useNavigate } from "react-router-dom";
import { message } from "../components/Common/SnakeAlert/SnakeAlert";

const google = ({ dispatch, navigate }) => {
  const handleLoginByGoogle = async (data) => {
    console.log("GOOGLE LOGIN  DATA", data);
    const response = await loginWithGmail(dispatch, data.credential);
    if (response?.data) {
      if (!response?.data?.loginWithGmail?.accessToken) {
        console.log(
          "RESPONSE IN GOOGLE COMPONENT",
          response?.data?.loginWithGmail
        );
        message.info("You are not Registered with us, Please Sign Up!");
        // navigate("/signup");
        navigate("/home");
      } else {
        navigate("/");
        console.log("RESPONSE IN GOOGLE NAVIGATED");
        message.success("Login Successfull!");
      }
    } else {
      message.error("Invalid Credentials");
    }
  };

  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        handleLoginByGoogle(credentialResponse);
      }}
      onError={(err) => {
        console.log("Login Failed", err);
      }}
    />
  );
};

export default google;
